import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type VideoBlockFieldsFragment = (
  { __typename?: 'videoBlock' }
  & Pick<Types.VideoBlock, 'id' | 'type' | 'title' | 'preamble' | 'url' | 'themeColorVariant'>
  & { theme?: Types.Maybe<(
    { __typename?: 'theme' }
    & Pick<Types.Theme, 'dark' | 'complementaryColor' | 'primaryColor' | 'textColor'>
  )> }
);

export const VideoBlockFieldsFragmentDoc = gql`
    fragment videoBlockFields on videoBlock {
  id
  type
  title
  preamble
  url
  themeColorVariant
  theme {
    dark
    complementaryColor
    primaryColor
    textColor
  }
}
    `;