"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var styled_components_1 = __importStar(require("styled-components"));
var styles_1 = require("../../styles");
var components_1 = require("../../components");
var framer_motion_1 = require("framer-motion");
var styled_system_1 = require("styled-system");
var TabsContext = React.createContext({
    activeTab: "",
    setActiveTab: function (id) { },
    controlled: false
});
exports.useTabsActiveTab = function (initialTabId) {
    var _a = React.useState(initialTabId || ""), activeTab = _a[0], setActiveTab = _a[1];
    return { activeTab: activeTab, setActiveTab: setActiveTab };
};
var Tabs = function (props) {
    var _a = exports.useTabsActiveTab(props.activeTab || ""), activeTab = _a.activeTab, setActiveTab = _a.setActiveTab;
    var internalActiveTab = props.controlled ? props.activeTab || "" : activeTab;
    return (React.createElement(TabsContext.Provider, { value: {
            activeTab: internalActiveTab,
            setActiveTab: setActiveTab,
            controlled: props.controlled || false
        } }, props.children));
};
exports.Tabs = Tabs;
var TabsContainer = function (props) {
    var theme = styled_components_1.useTheme();
    return React.createElement(components_1.Flex, __assign({}, props, { borderColor: theme.dark ? "white" : "black" }));
};
var TabStyled = styled_components_1.default.button.attrs(function (props) { return ({
    "aria-expanded": props.expanded
}); })(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tposition: relative;\n\twidth: 100%;\n\tbackground-color: ", ";\n\tmargin-left: ", ";\n\tpadding: ", " ", ";\n\tmin-height: ", ";\n\ttext-align: center;\n\t", "\n\n\t", "\t\n\n\t&:first-child {\n\t\tmargin-left: 0;\n\t}\n\n\t&:after {\n\t\tdisplay: ", ";\n\t\tcontent: \"\";\n\t\tbackground-color: ", ";\n\t\tposition: absolute;\n\t\tbottom: ", ";\n\t\tleft: 50%;\n\t\theight: 5px;\n\t\twidth: 100%;\n\t\ttransform: ", ";\n\t\ttransition: transform 0.5s cubic-bezier(0.789, 0.301, 0, 0.956);\n\t}\n\n\t&:hover {\n\t\t&:after {\n\t\t\ttransform: translateX(-50%) scaleX(1);\n\t\t}\n\t}\n\n\t", "\n"], ["\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\tposition: relative;\n\twidth: 100%;\n\tbackground-color: ",
    ";\n\tmargin-left: ", ";\n\tpadding: ", " ", ";\n\tmin-height: ", ";\n\ttext-align: center;\n\t", "\n\n\t",
    "\t\n\n\t&:first-child {\n\t\tmargin-left: 0;\n\t}\n\n\t&:after {\n\t\tdisplay: ", ";\n\t\tcontent: \"\";\n\t\tbackground-color: ",
    ";\n\t\tposition: absolute;\n\t\tbottom: ", ";\n\t\tleft: 50%;\n\t\theight: 5px;\n\t\twidth: 100%;\n\t\ttransform: ",
    ";\n\t\ttransition: transform 0.5s cubic-bezier(0.789, 0.301, 0, 0.956);\n\t}\n\n\t&:hover {\n\t\t&:after {\n\t\t\ttransform: translateX(-50%) scaleX(1);\n\t\t}\n\t}\n\n\t",
    "\n"])), function (props) {
    return props.expanded ? styles_1.colors["greybrown"] : styles_1.colors["brown-light"];
}, styles_1.scale.px(2.5), styles_1.scale.px(1), styles_1.scale.px(2), styles_1.scale.px(7), styled_system_1.space, styled_system_1.variant({
    variants: {
        ghost: {
            backgroundColor: "transparent",
            justifyContent: "flex-start",
            margin: 0,
            padding: 0,
            minHeight: "auto"
        }
    }
}), function (props) { return (props.variant === "ghost" ? "block" : "none"); }, function (_a) {
    var theme = _a.theme;
    return theme.dark ? styles_1.colors["white"] : styles_1.colors["black"];
}, styles_1.scale.px(-1), function (props) {
    return props.expanded
        ? "translateX(-50%) scaleX(1)"
        : "translateX(-50%) scaleX(0)";
}, styles_1.breakpoint("m")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\tpadding: ", " ", ";\n\n\t\t", "\t\n\t"], ["\n\t\tpadding: ", " ", ";\n\n\t\t",
    "\t\n\t"])), styles_1.scale.px(1.5), styles_1.scale.px(2), styled_system_1.variant({
    variants: {
        ghost: {
            padding: 0
        }
    }
})));
var Tab = function (_a) {
    var children = _a.children, id = _a.id, onClick = _a.onClick, rest = __rest(_a, ["children", "id", "onClick"]);
    var _b = React.useContext(TabsContext), setActiveTab = _b.setActiveTab, activeTab = _b.activeTab;
    var expanded = id === activeTab;
    return (React.createElement(TabStyled, __assign({ onClick: onClick
            ? function () { return onClick(id); }
            : function () {
                setActiveTab(id);
            }, expanded: expanded }, rest), children));
};
var IconTabStyled = styled_components_1.default.button.attrs(function (props) { return ({
    "aria-expanded": props.expanded
}); })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\twhite-space: nowrap;\n\tpadding: ", " ", ";\n\tdisplay: flex;\n\talign-items: center;\n\n\t> .label {\n\t\twidth: 100%;\n\t\twhite-space: nowrap;\n\t\toverflow: hidden;\n\t\ttext-overflow: ellipsis;\n\t\twhite-space: nowrap;\n\t\tline-height: ", ";\n\t\tfont-size: ", ";\n\t\tcolor: ", ";\n\t}\n\n\t&:hover,\n\t&.--active {\n\t\tbackground: ", ";\n\n\t\t> .label {\n\t\t\tcolor: ", ";\n\t\t}\n\n\t\t> ", " path {\n\t\t\tfill: ", ";\n\t\t}\n\t}\n"], ["\n\twhite-space: nowrap;\n\tpadding: ", " ", ";\n\tdisplay: flex;\n\talign-items: center;\n\n\t> .label {\n\t\twidth: 100%;\n\t\twhite-space: nowrap;\n\t\toverflow: hidden;\n\t\ttext-overflow: ellipsis;\n\t\twhite-space: nowrap;\n\t\tline-height: ", ";\n\t\tfont-size: ", ";\n\t\tcolor: ", ";\n\t}\n\n\t&:hover,\n\t&.--active {\n\t\tbackground: ", ";\n\n\t\t> .label {\n\t\t\tcolor: ", ";\n\t\t}\n\n\t\t> ", " path {\n\t\t\tfill: ", ";\n\t\t}\n\t}\n"])), styles_1.scale.px(3), styles_1.scale.px(4), styles_1.scale.px(3.5), styles_1.scale.px(2.25), function (_a) {
    var theme = _a.theme;
    return (theme.dark ? styles_1.colors.white : styles_1.colors.black);
}, function (_a) {
    var theme = _a.theme;
    return (theme.dark ? styles_1.colors.white : styles_1.colors.black);
}, function (_a) {
    var theme = _a.theme;
    return (theme.dark ? styles_1.colors.black : styles_1.colors.white);
}, components_1.Svg, function (_a) {
    var theme = _a.theme;
    return (theme.dark ? styles_1.colors.black : styles_1.colors.white);
});
var IconTab = function (_a) {
    var id = _a.id, icon = _a.icon, children = _a.children, onClick = _a.onClick, rest = __rest(_a, ["id", "icon", "children", "onClick"]);
    var _b = React.useContext(TabsContext), setActiveTab = _b.setActiveTab, activeTab = _b.activeTab;
    var expanded = id === activeTab;
    var textColor = styled_components_1.useTheme().textColor;
    return (React.createElement(IconTabStyled, __assign({ onClick: onClick
            ? function () { return onClick(id); }
            : function () {
                setActiveTab(id);
            }, className: expanded && "--active", expanded: expanded }, rest),
        React.createElement(components_1.Icon, { minw: 3, mr: 1.25, icon: icon, color: textColor }),
        React.createElement("span", { className: "label" }, children)));
};
var Label = function (props) { return (React.createElement(components_1.Text, __assign({ textTransform: "uppercase", mb: 0, fontSize: { _: 1.75, m: 2, xxl: 2.5 }, lineHeight: { _: 2.25, m: 2.5, xxl: 3 }, fontWeight: "semibold", letterSpacing: "0.1875" }, props))); };
var Panel = function (props) {
    var activeTab = React.useContext(TabsContext).activeTab;
    var defaultPanelAnimation = {
        initial: {
            opacity: 0
        },
        animate: {
            opacity: 1
        }
    };
    var transformPanelAnimation = {
        initial: {
            y: 56,
            opacity: 0
        },
        animate: {
            y: 0,
            opacity: 1
        }
    };
    return activeTab === props.id ? (React.createElement(framer_motion_1.motion.div, { style: { width: "100%" }, variants: props.animation === "opacity-y-transform"
            ? transformPanelAnimation
            : defaultPanelAnimation, initial: "initial", animate: "animate", transition: { ease: [0.22, 0.17, 0, 0.94], duration: 0.5 } }, props.children)) : null;
};
Tabs.TabsContainer = TabsContainer;
Tabs.Tab = Tab;
Tabs.IconTab = IconTab;
Tabs.Label = Label;
Tabs.Panel = Panel;
var templateObject_1, templateObject_2, templateObject_3;
