import * as React from "react"
import { Switch, Route } from "react-router-dom"
import { Page } from "../../www-client/src/components/"

export const Routes: React.FC = props => (
	<>
		<Switch>
			<Route exact path="/">
				<Page />
			</Route>
			<Route path="*">
				<Page />
			</Route>
		</Switch>
	</>
)
