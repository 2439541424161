import * as React from "react"
import { useLocation } from "react-router"
import {
	Text,
	Flex,
	PromotionalArea,
	INNER_CONTAINER_MAX_WIDTH,
	READABLE_LINE_MAX_WIDTH
} from "@operan/ui-components"
import { qBankImageSrc } from "../../utils"
import {
	PageIntroBlock as IPageIntroBlock,
	BlockType
} from "../../@types/graphql.generated"
import { renderSubnav } from "./utils"
import { ThemeContext } from "styled-components"
import { useLocales, translationNamespace } from "../../hooks/useLocales"

const PageIntroBlock: React.FC<IPageIntroBlock> = props => {
	const location = useLocation()
	const themeContext = React.useContext(ThemeContext)
	const locales = useLocales(
		translationNamespace.block(BlockType.PageIntroBlock),
		["submenuAccordionToggleLabel"]
	)
	const showMenu = props.menu && props.menu.children && props.menu.children.length > 0;
	const showCampaignArea = props.campaignArea && props.campaignArea.campaignAreaShowHide;

	return (
		<Flex px={{ _: 2, m: 5, l: 10 }} pt={{ _: 4, m: 6, l: 8 }}>
			<Flex
				alignItems="center"
				flexDirection="column"
				w={1}
				maxw={INNER_CONTAINER_MAX_WIDTH}
				style={{ margin: "0 auto" }}
			>
				<Flex w={1} mt={{ _: -4, m: -6 }} alignItems={"flex-start"}>
					<Flex
						w={1}
						style={{ position: "relative" }}
						mt={{ _: props.menu ? 12 : 7, l: 8 }}
						flexDirection={{ _: "column", l: "row" }}
						pr={true && showMenu && { _: 0, l: 39, xl: 43, xxl: 44, xxxl: 47}}
					>
						<Flex display="block" w={1} maxw={READABLE_LINE_MAX_WIDTH}>
							<Flex
								flexDirection="column"
							>
								<Text as="h1" variant="headline-1">
									{props.title}
								</Text>
								{props.preamble && (
									<Text
										variant="preamble"
										mb={props.image ? { _: 3.25, m: 3.5 } : 0}
										fontSize={{ _: 2.375, m: 2.75, l: 4 }}
										lineHeight={{ _: 3.5, m: 3.75, l: 5 }}
									>
										{props.preamble}
									</Text>
								)}
								<Flex display="block">
									{props.image && (
										<img
											alt={props.image.alt || ""}
											src={qBankImageSrc(props.image.src, "768")}
											style={{ width: "100%" }}
										/>
									)}
								</Flex>
							</Flex>
						</Flex>
						{showCampaignArea && (
							<Flex 
								maxw={{_: "unset", s: 50}}
								minw={{_: "unset", s: 40}}
								pt={{_: 4, l: 0}}
								pl={{_: 0, l: 5}} 
							>
								<PromotionalArea 
									media={props.campaignArea.campaignAreaImage ? <img 
										alt={props.campaignArea.campaignAreaImage.alt} 
										src={qBankImageSrc(props.campaignArea.campaignAreaImage.src, "768")} 
									/> : undefined}
									title={props.campaignArea.campaignAreaTitle}
									text={props.campaignArea.campaignAreaText}
									link={props.campaignArea.campaignAreaLink}
									color={props.campaignArea.campaignAreaCardColor}
									darkMode={props.campaignArea.campaignAreaDarkMode}
								/>
							</Flex>
						)}
						{showMenu &&
							renderSubnav(
								props.menu,
								location.pathname,
								themeContext,
								locales
							)}
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	)
}

export { PageIntroBlock }
