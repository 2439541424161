import * as React from "react"
import {
	LandingPageHero,
	ResponsiveImage,
	VideoPlayer,
	mergeColorTheme
} from "@operan/ui-components"
import { LandingPageHeroBlock as ILandingPageHeroBlock } from "../../@types/graphql.generated"
import { imageDataToImageProps, qBankVideoSrc } from "../../utils"
import { ThemeProvider } from "styled-components"

const LandingPageHeroBlock: React.FC<ILandingPageHeroBlock> = props => {
	return (
		<ThemeProvider theme={mergeColorTheme(props.theme)}>
			<LandingPageHero
				title={props.title}
				preamble={props.preamble}
				media={
					props.image || props.videoUrl ? (
						props.videoUrl ? (
							<VideoPlayer
								useCustomControls={true}
								autoplay={true}
								url={qBankVideoSrc(props.videoUrl)}
								muted
								playsinline
								loop
							/>
						) : (
							<ResponsiveImage {...imageDataToImageProps(props.image)} />
						)
					) : null
				}
			/>
		</ThemeProvider>
	)
}

export { LandingPageHeroBlock }
