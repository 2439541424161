import * as React from "react"
import { ThemeProvider } from "styled-components"
import {
	Flex,
	Text,
	Card,
	mergeColorTheme,
	UnderlinedLink,
	ScrollPanel,
	scale
} from "@operan/ui-components"
import {
	RelatedPagesBlock as IRelatedPagesBlock,
	PageSummary,
	BlockType
} from "../../@types/graphql.generated"
import { Block } from "../Block"
import { Link } from "react-router-dom"
import { qBankImageSrc, FallbackImage } from "../../utils"
import { useLocales, translationNamespace } from "../../hooks/useLocales"

export interface RelatedPagesBlockProps extends IRelatedPagesBlock {}

const RelatedPagesBlock: React.FC<RelatedPagesBlockProps> = props => {
	const locales = useLocales(
		translationNamespace.block(BlockType.RelatedPagesBlock),
		["nextButtonLabel", "prevButtonLabel"]
	)
	const slideRef = React.useRef(null)
	const hasCta = props.cta?.url && props.cta?.label

	return (
		props.pages?.length > 0 && (
			<Block themeColorVariant={props.themeColorVariant} theme={props.theme}>
				<Flex
					flexDirection="column"
					pt={{ _: 8 }}
					pb={{ _: 16, xxs: 8 }}
					as="section"
					alignItems="center"
				>
					{props.title && hasCta && (
						<Flex flexDirection="column" w={1} px={{ _: 0, s: 5, l: 10 }} pb={{_:8}}>
							<Flex
								flexDirection="column"
								w={1}
								maxw={180}
								style={{ margin: "0 auto" }}
							>
								<Flex
									justifyContent={{ _: "center", m: "space-between" }}
									alignItems="center"
								>
									{props.title && (
										<Flex flex={1} justifyContent={{ _: "center", m: "unset" }}>
											<Text
												variant="headline-2"
												pr={{ m: 2 }}
												mb={{_:0}}
												textAlign={{ _: "center", m: "left" }}
											>
												{props.title}
											</Text>
										</Flex>
									)}
									{hasCta && (
										<Flex display={{ _: "none", m: "flex" }}>
											<UnderlinedLink.Link
												to={props.cta.url}
												label={props.cta.label}
												withIcon
											/>
										</Flex>
									)}
								</Flex>
							</Flex>
						</Flex>
					)}

					{/* Page card carousel */}
					<ScrollPanel slideRef={slideRef}>
						<ScrollPanel.Content>
							<Flex py={0} pr={{ m: 8 }} w={1}>
								<Flex maxw={184}>
									{props.pages.map((page: PageSummary, i) => (
										<Flex
											flex={1}
											minw={{ _: 42, m: 50 }}
											px={{ _: 1, xl: 1.5 }}
											ref={i === 0 ? slideRef : null}
											key={i}
										>
											<ThemeProvider theme={mergeColorTheme(page.theme)}>
												<Link to={page.url} style={{ width: "100%" }}>
													<Card
														{...page}
														title={page.preamble}
														media={
															page.image ? (
																<img
																	alt=""
																	src={qBankImageSrc(page.image.src, "768")}
																/>
															) : (
																<FallbackImage />
															)
														}
														bgGradient={false}
													/>
												</Link>
											</ThemeProvider>
										</Flex>
									))}
								</Flex>
							</Flex>
						</ScrollPanel.Content>
						<Flex position="relative" w={1}>
							<Flex
								position="absolute"
								flexDirection={{ _: "column-reverse", xxs: "row"}}
								alignItems={{ m: "flex-start" }}
								px={{ _: 2, s: 5 }}
								justifyContent={{ m: "space-between" }}
								w={1}
								top={{ _: scale.px(1) }}
							>
								{hasCta && (
									<Flex 
										display={{ _: "flex", m: "none" }}
										justifyContent="flex-end"
									>
										<UnderlinedLink.Link
											to={props.cta.url}
											label={props.cta.label}
											withIcon
										/>
									</Flex>
								)}
								<Flex flex={1} justifyContent="flex-end">
									<ScrollPanel.Navigation {...locales} />
								</Flex>
							</Flex>
						</Flex>
					</ScrollPanel>
				</Flex>
			</Block>
		)
	)
}

export { RelatedPagesBlock }
