"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var colors_1 = require("./colors");
exports.borderColors = {
    greybrown: colors_1.colors["greybrown"],
    black: colors_1.colors["black"],
    white: colors_1.colors["white"],
    "greybrown-light": colors_1.colors["greybrown-light"],
    "greybrown-medium": colors_1.colors["greybrown-medium"],
    "greybrown-dark": colors_1.colors["greybrown-dark"],
    brown: colors_1.colors["brown"],
    red: colors_1.colors["red"]
};
exports.borderKeys = Object.keys(exports.borderColors);
