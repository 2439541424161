"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_1 = require("styled-components");
var constants_1 = require("../../constants");
exports.fadeDownText = styled_components_1.keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    0% {\n        opacity: 0;\n        transform: translateY(-24px);\n    }\n    60% {\n        transform: translateY(0);\n    }\n    100% {\n        transform: translateY(0);\n        opacity: 1;\n    }\n"], ["\n    0% {\n        opacity: 0;\n        transform: translateY(-24px);\n    }\n    60% {\n        transform: translateY(0);\n    }\n    100% {\n        transform: translateY(0);\n        opacity: 1;\n    }\n"])));
exports.fadeText = styled_components_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-fill-mode: forwards;\n"], ["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-fill-mode: forwards;\n"])), exports.fadeDownText, constants_1.TRANSITION_CURVE);
var templateObject_1, templateObject_2;
