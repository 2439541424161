import * as React from "react"

export const UIContext = React.createContext({
	navFoldoutOpen: false,
	toggleNavFoldout: (open?: boolean) => {},
	megaMenuOpen: false,
	toggleMegaMenu: (open?: boolean) => {}
})

export const UIContextProvider: React.FC = props => {
	const [navFoldoutOpen, setNavFoldoutOpen] = React.useState(false)
	const [megaMenuOpen, setMegaMenuOpen] = React.useState(false)

	const toggleNavFoldout = open => {
		if (open === false) {
			setNavFoldoutOpen(false)
		} else {
			setNavFoldoutOpen(!navFoldoutOpen)
		}
	}

	const toggleMegaMenu = open => {
		if (open === false) {
			setMegaMenuOpen(false)
		} else {
			setMegaMenuOpen(!megaMenuOpen)
		}
	}

	React.useEffect(() => {
		const handleKeyUp = e => {
			if (e.key === "Escape") {
				e.preventDefault()
				toggleMegaMenu(false)
			}
		}

		window.addEventListener("keyup", handleKeyUp)
		return () => window.removeEventListener("keyup", handleKeyUp)
	})

	return (
		<UIContext.Provider
			value={{
				navFoldoutOpen,
				toggleNavFoldout,
				megaMenuOpen,
				toggleMegaMenu
			}}
			{...props}
		/>
	)
}
