import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type ShowHeroBlockFieldsFragment = (
  { __typename?: 'showHeroBlock' }
  & Pick<Types.ShowHeroBlock, 'id' | 'themeColorVariant' | 'tag' | 'name' | 'title' | 'type' | 'riderAndCreator' | 'videoUrl'>
  & { image?: Types.Maybe<(
    { __typename?: 'image' }
    & Pick<Types.Image, 'id' | 'alt' | 'src' | 'placeholder'>
  )>, ctaButton?: Types.Maybe<(
    { __typename?: 'link' }
    & Pick<Types.Link, 'title' | 'target' | 'url'>
  ) | (
    { __typename?: 'stringBox' }
    & Pick<Types.StringBox, 'value'>
  )>, info?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'KeyValueGraphType' }
    & Pick<Types.KeyValueGraphType, 'label' | 'text' | 'asAccordion' | 'anchorLinkId' | 'icon'>
    & { link?: Types.Maybe<(
      { __typename?: 'link' }
      & Pick<Types.Link, 'title' | 'alt' | 'target' | 'url'>
    )> }
  )>>> }
);

export const ShowHeroBlockFieldsFragmentDoc = gql`
    fragment showHeroBlockFields on showHeroBlock {
  id
  themeColorVariant
  tag
  name
  title
  type
  riderAndCreator
  image {
    id
    alt
    src
    placeholder
  }
  videoUrl
  ctaButton: cta {
    ... on link {
      title
      target
      url
    }
    ... on stringBox {
      value
    }
  }
  info {
    label
    text
    asAccordion
    anchorLinkId
    icon
    link {
      title
      alt
      target
      url
    }
  }
}
    `;