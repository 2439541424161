"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_breakpoint_1 = require("styled-components-breakpoint");
var breakpointsMap = {
    xxs: 414,
    xs: 468,
    s: 640,
    m: 769,
    l: 968,
    xl: 1240,
    xxl: 1440,
    xxxl: 1600
};
exports.breakpointsMap = breakpointsMap;
var breakpoints = Object.keys(breakpointsMap).reduce(function (acc, key) { return __spreadArrays(acc, [breakpointsMap[key] + "px"]); }, []);
exports.breakpoints = breakpoints;
var breakpoint = styled_components_breakpoint_1.createBreakpoint(breakpointsMap);
exports.breakpoint = breakpoint;
breakpoints.xxs = breakpoints[0];
breakpoints.xs = breakpoints[1];
breakpoints.s = breakpoints[2];
breakpoints.m = breakpoints[3];
breakpoints.l = breakpoints[4];
breakpoints.xl = breakpoints[5];
breakpoints.xxl = breakpoints[6];
breakpoints.xxxl = breakpoints[7];
