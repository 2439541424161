"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var styled_components_1 = __importStar(require("styled-components"));
var styled_system_1 = require("styled-system");
var typography_1 = require("../../styles/constants/typography");
var styled_system_2 = require("../../styles/styled-system");
var react_router_dom_1 = require("react-router-dom");
exports.textStyles = styled_components_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t", "\n\t", "\n\t", "\n\t", "\n\t", "\n"], ["\n\t", "\n\t", "\n\t", "\n\t",
    "\n\t",
    "\n"
    /**
     * Text element
     */
])), styled_system_2.typography, styled_system_1.display, styled_system_1.alignItems, function (props) {
    return styled_system_2.typography(__assign(__assign({}, props), { textColor: props.textColor ? props.textColor : props.theme.textColor, theme: props.theme }));
}, styled_system_1.variant({
    prop: "truncate",
    variants: {
        singleLine: {
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        },
        twoLine: {
            overflow: "hidden",
            display: "-webkit-box",
            "-webkit-line-clamp": "2",
            "-webkit-box-orient": "vertical"
        },
        threeLine: {
            overflow: "hidden",
            display: "-webkit-box",
            "-webkit-line-clamp": "3",
            "-webkit-box-orient": "vertical"
        },
        none: {
            overflow: "visisble",
            display: "inline",
            "-webkit-line-clamp": "none",
            "-webkit-box-orient": "initial",
            whiteSpace: "normal"
        }
    }
}));
/**
 * Text element
 */
exports.TextStyled = styled_components_1.default.p.withConfig({
    shouldForwardProp: function (prop, defaultValidatorFn) {
        return ![
            "fontSize",
            "fontWeight",
            "fontFamily",
            "letterSpacing",
            "textTransform"
        ].includes(prop) && defaultValidatorFn(prop);
    }
})(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), exports.textStyles);
exports.Text = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? "paragraph-1" : _b, rest = __rest(_a, ["variant"]);
    return (React.createElement(exports.TextStyled, __assign({}, typography_1.variants[variant], rest)));
};
/**
 * Link element
 */
var LinkStyled = styled_components_1.default(react_router_dom_1.Link).withConfig({
    shouldForwardProp: function (prop, defaultValidatorFn) {
        return ![
            "fontSize",
            "fontWeight",
            "fontFamily",
            "letterSpacing",
            "textTransform",
            "active"
        ].includes(prop) && defaultValidatorFn(prop);
    }
})(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), exports.textStyles);
exports.Text.Link = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? "paragraph-1" : _b, rest = __rest(_a, ["variant"]);
    return (React.createElement(LinkStyled, __assign({}, typography_1.variants[variant], { as: react_router_dom_1.Link }, rest)));
};
/**
 * Anchor element
 */
exports.AnchorStyled = styled_components_1.default.a(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), exports.textStyles);
exports.Text.Anchor = function (_a) {
    var _b = _a.variant, variant = _b === void 0 ? "paragraph-1" : _b, rest = __rest(_a, ["variant"]);
    return (React.createElement(exports.AnchorStyled, __assign({}, typography_1.variants[variant], { as: "a" }, rest)));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
