import * as React from "react"

export const FallbackImage: React.FC = () => (
	<img
		src="https://gocmsdev.blob.core.windows.net/qbank/1600w/operan-fallback.jpg"
		alt=""
	/>
)

export const profileFallbackImageSrc =
	"https://gocmsdev.blob.core.windows.net/qbank/600w/fallbackportrait.jpg"
