import * as React from "react"
import { Transition } from "react-transition-group"
import { TRANSITION_TIMING } from "@operan/ui-components"

interface PageWrapperProps {
	in: any
}

const duration = 200

const defaultStyle = {
	transition: `opacity ${duration}ms ${TRANSITION_TIMING}`,
	opacity: 0
}

const transitionStyles = {
	entering: { opacity: 1 },
	entered: { opacity: 1 },
	exiting: { opacity: 0 },
	exited: { opacity: 0 }
}

export const PageWrapper: React.FC<PageWrapperProps> = ({
	in: inProp,
	children
}) => (
	<Transition in={inProp} timeout={duration}>
		{state => (
			<div
				style={{
					...defaultStyle,
					...transitionStyles[state]
				}}
			>
				{children}
			</div>
		)}
	</Transition>
)
