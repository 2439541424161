"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var styled_components_1 = __importStar(require("styled-components"));
var components_1 = require("../../components");
var styles_1 = require("../../styles");
var styled_components_2 = require("styled-components");
var underlinedLinkStyles = styled_components_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tposition: relative;\n\tdisplay: inline-block;\n\tfont-size: ", ";\n\tfont-weight: ", ";\n\tletter-spacing: 1px;\n\ttext-transform: uppercase;\n\tpadding-top: ", ";\n\tpadding-bottom: 10px;\n\ttext-decoration: none;\n\toverflow: hidden;\n\tcolor: ", ";\n\n\t&:after {\n\t\tcontent: \"\";\n\t\tdisplay: block;\n\t\tposition: absolute;\n\t\tbottom: 0;\n\t\tleft: 0;\n\t\theight: 4px;\n\t\twidth: 100%;\n\t\tbackground-color: ", ";\n\t\ttransform: scaleY(1);\n\t\ttransition: transform 0.1s linear;\n\t}\n\n\tsvg {\n\t\tposition: absolute;\n\t\tleft: 0;\n\t\ttop: 8px;\n\t\ttransform: translateX(0);\n\t\ttransition: transform 0.3s cubic-bezier(0.789, 0.301, 0, 0.956);\n\t}\n\n\t&:hover {\n\t\t&:after {\n\t\t\ttransform: scaleY(0.3);\n\t\t}\n\n\t\tsvg {\n\t\t\ttransform: translateX(3px);\n\t\t}\n\t}\n"], ["\n\tposition: relative;\n\tdisplay: inline-block;\n\tfont-size: ", ";\n\tfont-weight: ", ";\n\tletter-spacing: 1px;\n\ttext-transform: uppercase;\n\tpadding-top: ", ";\n\tpadding-bottom: 10px;\n\ttext-decoration: none;\n\toverflow: hidden;\n\tcolor: ", ";\n\n\t&:after {\n\t\tcontent: \"\";\n\t\tdisplay: block;\n\t\tposition: absolute;\n\t\tbottom: 0;\n\t\tleft: 0;\n\t\theight: 4px;\n\t\twidth: 100%;\n\t\tbackground-color: ",
    ";\n\t\ttransform: scaleY(1);\n\t\ttransition: transform 0.1s linear;\n\t}\n\n\tsvg {\n\t\tposition: absolute;\n\t\tleft: 0;\n\t\ttop: 8px;\n\t\ttransform: translateX(0);\n\t\ttransition: transform 0.3s cubic-bezier(0.789, 0.301, 0, 0.956);\n\t}\n\n\t&:hover {\n\t\t&:after {\n\t\t\ttransform: scaleY(0.3);\n\t\t}\n\n\t\tsvg {\n\t\t\ttransform: translateX(3px);\n\t\t}\n\t}\n"])), styles_1.scale.px(1.75), styles_1.fontWeight.bold, styles_1.scale.px(1), function (_a) {
    var theme = _a.theme;
    return theme.textColor;
}, function (_a) {
    var theme = _a.theme;
    return theme.dark ? styles_1.colors["white"] : styles_1.colors["black-light"];
});
var defaultProps = {
    withIcon: false
};
exports.UnderlinedLinkStyled = styled_components_1.default.p(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), underlinedLinkStyles);
exports.UnderlinedLink = function (props) { return (React.createElement(exports.UnderlinedLinkStyled, __assign({}, props), props.label)); };
exports.UnderlinedLink.defaultProps = defaultProps;
var LinkStyled = styled_components_1.default(react_router_dom_1.Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), underlinedLinkStyles);
exports.UnderlinedLink.Link = function (_a) {
    var withIcon = _a.withIcon, label = _a.label, rest = __rest(_a, ["withIcon", "label"]);
    var themeContext = React.useContext(styled_components_2.ThemeContext);
    return (React.createElement(LinkStyled, __assign({}, rest),
        React.createElement(components_1.Flex, { pl: withIcon ? 3 : 0 }, label),
        withIcon && (React.createElement(components_1.Icon, { icon: "arrow-right", size: 1.5, color: themeContext.dark ? "white" : "black-light" }))));
};
var AnchorStyled = styled_components_1.default.a(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), underlinedLinkStyles);
exports.UnderlinedLink.Anchor = function (_a) {
    var withIcon = _a.withIcon, label = _a.label, as = _a.as, ref = _a.ref, rest = __rest(_a, ["withIcon", "label", "as", "ref"]);
    var themeContext = React.useContext(styled_components_2.ThemeContext);
    return (React.createElement(AnchorStyled, __assign({}, rest),
        React.createElement(components_1.Flex, { pl: withIcon ? 3 : 0 }, label),
        withIcon && (React.createElement(components_1.Icon, { icon: "arrow-right", size: 1.5, color: themeContext.dark ? "white" : "black-light" }))));
};
var ButtonStyled = styled_components_1.default.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), underlinedLinkStyles);
exports.UnderlinedLink.Button = function (_a) {
    var withIcon = _a.withIcon, label = _a.label, as = _a.as, ref = _a.ref, type = _a.type, rest = __rest(_a, ["withIcon", "label", "as", "ref", "type"]);
    var themeContext = React.useContext(styled_components_2.ThemeContext);
    return (React.createElement(ButtonStyled, __assign({}, rest),
        React.createElement(components_1.Flex, { pl: withIcon ? 3 : 0 }, label),
        withIcon && (React.createElement(components_1.Icon, { icon: "arrow-right", size: 1.5, color: themeContext.dark ? "white" : "black-light" }))));
};
exports.UnderlinedLink.Anchor.defaultProps = defaultProps;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
