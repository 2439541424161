import React from "react"
import { BrowserRouter } from "react-router-dom"
import { ApolloClient, ApolloProvider } from "@apollo/client"
import { config, Layout } from "@operan/www-common"
import { localeCodeFromUrl } from "@operan/www-common/utils/locale"
import { AppContextProvider } from "./context/AppContext"
import smoothscroll from "smoothscroll-polyfill"
import "./vendor/waiter-aid"
smoothscroll.polyfill()

const localeCode = localeCodeFromUrl(window.location.pathname)

const client = new ApolloClient({
	...config.apolloClient,
	cache: config.apolloClient.cache().restore(window.__APOLLO_STATE__),
	headers: {
		"x-site": localeCode
	}
})

const App: React.FC = () => (
	<BrowserRouter>
		<ApolloProvider client={client}>
			<AppContextProvider localesContextProviderProps={{ localeCode }}>
				<Layout />
			</AppContextProvider>
		</ApolloProvider>
	</BrowserRouter>
)

export default App
