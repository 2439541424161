import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type RteBlockFieldsFragment = (
  { __typename?: 'rteBlock' }
  & Pick<Types.RteBlock, 'id' | 'themeColorVariant' | 'type' | 'text'>
  & { theme?: Types.Maybe<(
    { __typename?: 'theme' }
    & Pick<Types.Theme, 'dark' | 'complementaryColor' | 'primaryColor' | 'textColor'>
  )> }
);

export const RteBlockFieldsFragmentDoc = gql`
    fragment rteBlockFields on rteBlock {
  id
  themeColorVariant
  theme {
    dark
    complementaryColor
    primaryColor
    textColor
  }
  type
  text
}
    `;