import { CommonSearchResultItem } from "../../../@types/graphql.generated"
import * as React from "react"
import {
	Button,
	Flex,
	fontFamilyHeadings,
	mergeColorTheme,
	SearchResultItem,
	Text
} from "@operan/ui-components"
import { qBankImageSrc } from "../../../utils"
import { Link } from "react-router-dom"
import { ThemeProvider } from "styled-components"

interface SearchResultItemShowProps {
	result: CommonSearchResultItem
}

export const SearchResultItemShow = React.forwardRef<
	HTMLAnchorElement,
	SearchResultItemShowProps
>((props, ref) => {
	return (
		<ThemeProvider theme={mergeColorTheme(props.result.theme)}>
			<SearchResultItem
				isShow
				image={
					props.result.image ? (
						<img
							src={qBankImageSrc(props.result.image.src, "768")}
							alt={props.result.image.alt || ""}
						/>
					) : null
				}
			>
				<Flex
					p={{ _: 2.5, m: 0 }}
					flexDirection="column"
					alignItems={{ _: "center", m: "flex-start" }}
				>
					<SearchResultItem.Header isShow>
						{props.result.tag && (
							<SearchResultItem.Tag isShow>
								{props.result.tag}
							</SearchResultItem.Tag>
						)}
						{props.result.dateRange && props.result.dateRange.from && (
							<SearchResultItem.Date isShow>
								{props.result.dateRange.to
									? `${props.result.dateRange.from.formatted.date} - ${props.result.dateRange.to.formatted.date}`
									: props.result.dateRange.from.formatted.date}
							</SearchResultItem.Date>
						)}
					</SearchResultItem.Header>

					{props.result.title && (
						<Link ref={ref} to={props.result.title.url}>
							<Text
								as="h2"
								fontFamily={fontFamilyHeadings}
								fontSize={{ _: 2.25, m: 3 }}
								lineHeight={{ _: 3, m: 4 }}
								style={{ textDecoration: "underline" }}
								textAlign={{ _: "center", m: "left" }}
							>
								{props.result.title.title}
							</Text>
						</Link>
					)}

					{props.result.preamble && (
						<SearchResultItem.Content>
							<Text
								textAlign={{ _: "center", m: "left" }}
								fontFamily={fontFamilyHeadings}
								fontSize={{ _: 3, m: 4 }}
								lineHeight={{ _: 4, m: 5 }}
								mt={1}
								mb={0}
								truncate="twoLine"
							>
								{props.result.preamble}
							</Text>
						</SearchResultItem.Content>
					)}
					{props.result.ticketUrl && (
						<Flex mt={2} mb={{ _: 4, m: 0 }}>
							{props.result.ticketUrl.__typename === "link" ? (
								<Button.Anchor
									backgroundColor="gold"
									textColor="black-light"
									href={props.result.ticketUrl.url}
									target={`_${props.result.ticketUrl.target}`}
									rel="nofollow noreferrer"
									rounded
									size="large"
									label={props.result.ticketUrl.title}
								/>
							) : (
								<Text
									textColor={props.result.theme.dark ? "white" : "black"}
									fontWeight="semibold"
									mb={0}
								>
									{props.result.ticketUrl.value}
								</Text>
							)}
						</Flex>
					)}
				</Flex>
			</SearchResultItem>
		</ThemeProvider>
	)
})
