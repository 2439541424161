import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type RelatedPagesBlockFieldsFragment = (
  { __typename?: 'relatedPagesBlock' }
  & Pick<Types.RelatedPagesBlock, 'id' | 'title' | 'type' | 'themeColorVariant'>
  & { theme?: Types.Maybe<(
    { __typename?: 'theme' }
    & Pick<Types.Theme, 'dark' | 'complementaryColor' | 'primaryColor'>
  )>, cta?: Types.Maybe<(
    { __typename?: 'CtaGraphType' }
    & Pick<Types.CtaGraphType, 'label' | 'url'>
  )>, pages?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'pageSummary' }
    & Pick<Types.PageSummary, 'tag' | 'name' | 'meta' | 'title' | 'preamble' | 'url'>
    & { theme?: Types.Maybe<(
      { __typename?: 'theme' }
      & Pick<Types.Theme, 'dark' | 'complementaryColor' | 'primaryColor' | 'textColor'>
    )>, image?: Types.Maybe<(
      { __typename?: 'image' }
      & Pick<Types.Image, 'src' | 'placeholder'>
    )> }
  )>>> }
);

export const RelatedPagesBlockFieldsFragmentDoc = gql`
    fragment relatedPagesBlockFields on relatedPagesBlock {
  id
  title
  type
  themeColorVariant
  theme {
    dark
    complementaryColor
    primaryColor
  }
  cta {
    label
    url
  }
  pages {
    tag
    name
    meta
    title
    preamble
    url
    theme {
      dark
      complementaryColor
      primaryColor
      textColor
    }
    image {
      src
      placeholder
    }
  }
}
    `;