import * as React from "react"
import queryString from "query-string"
import { SearchQueryVariables, SearchQuery } from "./operations.generated"
import { SearchResultItemDefault } from "./components/SearchResultItemDefault"
import { SearchResultItemShow } from "./components/SearchResultItemShow"
import { SearchResultItemProfile } from "./components/SearchResultItemProfile"
import { sendMatomoEvent } from "../../utils"

const searchResultItemType = {
	showPage: SearchResultItemShow,
	profilePage: SearchResultItemProfile
}

const getSearchResultItemByType = (contentType: string): React.FC<any> =>
	searchResultItemType[contentType] || React.Fragment

export const mapSearchResultItems = (
	searchQueryData: SearchQuery,
	ref: React.MutableRefObject<HTMLAnchorElement>
) => {
	const { results } = searchQueryData.search

	return results.map((result, i) => {
		const component =
			result.type in searchResultItemType && !result.passed
				? getSearchResultItemByType(result.type)
				: SearchResultItemDefault
		return React.createElement(component, {
			key: `${result.title.title}_${i}`,
			result,
			ref: i === 0 ? ref : null
		})
	})
}

interface SearchQueryStringObject {
	[key: string]: string[] | string
}

export const parseSearchQueryString = (
	searchQueryString: string
): SearchQueryStringObject => {
	let searchQueryStringObject: SearchQueryStringObject = {}

	try {
		searchQueryStringObject = queryString.parse(searchQueryString, {
			arrayFormat: "bracket"
		})
	} catch {
		searchQueryStringObject = {}
	}

	return searchQueryStringObject
}

// Parse searchQueryVaribles into url encoded query string
export const searchQueryVariablesToSearchQueryString = (
	searchQueryVariables: SearchQueryVariables
) =>
	queryString.stringify(
		Object.keys(searchQueryVariables).reduce<SearchQueryStringObject>(
			(acc, key) => ({
				...acc,
				...(searchQueryVariables.searchInput !== ""
					? {
							q: searchQueryVariables.searchInput,
							page: searchQueryVariables.pageInput.page.toString()
					  }
					: {})
			}),
			{}
		),
		{
			arrayFormat: "bracket",
			sort: false
		}
	)

/**
 *   Push Motomo event on search
 */
export const trackSearchTermHitsCount = (
	searchTerm: string,
	totalSize: number
) => {
	sendMatomoEvent({
		event: "Search Result Page",
		searchTerm,
		totalSearchHits: totalSize
	})
}

export const trackSearchResultItemClick = (
	searchTerm: string,
	clickTarget: string,
	searchResultIndex: number,
	page: number,
	totalSize: number
) => {
	sendMatomoEvent({
		event: "Click In Serp",
		searchTerm,
		clickTarget,
		searchResultIndex,
		page,
		totalSearchHits: totalSize
	})
}
