import React from "react"
import { Flex } from "@operan/ui-components"

export interface ISHellProps {
	head?: React.FC
	main?: React.FC
	footer?: React.FC
}

export const AppShell: React.FC<ISHellProps> = props => {
	const { head: Head, main: Main, footer: Footer } = props

	return (
		<Flex h={1} flexDirection="column">
			{Head && <Head />}
			{Main && <Main />}
			{Footer && <Footer />}
		</Flex>
	)
}
