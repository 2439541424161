"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_1 = require("styled-components");
var constants_1 = require("../../constants");
exports.zoomIn = styled_components_1.keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    0% {\n        transform: scale(1.1);\n    }\n\n    100% {\n        transform: scale(1);\n    }\n"], ["\n    0% {\n        transform: scale(1.1);\n    }\n\n    100% {\n        transform: scale(1);\n    }\n"])));
exports.fadeIn = styled_components_1.keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    0% {\n        opacity: 0;\n        transform: translateY(24px);\n    }\n    60% {\n        transform: translateY(0);\n    }\n    100% {\n        transform: translateY(0);\n        opacity: 1;\n    }\n"], ["\n    0% {\n        opacity: 0;\n        transform: translateY(24px);\n    }\n    60% {\n        transform: translateY(0);\n    }\n    100% {\n        transform: translateY(0);\n        opacity: 1;\n    }\n"])));
exports.zoomInImage = styled_components_1.css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-delay: 0.3s;\n\tanimation-fill-mode: both;\n"], ["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-delay: 0.3s;\n\tanimation-fill-mode: both;\n"])), exports.zoomIn, constants_1.TRANSITION_CURVE);
exports.fadeInContainer = styled_components_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-delay: 0.3s;\n\tanimation-fill-mode: both;\n"], ["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-delay: 0.3s;\n\tanimation-fill-mode: both;\n"])), exports.fadeIn, constants_1.TRANSITION_CURVE);
exports.fadeInText = styled_components_1.css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-fill-mode: both;\n"], ["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-fill-mode: both;\n"])), exports.fadeIn, constants_1.TRANSITION_CURVE);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
