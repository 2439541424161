import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type VideoPageIntroBlockFieldsFragment = (
  { __typename?: 'videoPageIntroBlock' }
  & Pick<Types.VideoPageIntroBlock, 'id' | 'type' | 'title' | 'preamble' | 'text' | 'fromDate' | 'toDate' | 'videoURL' | 'themeColorVariant'>
  & { link?: Types.Maybe<(
    { __typename?: 'link' }
    & Pick<Types.Link, 'title' | 'url' | 'target'>
  )>, videoDetails?: Types.Maybe<(
    { __typename?: 'vimeoDetails' }
    & Pick<Types.VimeoDetails, 'title' | 'videoThumbnail'>
  )>, theme?: Types.Maybe<(
    { __typename?: 'theme' }
    & Pick<Types.Theme, 'complementaryColor' | 'dark' | 'primaryColor' | 'textColor'>
  )> }
);

export const VideoPageIntroBlockFieldsFragmentDoc = gql`
    fragment videoPageIntroBlockFields on videoPageIntroBlock {
  id
  type
  title
  preamble
  text
  fromDate
  toDate
  videoURL
  themeColorVariant
  link {
    title
    url
    target
  }
  videoDetails {
    title
    videoThumbnail
  }
  theme {
    complementaryColor
    dark
    primaryColor
    textColor
  }
}
    `;