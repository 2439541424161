"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var styled_components_1 = __importStar(require("styled-components"));
var styles_1 = require("../../styles");
var components_1 = require("../../components");
var counterButtonStyles = styled_components_1.css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tdisplay: inline-flex;\n\talign-items: center;\n\tjustify-content: center;\n\twhite-space: nowrap;\n\tpadding: 0 ", ";\n\tcursor: pointer;\n\n\t", "\n\n\t&:disabled {\n\t\topacity: 30%;\n\t\tcursor: default;\n\t}\n\n\t&:hover {\n\t\ttext-decoration: none;\n\t}\n"], ["\n\tdisplay: inline-flex;\n\talign-items: center;\n\tjustify-content: center;\n\twhite-space: nowrap;\n\tpadding: 0 ", ";\n\tcursor: pointer;\n\n\t",
    "\n\n\t&:disabled {\n\t\topacity: 30%;\n\t\tcursor: default;\n\t}\n\n\t&:hover {\n\t\ttext-decoration: none;\n\t}\n"])), styles_1.scale.px(2), styles_1.breakpoint("m")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        padding: 0 ", ";\n    "], ["\n        padding: 0 ", ";\n    "])), styles_1.scale.px(4)));
var CounterButtonDecrement = styled_components_1.default.button.attrs(function (props) { return ({
    "aria-controls": props.ariaControls,
    "aria-label": props.decrementBy
}); })(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), counterButtonStyles);
var CounterButtonIncrement = styled_components_1.default.button.attrs(function (props) { return ({
    "aria-controls": props.ariaControls,
    "aria-label": props.incrementBy
}); })(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), counterButtonStyles);
var InputStyled = styled_components_1.default.input(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tflex: 1;\n\twidth: 100%;\n\tmin-width: 0; // Firefox fix\n\talign-self: stretch;\n\tfont-size: ", ";\n\ttext-align: center;\n\tbackground-color: rgba(255, 255, 255, 0.6);\n\t-moz-appearance: textfield;\n\tborder: none;\n\tfont-family: ", ";\n\tpadding: ", " 0;\n\n\t&::-webkit-inner-spin-button,\n\t&::-webkit-outer-spin-button {\n\t\t-webkit-appearance: none;\n\t\tmargin: 0;\n\t}\n\n\t&[disabled] {\n\t\topacity: 0.5;\n\t}\n"], ["\n\tflex: 1;\n\twidth: 100%;\n\tmin-width: 0; // Firefox fix\n\talign-self: stretch;\n\tfont-size: ", ";\n\ttext-align: center;\n\tbackground-color: rgba(255, 255, 255, 0.6);\n\t-moz-appearance: textfield;\n\tborder: none;\n\tfont-family: ", ";\n\tpadding: ", " 0;\n\n\t&::-webkit-inner-spin-button,\n\t&::-webkit-outer-spin-button {\n\t\t-webkit-appearance: none;\n\t\tmargin: 0;\n\t}\n\n\t&[disabled] {\n\t\topacity: 0.5;\n\t}\n"])), styles_1.scale.px(3), styles_1.fontFamilyHeadings, styles_1.scale.px(1.5));
var CounterStyled = styled_components_1.default.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\tdisplay: flex;\n\twidth: 100%;\n\tbackground-color: ", ";\n\tborder: ", ";\n\tpadding: ", " 0;\n\tborder-radius: 4px;\n\n\t", " {\n\t\tcolor: ", ";\n\t}\n"], ["\n\tdisplay: flex;\n\twidth: 100%;\n\tbackground-color: ",
    ";\n\tborder: ", ";\n\tpadding: ", " 0;\n\tborder-radius: 4px;\n\n\t", " {\n\t\tcolor: ", ";\n\t}\n"])), function (props) {
    return props.error ? styles_1.colors["white"] : styles_1.colors["brown-normal"];
}, function (props) { return (props.error ? "1px solid " + styles_1.colors["red"] : "none"); }, styles_1.scale.px(1), InputStyled, function (props) { return (props.error ? styles_1.colors["red-dark"] : styles_1.colors["black"]); });
var Counter = function (_a) {
    var _b;
    var onChange = _a.onChange, rest = __rest(_a, ["onChange"]);
    var _c = React.useState(rest.initialValue), internalValue = _c[0], setInternalValue = _c[1];
    var value = (_b = rest.value, (_b !== null && _b !== void 0 ? _b : internalValue));
    var onDecrement = rest.onDecrement ||
        function () {
            setInternalValue(function (prev) { return prev - rest.incrementBy; });
        };
    var onIncrement = rest.onIncrement ||
        function () {
            setInternalValue(function (prev) { return prev + rest.incrementBy; });
        };
    var onInputChange = rest.onInputChange ||
        function (e) {
            setInternalValue(Number(e.target.value));
        };
    var onInputFocus = function (e) {
        e.target.select();
    };
    React.useEffect(function () {
        onChange && onChange(internalValue);
    }, [onChange, internalValue]);
    return (React.createElement(CounterStyled, { id: rest.id, error: rest.error },
        React.createElement(CounterButtonDecrement, __assign({ ariaControls: rest.id, onClick: onDecrement, disabled: rest.disabled || value === 0 }, rest.locales),
            React.createElement(components_1.Icon, { icon: "minus", size: 2 })),
        React.createElement(InputStyled, { onChange: onInputChange, onFocus: onInputFocus, value: value, type: "number", disabled: value === 999 || rest.disabled }),
        React.createElement(CounterButtonIncrement, __assign({ ariaControls: rest.id, onClick: onIncrement, disabled: value === 999 || rest.disabled }, rest.locales),
            React.createElement(components_1.Icon, { icon: "plus", size: 2 }))));
};
exports.Counter = Counter;
Counter.defaultProps = {
    id: "counter",
    initialValue: 0,
    incrementBy: 1,
    disabled: false
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
