"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styled_system_1 = require("styled-system");
var configs = {
    textColor: {
        property: "color",
        scale: "colors"
    },
    backgroundColor: {
        property: "backgroundColor",
        scale: "colors"
    }
};
exports.color = styled_system_1.system(configs);
