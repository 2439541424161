import * as React from "react"
import { Slideshow, Flex, INNER_CONTAINER_MAX_WIDTH } from "@operan/ui-components"
import { Block } from "../Block/Block"
import {
	SlideshowItem,
	SlideshowBlock as ISlideshowBlock,
	BlockType
} from "../../@types/graphql.generated"
import { dataToProps, trackSlideshowInteraction } from "./utils"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { BlockTitlePreamble } from "../../utils/layout"
import { useLocation } from "react-router"

export interface SlideshowBlockProps extends ISlideshowBlock {
	slideshowItems: SlideshowItem[]
}

const SlideshowBlock: React.FC<SlideshowBlockProps> = props => {
	const locales = useLocales(
		translationNamespace.block(BlockType.SlideshowBlock),
		[
			"participantsLabel",
			"photographerLabel",
			"nextSlideLabel",
			"previousSlideLabel"
		]
	)
	const location = useLocation()
	const [userHasInteracted, setUserHasInteracted] = React.useState(false)

	return (
		<Block themeColorVariant={props.themeColorVariant} theme={props.theme}>
			<Flex py={{ _: 8, l: 8 }} px={{ _: 0, l: 20 }}>
				<Flex
					alignItems="center"
					flexDirection="column"
					w={1}
					h={1}
					maxw={INNER_CONTAINER_MAX_WIDTH}
					style={{ margin: "0 auto" }}
				>
					{props.title && (
						<BlockTitlePreamble
							title={props.title}
							preamble={props.preamble || null}
						/>
					)}
					{props.slideshowItems && (
						<Slideshow
							items={dataToProps(props.slideshowItems, locales)}
							themeColorVariant={props.themeColorVariant}
							onClickListener={() => {
								if (!userHasInteracted) {
									trackSlideshowInteraction(location.pathname)
									setUserHasInteracted(true)
								}
							}}
							locales={{
								nextSlide: locales.nextSlideLabel,
								previousSlide: locales.previousSlideLabel
							}}
						/>
					)}
				</Flex>
			</Flex>
		</Block>
	)
}

export { SlideshowBlock }
