"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var styled_system_1 = require("styled-system");
var calc_1 = require("../utils/calc");
var utils_1 = require("../utils");
var spacing_1 = require("./spacing");
var color_1 = require("./color");
var config = {
    fontFamily: {
        property: "fontFamily"
    },
    fontSize: {
        property: "fontSize",
        transform: calc_1.scale.px
    },
    fontWeight: {
        property: "fontWeight",
        transform: utils_1.getFontWeight
    },
    lineHeight: {
        property: "lineHeight",
        transform: calc_1.scale.px
    },
    letterSpacing: {
        property: "letterSpacing",
        transform: calc_1.scale.px
    },
    textTransform: {
        property: "textTransform"
    },
    textColor: {
        property: "color",
        scale: "colors"
    },
    textAlign: true,
    fontStyle: true
};
exports.typography = styled_system_1.compose(spacing_1.space, color_1.color, styled_system_1.system(config));
