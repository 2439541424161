import * as React from "react"
import {
	AccordionBlock as IAccordionBlock,
	BlockType
} from "../../@types/graphql.generated"
import { ThemeProvider } from "styled-components"
import {
	Rte,
	Flex,
	Accordion,
	Text,
	lightTheme,
	INNER_CONTAINER_MAX_WIDTH,
	READABLE_LINE_MAX_WIDTH
} from "@operan/ui-components"
import { useLocales, translationNamespace } from "../../hooks/useLocales"

function createMarkup(markup: string) {
	return { __html: markup }
}

const AccordionBlock: React.FC<IAccordionBlock> = props => {
	const locales = useLocales(
		translationNamespace.block(BlockType.AccordionBlock),
		["toggleLabel"]
	)

	return (
		<ThemeProvider theme={lightTheme}>
			<Flex
				display="block"
				py={{ _: 8, l: 18 }}
				px={{ _: 2, s: 5, l: 10, xxl: 20 }}
				backgroundColor="brown-normal"
			>
				<Flex
					maxw={INNER_CONTAINER_MAX_WIDTH}
					w={1}
					style={{ margin: "0 auto" }}
					flexDirection="column"
				>
					{props.title && (
						<Flex
							maxw={READABLE_LINE_MAX_WIDTH}
							flexDirection="column"
							mb={{ _: 3, m: 5 }}
						>
							<Text variant="headline-2">{props.title}</Text>
							{props.preamble && (
								<Text variant="headline-4" as="p">
									{props.preamble}
								</Text>
							)}
						</Flex>
					)}
					{props.items.map((item, idx) => (
						<Accordion key={idx}>
							<Accordion.Header
								id={`item-${idx}`}
								toggleLabel={locales.toggleLabel}
							>
								<Text
									variant="paragraph-1"
									fontSize={2.5}
									fontWeight="semibold"
									textColor={"black-light"}
									mb={0}
								>
									{item.title}
								</Text>
							</Accordion.Header>
							<Accordion.Panel id={`item-${idx}`}>
								<Rte>
									{item.content && (
										<div dangerouslySetInnerHTML={createMarkup(item.content)} />
									)}
								</Rte>
							</Accordion.Panel>
						</Accordion>
					))}
				</Flex>
			</Flex>
		</ThemeProvider>
	)
}

export { AccordionBlock }
