import * as React from "react"
import {
	Flex,
	BlockAlert,
	Icon,
	IconTypes,
	Rte,
	READABLE_LINE_MAX_WIDTH
} from "@operan/ui-components"
import { ShowAlertBlock as IShowAlertBlock } from "../../@types/graphql.generated"
import { ThemeContext } from "styled-components"

function createMarkup(markup: string) {
	return { __html: markup }
}

const ShowAlertBlock: React.FC<IShowAlertBlock> = props => {
	const theme = React.useContext(ThemeContext)

	return (
		<Flex display="block" style={{ margin: "0 auto" }} py={{ _: 8, l: 8 }}>
			<Flex
				maxw={READABLE_LINE_MAX_WIDTH}
				w={1}
				style={{ margin: "0 auto" }}
				pl={{ _: 2, m: 5, l: 11 }}
				pr={{ _: 2, m: 5, l: 11 }}
			>
				<BlockAlert>
					<BlockAlert.Icon>
						<Icon
							icon={props.icon.toLowerCase() as IconTypes}
							color={theme.dark ? "white" : "black"}
							size={{ _: 1.75, l: 3.5 }}
						/>
					</BlockAlert.Icon>
					{props.body && (
						<Rte>
							<div dangerouslySetInnerHTML={createMarkup(props.body)} />
						</Rte>
					)}
				</BlockAlert>
			</Flex>
		</Flex>
	)
}

export { ShowAlertBlock }
