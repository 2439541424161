import { sendMatomoEvent } from "../../utils"

/**
 *   Push Motomo event for clicks on mega menu links
 */
export const trackMegaMenuClickEvent = (linkTitle, linkTarget) => {
	sendMatomoEvent({
		event: "Main Navigation More Menu",
		analyticsinfo: linkTitle,
		clickTarget: linkTarget
	})
}

/**
 *   Push Motomo event for clicks on main menu links
 */
export const trackMainMenuClickEvent = linkTitle => {
	sendMatomoEvent({
		event: "Main Navigation",
		analyticsinfo: linkTitle
	})
}

/**
 *   Push Motomo event for clicks on main menu links
 */
export type ButtonLocation =
	| "Main menu"
	| "Show page hero"
	| "Header"
	| "Programme"
export const trackTicketLinkClickEvent = (
	buttonLabel: string,
	location: string,
	buttonLocation: ButtonLocation
) => {
	sendMatomoEvent({
		event: "Ticket Button",
		location: buttonLocation,
		analyticsinfo: buttonLabel,
		clickTarget: location
	})
}
