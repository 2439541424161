import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type ParticipantsBlockFieldsFragment = (
  { __typename?: 'participantsBlock' }
  & Pick<Types.ParticipantsBlock, 'id' | 'type' | 'themeColorVariant' | 'castListing' | 'crewListing'>
  & { theme?: Types.Maybe<(
    { __typename?: 'theme' }
    & Pick<Types.Theme, 'dark' | 'complementaryColor' | 'primaryColor' | 'textColor'>
  )>, castParticipants?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'profilePageHeroBlock' }
    & Pick<Types.ProfilePageHeroBlock, 'name' | 'title'>
    & { image?: Types.Maybe<(
      { __typename?: 'image' }
      & Pick<Types.Image, 'id' | 'src' | 'alt'>
    )>, url?: Types.Maybe<(
      { __typename?: 'link' }
      & Pick<Types.Link, 'url'>
    )> }
  )>>> }
);

export const ParticipantsBlockFieldsFragmentDoc = gql`
    fragment participantsBlockFields on participantsBlock {
  id
  type
  themeColorVariant
  theme {
    dark
    complementaryColor
    primaryColor
    textColor
  }
  castListing
  castParticipants {
    name
    title
    image {
      id
      src
      alt
    }
    url {
      url
    }
  }
  crewListing
}
    `;