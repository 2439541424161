import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type AccordionBlockFieldsFragment = (
  { __typename?: 'accordionBlock' }
  & Pick<Types.AccordionBlock, 'id' | 'type' | 'title' | 'preamble' | 'themeColorVariant'>
  & { items?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'accordionItem' }
    & Pick<Types.AccordionItem, 'title' | 'content'>
  )>>> }
);

export const AccordionBlockFieldsFragmentDoc = gql`
    fragment accordionBlockFields on accordionBlock {
  id
  type
  title
  preamble
  items {
    title
    content
  }
  themeColorVariant
}
    `;