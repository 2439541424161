import React from "react"
import { Link } from "react-router-dom"
import {
	Menu,
	MegaMenu,
	FoldoutSubMenu,
	CalendarButton,
	SearchLink,
	MyProfile
} from "../Menu"
import {
	Header as UIHeader,
	Flex,
	NavToggle,
	Foldout,
	HeaderLogo,
	lightTheme,
	SkipLink,
	Text
} from "@operan/ui-components"
import { UIContext } from "../../context/UIContext"
import { ThemeProvider } from "styled-components"
import {
	useGetMenuQuery,
	GetMenuQueryVariables
} from "../Menu/operations.generated"
import { useLocation } from "react-router"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { LanguageSwitch } from "../LanguageSwitch"

const Header: React.FC = () => {
	let location = useLocation()
	const getMenuQueryVariables: GetMenuQueryVariables = {
		path: location ? location.pathname : null
	}
	const menu = useGetMenuQuery({
		variables: getMenuQueryVariables
	})
	const { toggleNavFoldout, navFoldoutOpen } = React.useContext(UIContext)

	const locales = useLocales(translationNamespace.common("Header"), [
		"goLogoUrl",
		"operaLogoLinkLabel",
		"openMegaMenuLabel",
		"menu"
	])

	const skipLinkLocales = useLocales(translationNamespace.common("SkipLink"), [
		"skipLinkLabel"
	])

	return menu?.data?.main ? (
		<>
			<SkipLink label={skipLinkLocales.skipLinkLabel} />

			<UIHeader fixed={navFoldoutOpen}>
				<Flex h={1} alignItems="center">
					<Link
						to={locales.goLogoUrl}
						title={locales.operaLogoLinkLabel}
						onClick={() => toggleNavFoldout(false)}
					>
						<HeaderLogo />
					</Link>
					<Flex alignItems="center" ml="auto">
						<>
							{/* Desktop main menu */}
							<Flex display={{ _: "none", l: "flex" }}>
								<Menu menu={menu} />
							</Flex>
							<Flex
								display={{ _: "none", l: "flex" }}
								mr={{ _: 1, xl: 2.5 }}
								alignItems="center"
							>
								<Text
									fontWeight="bold"
									fontSize={2}
									mb={0}
									mr={{ l: 2.5, xl: 4 }}
									tabIndex={-1}
								>
									|
								</Text>
								<LanguageSwitch useShortTitle />
							</Flex>
							<MyProfile menu={menu} />
							<SearchLink menu={menu} />
							<CalendarButton menu={menu} />
							{/* Navtoggle for mobile view */}
							<Flex display={{ _: "block", l: "none" }} ml={{ _: 2, l: 0 }}>
								<NavToggle
									onClick={toggleNavFoldout}
									aria-label={locales.openMegaMenuLabel || ""}
									open={navFoldoutOpen}
									locales={{
										label: locales.menu
									}}
								/>
							</Flex>
						</>
					</Flex>
				</Flex>
			</UIHeader>
			<ThemeProvider theme={lightTheme}>
				<MegaMenu menu={menu} />

				{/* Mobile foldout menu */}
				<Flex display={{ _: "block", l: "none" }}>
					<Foldout open={navFoldoutOpen}>
						<Menu menu={menu} />
						<FoldoutSubMenu menu={menu} />
						<Flex
							justifyContent="flex-end"
							alignItems="center"
							mr={3.5}
							mt={-14}
						>
							<LanguageSwitch id="language-switch-header-mobile" useLabel />
						</Flex>
					</Foldout>
				</Flex>
			</ThemeProvider>
		</>
	) : null
}

export { Header }
