import * as React from "react"
import { ThemeProvider } from "styled-components"
import {
	Flex,
	Card,
	HorizontalScroller,
	Chip,
	darkTheme,
	mergeColorTheme,
	VisuallyHidden
} from "@operan/ui-components"
import {
	BlockType,
	ProgramListingBlock as IProgramListingBlock
} from "../../@types/graphql.generated"
import { Link } from "react-router-dom"
import { qBankImageSrc, FallbackImage } from "../../utils"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { BlockFullWidthLayout } from "../../utils/layout"
import { trackProgramFilterClick } from "./utils"

const ProgramListingBlock: React.FC<IProgramListingBlock> = props => {
	const programListingBlocklocales = useLocales(
		translationNamespace.block(BlockType.ProgramListingBlock),
		[
			"showAllLabel",
			"hardCodedFilterOptions",
			"filterFeedbackText",
			"filterToggleLabel"
		]
	)
	const commonLocales = useLocales(translationNamespace.common("Filter"), [
		"filterOn"
	])
	const locales = { ...programListingBlocklocales, ...commonLocales }

	const hardCodedFilterOptions = locales.hardCodedFilterOptions
		.split(",")
		.map(option => option.trim())

	const tags =
		props.pages?.reduce(
			(acc, page) => {
				return acc.indexOf(page.tag) === -1 && page.tag
					? [...acc, page.tag]
					: [...acc]
			},
			[...(hardCodedFilterOptions ? hardCodedFilterOptions : [])]
		) ?? []

	const [activeTag, setActiveTag] = React.useState(locales.showAllLabel)

	return (
		<ThemeProvider theme={darkTheme}>
			{props.pages && (
				<BlockFullWidthLayout>
					<Flex w={1}>
						<HorizontalScroller
							justifyContent={{ _: "flex-start", m: "flex-end" }}
						>
							<Flex ml={1}>
								<Chip
									aria-label={locales.filterToggleLabel.replace(
										/%s/g,
										locales.showAllLabel
									)}
									aria-pressed={
										activeTag === locales.showAllLabel ? "true" : "false"
									}
									onClick={() => {
										setActiveTag(locales.showAllLabel)
										trackProgramFilterClick(locales.showAllLabel)
									}}
									className={activeTag === locales.showAllLabel ? "active" : ""}
								>
									{locales.showAllLabel}
								</Chip>
							</Flex>
							{tags.map((tag, i) => (
								<Flex key={i} ml={1}>
									<Chip
										aria-label={locales.filterToggleLabel.replace(/%s/g, tag)}
										aria-pressed={activeTag === tag ? "true" : "false"}
										onClick={() => {
											setActiveTag(tag)
											trackProgramFilterClick(tag)
										}}
										className={activeTag === tag ? "active" : ""}
									>
										{tag}
									</Chip>
								</Flex>
							))}
						</HorizontalScroller>
						<VisuallyHidden role="region" aria-live="polite">
							{locales.filterFeedbackText.replace(/%s/g, activeTag)}
						</VisuallyHidden>
					</Flex>
					<Flex
						display={{ _: "block", s: "flex" }}
						flexWrap="wrap"
						width={{ _: 1, s: "calc(100% + 16px)", xl: "calc(100% + 32px)" }}
						ml={{ s: -1, xl: -2 }}
					>
						{props.pages
							.filter(
								page =>
									activeTag === locales.showAllLabel || activeTag === page.tag
							)
							.map((page, i) => (
								<Flex
									key={i}
									flex={{
										_: "0 0 100%",
										s: "0 0 50%",
										l: "0 0 33.33%",
										xxxl: "0 0 25%"
									}}
									flexDirection="column"
									px={{ _: 0, s: 1, xl: 1.5 }}
									pb={{ _: 0, s: 2, xl: 3 }}
								>
									<ThemeProvider
										theme={mergeColorTheme(page.theme || darkTheme)}
									>
										<Link
											to={page.url}
											title={page.name || ""}
											style={{ width: "100%", height: "100%" }}
										>
											<Card
												{...page}
												media={
													page.image ? (
														<img
															alt=""
															src={qBankImageSrc(page.image.src, "768")}
														/>
													) : (
														<FallbackImage />
													)
												}
											/>
										</Link>
									</ThemeProvider>
								</Flex>
							))}
					</Flex>
				</BlockFullWidthLayout>
			)}
		</ThemeProvider>
	)
}

export { ProgramListingBlock }
