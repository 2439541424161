import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type AlertBlockFieldsFragment = (
  { __typename?: 'alertBlock' }
  & Pick<Types.AlertBlock, 'id' | 'type' | 'backgroundColor' | 'title' | 'preamble'>
  & { link?: Types.Maybe<(
    { __typename?: 'link' }
    & Pick<Types.Link, 'alt' | 'target' | 'url' | 'title'>
  )> }
);

export const AlertBlockFieldsFragmentDoc = gql`
    fragment alertBlockFields on alertBlock {
  id
  type
  backgroundColor
  title
  preamble
  link {
    alt
    target
    url
    title
  }
}
    `;