import React from "react"
import {
	ColorThemeProvider,
	UIContextProvider,
	LocalesContextProvider,
	LocalesContextProviderProps,
	SnackbarContextProvider
} from "@operan/www-client/src/context"

export interface AppContextProviderCommonProps {
	localesContextProviderProps: LocalesContextProviderProps
}

export const AppContextProviderCommon: React.FC<AppContextProviderCommonProps> = props => {
	return (
		<LocalesContextProvider {...props.localesContextProviderProps}>
			<ColorThemeProvider>
				<UIContextProvider>
					<SnackbarContextProvider>{props.children}</SnackbarContextProvider>
				</UIContextProvider>
			</ColorThemeProvider>
		</LocalesContextProvider>
	)
}
