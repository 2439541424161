import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type PerformancePastBlockFieldsFragment = (
  { __typename?: 'performancePastBlock' }
  & Pick<Types.PerformancePastBlock, 'id' | 'type' | 'title' | 'preamble'>
  & { link?: Types.Maybe<(
    { __typename?: 'link' }
    & Pick<Types.Link, 'title' | 'url' | 'target'>
  )> }
);

export const PerformancePastBlockFieldsFragmentDoc = gql`
    fragment performancePastBlockFields on performancePastBlock {
  id
  type
  title
  preamble
  link {
    title
    url
    target
  }
}
    `;