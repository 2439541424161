import React from "react"
import { useLocation } from "react-router"
import { Menu as IMenu } from "../../@types/graphql.generated"
import {
	Menu as UIMenu,
	NavToggle,
	MegaMenu as UIMegaMenu,
	Flex,
	CustomLink,
	Submenu,
	Accordion,
	Button,
	Text,
	Icon,
	INNER_MAX_WIDTH,
	colors,
	Modal
} from "@operan/ui-components"
import { UIContext } from "../../context/UIContext"
import useSSR from "use-ssr"
import {
	trackMegaMenuClickEvent,
	trackMainMenuClickEvent,
	trackTicketLinkClickEvent,
	ButtonLocation
} from "./utils"
import { ThemeContext } from "styled-components"
import { useLocales, translationNamespace } from "../../hooks/useLocales"

import { useGetMenuQuery, GetMenuQueryResult } from "./operations.generated"
import { SearchModalContent } from "../SearchModalContent"
import { SearchButtonStyled } from "./styled"

// Main menu mobile & desktop
const mapMenuItems = (
	menu: IMenu[],
	path: string,
	toggleMegaMenu: (state: boolean) => void,
	megaMenuOpen: boolean,
	toggleNavFoldout: (state: boolean) => void,
	trackMainMenuClickEvent: (linkTitle: string) => void,
	trackTicketLinkClickEvent: (
		buttonLabel: string,
		location: string,
		buttonLocation: ButtonLocation
	) => void,
	headerLocales: Record<string, string>
) =>
	menu.map((item, idx) =>
		item.linkType === "FOLDOUT_ITEM" ? (
			<Flex
				as="li"
				display={{ _: "none", l: "flex" }}
				ml={{ l: 1.5, xl: 2 }}
				mr={{ l: 2.5, xl: 4 }}
				key={item.id}
			>
				<NavToggle
					open={megaMenuOpen}
					onClick={() => toggleMegaMenu(!megaMenuOpen)}
					aria-label={headerLocales?.openMegaMenuLabel || ""}
					locales={{
						label: item.title
					}}
					ariaControls="mega-menu"
				/>
			</Flex>
		) : item.linkType === "TICKET_PAGE" ? (
			<UIMenu.Item
				key={item.id}
				index={idx}
				active={path === item.url}
				display={{ l: "none" }}
			>
				{item?.url && (
					<UIMenu.Link
						to={item.url}
						onClick={() => {
							toggleNavFoldout(false)
							toggleMegaMenu(false)
							trackTicketLinkClickEvent(item.title, path, "Main menu")
						}}
						active={path === item.url}
					>
						{item.title}
					</UIMenu.Link>
				)}
			</UIMenu.Item>
		) : item.linkType === "SEARCH_PAGE" ? null : item.linkType ===
		  "MY_PROFILE" ? null : (
			<UIMenu.Item key={item.id} index={idx} active={path === item.url}>
				{item?.url && (
					<UIMenu.Link
						to={item.url}
						onClick={() => {
							toggleNavFoldout(false)
							toggleMegaMenu(false)
							trackMainMenuClickEvent(item.title)
						}}
						active={path === item.url}
					>
						{item.title}
					</UIMenu.Link>
				)}
			</UIMenu.Item>
		)
	)

// Mobile foldout submenu
const mapFoldoutSubmenuItems = (
	menu: IMenu[],
	path: string,
	toggleNavFoldout: (state: boolean) => void,
	locales: Record<string, string>
) =>
	menu.map(item =>
		item.children ? (
			<Accordion key={item.id}>
				<Accordion.Header
					id={`submenu-${item.url}`}
					toggleLabel={
						locales?.showMoreLinksFor
							? locales.showMoreLinksFor.replace(/%s/g, item.title)
							: ""
					}
				>
					<CustomLink
						to={item.url}
						onClick={() => toggleNavFoldout(false)}
						label={item.title}
						withChevron={true}
					/>
				</Accordion.Header>
				<Accordion.Panel id={`submenu-${item.url}`}>
					<Submenu>
						<Submenu.List>
							{item.children?.map((link, idx) => (
								<Submenu.Item active={path === link.url} key={idx}>
									<Submenu.Link
										to={link.url ? link.url : "#"}
										onClick={() => toggleNavFoldout(false)}
									>
										{link.title}
									</Submenu.Link>
								</Submenu.Item>
							))}
						</Submenu.List>
					</Submenu>
				</Accordion.Panel>
			</Accordion>
		) : (
			<Flex
				py={3}
				borderBottom
				style={{
					borderColor: "rgba(205,203,199,0.4)"
				}}
				key={item.id}
			>
				<CustomLink to={item.url} label={item.title} withChevron={true} />
			</Flex>
		)
	)

// Mega menu
const mapMegaMenuItems = (
	menu: IMenu[],
	path: string,
	toggleMegaMenu: (state: boolean) => void,
	trackMegaMenuClickEvent: (linkTitle: string, linkTarget: string) => void
) =>
	menu.map(item => (
		<Flex
			flexDirection="column"
			mb={6}
			flexBasis={{ _: `50%`, l: `33.33%` }}
			flexGrow={0}
			pr={4}
			key={item.id}
		>
			<Flex mb={2}>
				<CustomLink
					to={item.url}
					onClick={() => {
						toggleMegaMenu(false)
						trackMegaMenuClickEvent(item.title, item.url)
					}}
					label={item.title}
					withChevron={true}
				/>
			</Flex>
			<Flex flexDirection="column">
				<Submenu>
					<Submenu.List>
						{item.children?.map((link, idx) => (
							<Submenu.Item key={idx}>
								<Submenu.Link
									to={link.url ? link.url : "#"}
									onClick={() => {
										toggleMegaMenu(false)
										trackMegaMenuClickEvent(item.title, link.url)
									}}
								>
									{link.title}
								</Submenu.Link>
							</Submenu.Item>
						))}
					</Submenu.List>
				</Submenu>
			</Flex>
		</Flex>
	))

export interface MenuProps {
	menu?: GetMenuQueryResult
}

const Menu: React.FC<MenuProps> = React.memo(props => {
	const {
		navFoldoutOpen,
		toggleNavFoldout,
		megaMenuOpen,
		toggleMegaMenu
	} = React.useContext(UIContext)
	const location = useLocation()
	const { isBrowser } = useSSR()
	const headerLocales = useLocales(translationNamespace.common("Header"), [
		"openMegaMenuLabel"
	])

	React.useEffect(() => {
		if (isBrowser) {
			document.body.style.overflow = navFoldoutOpen ? "hidden" : "auto"
			document.body.style.height = navFoldoutOpen ? "100%" : "auto"
		}
	}, [navFoldoutOpen, isBrowser])

	return (
		<Flex display="block" style={{ opacity: props.menu.loading ? 0 : 1 }}>
			<UIMenu open={navFoldoutOpen}>
				<UIMenu.List>
					{props.menu.data?.main &&
						mapMenuItems(
							props.menu.data.main as IMenu[],
							location.pathname,
							toggleMegaMenu,
							megaMenuOpen,
							toggleNavFoldout,
							trackMainMenuClickEvent,
							trackTicketLinkClickEvent,
							headerLocales
						)}
				</UIMenu.List>
			</UIMenu>
		</Flex>
	)
})

const FoldoutSubMenu: React.FC<MenuProps> = React.memo(props => {
	const menu = useGetMenuQuery()
	const location = useLocation()
	const { toggleNavFoldout } = React.useContext(UIContext)
	const locales = useLocales(translationNamespace.common("Header"), [
		"showMoreLinksFor"
	])

	return (
		<>
			<Flex
				flexDirection="column"
				mt={3}
				pt={2}
				pb={25}
				px={2}
				style={{
					backgroundColor: colors["greybrown"],
					opacity: menu.loading ? 0 : 1
				}}
			>
				{props.menu.data?.foldout &&
					mapFoldoutSubmenuItems(
						props.menu.data.foldout as IMenu[],
						location.pathname,
						toggleNavFoldout,
						locales
					)}
			</Flex>
		</>
	)
})

const MegaMenu: React.FC<MenuProps> = React.memo(props => {
	const location = useLocation()
	const { megaMenuOpen, toggleMegaMenu } = React.useContext(UIContext)
	const locales = useLocales(translationNamespace.common("Header"), [
		"closeMegaMenuLabel"
	])

	return (
		<UIMegaMenu id="mega-menu" open={megaMenuOpen}>
			<Flex
				maxw={INNER_MAX_WIDTH}
				style={{ margin: "0 auto", opacity: props.menu.loading ? 0 : 1 }}
			>
				<Flex flexWrap="wrap" flexDirection="row" flex={{ _: 1, xxl: 10 / 12 }}>
					{props.menu.data?.foldout &&
						mapMegaMenuItems(
							props.menu.data.foldout as IMenu[],
							location.pathname,
							toggleMegaMenu,
							trackMegaMenuClickEvent
						)}
				</Flex>
				<Flex
					flexDirection="column"
					flex={{ _: 0, xxl: 2 / 12 }}
					display={{ _: "none", xxl: "flex" }}
				>
					{/* Placeholder for aside content */}
				</Flex>
			</Flex>
			<UIMegaMenu.Toggle
				onClick={() => toggleMegaMenu(false)}
				open={megaMenuOpen}
				closeLabel={locales.closeMegaMenuLabel}
			/>
		</UIMegaMenu>
	)
})

const CalendarButton: React.FC<MenuProps> = props => {
	const { toggleMegaMenu } = React.useContext(UIContext)
	const location = useLocation()

	return (
		<Flex display="block" style={{ opacity: props.menu.loading ? 0 : 1 }}>
			{props.menu.data?.main &&
				props.menu.data.main.map(item =>
					item.linkType === "TICKET_PAGE" ? (
						<Button.Link
							size="xlarge"
							backgroundColor="gold"
							textColor="black-light"
							to={item.url}
							label={item.title}
							onClick={() => {
								toggleMegaMenu(false)
								trackTicketLinkClickEvent(
									item.title,
									location.pathname,
									"Header"
								)
							}}
							key={item.id}
							stretch
						/>
					) : null
				)}
		</Flex>
	)
}

const SearchLink: React.FC<MenuProps> = props => {
	const ref = React.useRef() as React.MutableRefObject<HTMLButtonElement>
	const theme = React.useContext(ThemeContext)
	const { toggleMegaMenu, toggleNavFoldout } = React.useContext(UIContext)
	const [isModalOpen, setIsModalOpen] = React.useState(false)
	const MODAL_ID = "search-modal"
	const locales = useLocales(translationNamespace.common("Header"), [
		"openSearch"
	])

	return (
		<>
			{props.menu.data?.main &&
				props.menu.data.main.map((item, i) =>
					item.linkType === "SEARCH_PAGE" ? (
						<React.Fragment key={i}>
							{/* Search link desktop */}
							<Flex
								display={{ _: "none", xl: "flex" }}
								mr={2.5}
								alignItems="center"
							>
								<SearchButtonStyled
									ref={ref}
									textColor={theme.dark ? "white" : "black"}
									onClick={() => {
										setIsModalOpen(true)
										toggleMegaMenu(false)
									}}
									type="button"
									aria-label={locales.openSearch}
									aria-controls={MODAL_ID}
									aria-expanded={isModalOpen ? "true" : "false"}
								>
									<Flex>
										{item.title}
										<Icon
											icon="search"
											size={2}
											ml={1}
											mt={-0.125}
											color={theme.dark ? "white" : "black"}
										/>
									</Flex>
								</SearchButtonStyled>
							</Flex>

							{/* Search link mobile */}
							<Flex display={{ _: "flex", xl: "none" }} mr={2}>
								<SearchButtonStyled
									textColor={theme.dark ? "white" : "black"}
									onClick={() => {
										setIsModalOpen(true)
										toggleNavFoldout(false)
									}}
									type="button"
									aria-label={locales.openSearch}
								>
									<Icon
										icon="search"
										size={2.5}
										ml={{ _: 0, l: 1 }}
										mt={{ _: 0.125, l: -0.125 }}
										color={theme.dark ? "white" : "black"}
									/>
								</SearchButtonStyled>
							</Flex>

							{/* Portal modal */}
							<Modal
								modalId={MODAL_ID}
								isOpen={isModalOpen}
								handleClose={() => setIsModalOpen(false)}
								lastFocusedElement={ref.current}
							>
								<SearchModalContent handleClose={() => setIsModalOpen(false)} />
							</Modal>
						</React.Fragment>
					) : null
				)}
		</>
	)
}

const MyProfile: React.FC<MenuProps> = props => {
	const theme = React.useContext(ThemeContext)
	const { toggleMegaMenu, toggleNavFoldout } = React.useContext(UIContext)

	return (
		<>
			{props.menu.data?.main &&
				props.menu.data.main.map((item, i) =>
					item.linkType === "MY_PROFILE" ? (
						<React.Fragment key={i}>
							{/* MyProfile link desktop */}
							<Flex
								display={{ _: "none", xl: "flex" }}
								mr={2.5}
								alignItems="center"
							>
								<a aria-label={item.title} href={item.url}>
									<Text
										onClick={() => toggleMegaMenu(false)}
										fontSize={{ l: 1.75, xl: 2 }}
										fontWeight="semibold"
										lineHeight={2}
										textColor={theme.dark ? "white" : "black"}
										textTransform="uppercase"
										mb={0}
									>
										<Flex as="span" alignItems="center">
											{item.title}
											<Icon
												icon="profile"
												size={2.5}
												ml={1}
												mt={-0.25}
												color={theme.dark ? "white" : "black"}
											/>
										</Flex>
									</Text>
								</a>
							</Flex>
							{/* MyProfile link mobile */}
							<Flex display={{ _: "flex", xl: "none" }} mr={2}>
								<a
									aria-label={item.title}
									href={item.url}
									onClick={() => toggleNavFoldout(false)}
								>
									<Icon
										icon="profile"
										size={3}
										ml={1}
										mt={{ _: 0, l: -0.25 }}
										color={theme.dark ? "white" : "black"}
									/>
								</a>
							</Flex>
						</React.Fragment>
					) : null
				)}
		</>
	)
}

export { Menu, MegaMenu, FoldoutSubMenu, SearchLink, CalendarButton, MyProfile }
