import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type ShowIntroBlockFieldsFragment = (
  { __typename?: 'showIntroBlock' }
  & Pick<Types.ShowIntroBlock, 'id' | 'type' | 'preamble' | 'showMoreLabel' | 'themeColorVariant'>
  & { rte?: Types.Maybe<(
    { __typename?: 'rteBlock' }
    & Pick<Types.RteBlock, 'text'>
  )> }
);

export const ShowIntroBlockFieldsFragmentDoc = gql`
    fragment showIntroBlockFields on showIntroBlock {
  id
  type
  preamble
  rte {
    text
  }
  showMoreLabel
  themeColorVariant
}
    `;