import * as React from "react"
import {
	Flex,
	Tabs,
	useTabsActiveTab,
	scale,
	MultipleShowsTab,
	UnderlinedLink,
	INNER_CONTAINER_MAX_WIDTH
} from "@operan/ui-components"
import { Block, BlockResolver } from "../../components"
import {
	MultipleShowsBlock as IMultipleShowsBlock,
	Blocks,
	BlockType
} from "../../@types/graphql.generated"
import { mapMultipleShowsTabs, trackMultipleShowsTabClick } from "./utils"
import { qBankImageSrc } from "../../utils/assetPaths"
import { useLocales, translationNamespace } from "../../hooks/useLocales"

const MultipleShowsBlock: React.FC<IMultipleShowsBlock> = props => {
	const locales = useLocales(
		translationNamespace.block(BlockType.MultipleShowsBlock),
		["nextShow"]
	)
	const ref = React.useRef() as React.MutableRefObject<HTMLDivElement>
	const { activeTab, setActiveTab } = useTabsActiveTab(
		props.tabs?.length ? props.tabs[0].title : ""
	)
	let nextTab = null

	if (props.tabs) {
		const activeTabObj = props.tabs.find(tab => tab.title === activeTab)
		const nextTabIndex = props.tabs.indexOf(activeTabObj) + 1
		nextTab = props.tabs[nextTabIndex] || props.tabs[0]
	}

	return props.tabs && props.tabs.length > 0 ? (
		<Block themeColorVariant={props.themeColorVariant}>
			<Tabs activeTab={activeTab} controlled>
				<>
					<Flex
						w={1}
						pl={{ _: 2, m: 5, l: 10 }}
						pr={{ _: 0, m: 5, l: 10 }}
						ref={ref}
					>
						<Flex
							display="block"
							w={1}
							maxw={INNER_CONTAINER_MAX_WIDTH}
							mx="auto"
						>
							<Flex
								justifyContent="center"
								ml={{ _: -1, xl: -1.5 }} // Adjust for column gutter
								width={{
									_: `calc(100% + ${scale.px(1)})`,
									s: `calc(100% + ${scale.px(2)})`,
									xl: `calc(100% + ${scale.px(3)})`
								}}
							>
								{mapMultipleShowsTabs(props.tabs, setActiveTab)}
							</Flex>
						</Flex>
					</Flex>
					{props.tabs.map((tab, i) => (
						<Tabs.Panel
							id={tab.title}
							key={i}
							animation={"opacity-y-transform"}
						>
							<Block themeColorVariant={props.themeColorVariant}>
								<BlockResolver blocks={tab.blocks as Array<Blocks>} />
							</Block>
						</Tabs.Panel>
					))}
					{nextTab && props.tabs.length > 0 && (
						<Flex
							w={1}
							flexDirection="column"
							justifyContent="center"
							alignItems="center"
							my={{ _: 4, l: 8 }}
						>
							<MultipleShowsTab
								image={
									nextTab.image ? (
										<img
											src={qBankImageSrc(nextTab.image.src, "768")}
											alt={nextTab.image.alt || ""}
										/>
									) : null
								}
							>
								<MultipleShowsTab.Title>{nextTab.title}</MultipleShowsTab.Title>
								<MultipleShowsTab.Subtitle>
									{nextTab.subTitle}
								</MultipleShowsTab.Subtitle>
							</MultipleShowsTab>
							<UnderlinedLink.Button
								label={locales.nextShow}
								withIcon={true}
								onClick={() => {
									setActiveTab(nextTab.title)
									trackMultipleShowsTabClick(nextTab.title)

									window.scroll({
										top: ref.current.offsetTop,
										left: 0,
										behavior: "smooth"
									})
								}}
							/>
						</Flex>
					)}
				</>
			</Tabs>
		</Block>
	) : (
		<></>
	)
}

export { MultipleShowsBlock }
