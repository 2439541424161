import {
	CalendarDayUnionType,
	CalendarMonth as ICalendarMonth,
	Event as IEvent
} from "../../@types/graphql.generated"

import { SeasonCalendarContextValue } from "./context/SeasonCalendarContext"

export interface FilterValues
	extends Pick<
		SeasonCalendarContextValue,
		"activeMonth" | "activeDay" | "activeTag" | "activeShowName"
	> {}

export const getFilteredEvents = (
	events: IEvent[],
	filterValues: FilterValues
) => {
	return filterValues.activeShowName !== ""
		? events.filter(
				e => e.eventGroupTitle.toLowerCase() === filterValues.activeShowName
		  )
		: filterValues.activeTag !== ""
		? events.filter(e => e.tag.toLowerCase() === filterValues.activeTag)
		: events
}

export const getFilteredDays = (
	days: CalendarDayUnionType[],
	filterValues: FilterValues
) => {
	return days.reduce((acc, day) => {
		if (day.__typename === "calendarDay") {
			return [
				...acc,
				{
					...day,
					events: getFilteredEvents(day.events, filterValues)
				}
			]
		} else {
			return filterValues.activeShowName || filterValues.activeTag
				? [...acc]
				: [...acc, day]
		}
	}, []) as CalendarDayUnionType[]
}

export const getFilteredMonths = (
	months: ICalendarMonth[],
	filterValues: FilterValues
) => {
	return months.reduce((acc, month) => {
		const days = filterValues.activeDay ? [filterValues.activeDay] : month.days
		return [
			...acc,
			{
				...month,
				days: getFilteredDays(days, filterValues)
			}
		]
	}, []) as ICalendarMonth[]
}
