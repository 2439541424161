/* eslint-disable */
;(function() {
	var __DOMReady = function(a, b, c) {
		;(b = document), (c = "addEventListener")
		b[c] ? b[c]("DOMContentLoaded", a) : window.attachEvent("onload", a)
	}
	var clientId = undefined
	var isMobile = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
		navigator.userAgent || navigator.vendor || window.opera
	)
	var getGAID = function() {
		if (typeof ga !== "undefined") {
			if (typeof ga.getAll === "function") {
				clientId = ga.getAll()
				//console.log("Got GA", clientId);
				if (clientId && typeof clientId[0] !== "undefined")
					clientId = clientId[0].get("clientId")
				console.log("Set client ID", clientId)
			} else {
				ga(function(tracker) {
					clientId = tracker.get("clientId")
					console.log("Set client async ID", clientId)
				})
			}
		} else {
			console.log("No GA found.")
		}
	}
	__DOMReady(function() {
		getGAID()
		bindEvents()
	})

	/*__DOMReady(function(){
    	console.log("Trying to get GAID");
    	if(typeof(ga) !== 'undefined'){
    		if(typeof(ga.getAll) === 'function'){
    			clientId = ga.getAll()[0].get("clientId");
    			//bindEvents(clientId);
    		}else{
    			ga(function(tracker){
    				clientId = tracker.get("clientId");
    				//bindEvents(clientId);
    			});
    		}
    	}else{
    		console.log("NO Ga found");
    		//bindEvents();
    	}
    	bindEvents();
    });*/
	if ("addEventListener" in window) {
		window.addEventListener("load", function() {
			getGAID()
		})
	}

	function checkIfSafari() {
		var ua = navigator.userAgent.toLowerCase()
		if (ua.indexOf("safari") != -1) {
			if (ua.indexOf("chrome") > -1) {
				return false
			} else {
				return true
			}
		}
		return false
	}

	function bindEvents() {
		console.log("Binding for cid", clientId)
		document.addEventListener("click", function(e) {
			var qs = document.querySelectorAll(".waiteraid-widget")
			if (qs) {
				var el = e.target,
					index = -1
				while (el && (index = Array.prototype.indexOf.call(qs, el)) === -1) {
					el = el.parentElement
				}
				if (index > -1) {
					//cb.call(el, event);
					//console.log("Found it",el);
					e.preventDefault()
					e.stopPropagation()
					var checkTemp = el.getAttribute("data-temp-check") || false
					if (checkIfSafari() && checkTemp == "yes") {
						var wk = window.open(
							"https://app.waiteraid.com/reservation/setck",
							"wawindow",
							"toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=1,height=1"
						)
						window.setTimeout(function() {
							loadIframeWidget(clientId, el)
							wk.close()
						}, 1000)
					} else {
						loadIframeWidget(clientId, el)
					}
				}
			}
		})
	}

	function loadIframeWidget(gaTrackerClientId, el) {
		var body = document.querySelector("body")
		var html = document.documentElement
		//[].slice.call(document.querySelectorAll(".waiteraid-widget")).forEach(function(el) {
		//    el.addEventListener("click", function(e) {
		//    	e.preventDefault();
		//    	e.stopPropagation();
		var hash = el.getAttribute("data-hash") || false
		var lang = el.getAttribute("data-lang") || ""
		var mc_meals = el.getAttribute("data-mc-meals") || ""
		lang = lang === "sv" ? "sw" : lang
		var groupId = el.getAttribute("data-groupid") || ""
		var mealId = el.getAttribute("data-mealid") || ""
		var isBB = el.getAttribute("data-is-bb") || ""
		if (!hash) return false
		var iframe_url =
			location.protocol +
			"//app.waiteraid.com/reservation/?app_type=bokabord&hash=" +
			hash
		if (isBB && isBB === "yes") iframe_url += "&is_bokabord_web=Y"

		if (lang && lang.length == 2) {
			iframe_url += "&lang=" + lang
		}
		if (groupId && groupId > 0) {
			iframe_url += "&mr=" + groupId
		}
		if (mealId && mealId > 0) {
			iframe_url += "&mealid=" + mealId
		}
		if (typeof gaTrackerClientId !== "undefined") {
			iframe_url += "&gaci=" + gaTrackerClientId
		}
		if (mc_meals) {
			iframe_url += "&mc_meals=" + mc_meals
		}
		if (isMobile) {
			window.location.href = iframe_url
		} else {
			;[].slice
				.call(document.querySelectorAll(".bb_modaloverlay"))
				.forEach(function(md) {
					body.removeChild(md)
				})
			//body.style.position = "";
			html.classList.remove("with-popup")
			var wa_popupElement = document.createElement("div")
			var wa_modal = document.createElement("div")
			wa_popupElement.classList.add("bb_modaloverlay")
			wa_popupElement.addEventListener("click", function(e) {
				e.stopPropagation()
			})
			wa_modal.addEventListener("click", function(e) {
				e.stopPropagation()
			})
			wa_modal.style.minHeight = "calc(100vh - 30px)"
			wa_modal.classList.add("bb_modal")
			wa_popupElement.appendChild(wa_modal)
			var iframe = document.createElement("iframe")
			iframe.setAttribute("src", iframe_url)
			iframe.setAttribute("frameborder", "0")
			iframe.setAttribute("scrolling", "auto")
			iframe.setAttribute("width", "100%")
			iframe.setAttribute("height", "100%")
			wa_modal.appendChild(iframe)
			body.appendChild(wa_popupElement)
			html.classList.add("with-popup")
			//body.style.position = "fixed";
		}
		//  });
		//});

		window.addEventListener("message", function(event) {
			var _md = document.querySelector(".bb_modaloverlay .bb_modal")

			if (event.data === "closeWaiteraidFrame") {
				document.documentElement.classList.remove("with-popup")
				;[].slice
					.call(document.querySelectorAll(".bb_modaloverlay"))
					.forEach(function(md) {
						body.removeChild(md)
					})
				body.style.position = ""
			} else if (event.data === "scrollTop") {
				document.querySelector(".bb_modaloverlay").scrollTo(0, 0)
			} else {
				if ((contentHeight = parseInt(event.data))) {
					var wWidth = parseInt(window.innerWidth)
					if (parseInt(window.innerWidth) <= 748 || isMobile) {
						_md.style.height = ""
					} else {
						_md.style.height = contentHeight + "px"
					}
				}
			}
		})
		if (!isMobile) {
			var cssId = "modalcss"
			if (!document.getElementById(cssId)) {
				var head = document.getElementsByTagName("head")[0]
				var link = document.createElement("link")
				link.id = cssId
				link.rel = "stylesheet"
				link.type = "text/css"
				link.href =
					location.protocol + "//www.bokabord.se/static/css/modal.css?v=3"
				link.media = "all"
				head.appendChild(link)
			}
		}
	}
})()
/* eslint-enable */
