import * as React from "react"
import {
	Text,
	Flex,
	INNER_MAX_WIDTH,
	READABLE_LINE_MAX_WIDTH,
	Rte
} from "@operan/ui-components"
import { qBankImageSrc } from "../../utils"
import {
	ProfilePageHeroBlock as IProfilePageHeroBlock,
	BlockType
} from "../../@types/graphql.generated"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { profileFallbackImageSrc } from "../../utils/fallbackImage"

function createMarkup(markup: string) {
	return { __html: markup }
}

const ProfilePageHeroBlock: React.FC<IProfilePageHeroBlock> = props => {
	const locales = useLocales(
		translationNamespace.block(BlockType.ProfilePageHeroBlock),
		["phoneLabel", "emailLabel"]
	)

	return (
		<Flex w={1}>
			<Flex
				mt={{ _: 7, l: 8 }}
				maxw={INNER_MAX_WIDTH}
				w={1}
				mx="auto"
				pl={{ _: 2, m: 8, xxl: 22 }}
				pr={{ _: 2, m: 8, xxl: 22 }}
			>
				<Flex display="block" w={1} maxw={READABLE_LINE_MAX_WIDTH} mb={2}>
					<Flex flexDirection="column" pr={{ _: 0, l: 39, xxl: 30, xxxl: 22 }}>
						<Text as="h1" variant="headline-1">
							{props.name}
						</Text>
						{props.title && (
							<Text as="h2" variant="preamble">
								{props.title}
							</Text>
						)}
						<Flex display="block" mt={{ m: 2 }} mb={{ _: 3, m: 5 }}>
							<img
								alt={props.image?.alt || ""}
								src={
									props.image
										? qBankImageSrc(props.image.src, "768")
										: profileFallbackImageSrc
								}
								style={{ width: "100%" }}
							/>
						</Flex>
						<Flex w={1} flexDirection="column" mb={{ _: 3, m: 5 }}>
							{props.phone && (
								<Text
									fontSize={{ _: 2.5, l: 3 }}
									lineHeight={{ _: 3.5, l: 4 }}
									fontWeight="semibold"
									mb={0}
								>
									{locales.phoneLabel}: {props.phone}
								</Text>
							)}
							{props.email && (
								<Text
									fontSize={{ _: 2.5, l: 3 }}
									lineHeight={{ _: 3.5, l: 4 }}
									fontWeight="semibold"
									mb={0}
								>
									{locales.emailLabel}: {props.email}
								</Text>
							)}
						</Flex>
						<Flex w={1} flexDirection="column">
							{props.preamble && <Text>{props.preamble}</Text>}
						</Flex>
						<Flex w={1} flexDirection="column">
							<Rte>
								{props.text && (
									<div dangerouslySetInnerHTML={createMarkup(props.text)} />
								)}
							</Rte>
						</Flex>
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	)
}

export { ProfilePageHeroBlock }
