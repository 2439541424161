import * as React from "react"
import { Link } from "react-router-dom"
import { Flex, Text, Button, fontFamilyHeadings } from "@operan/ui-components"
import {
	Event as IEvent,
	CtaGraphType
} from "../../../@types/graphql.generated"
import { trackTicketLinkClickEvent } from "../../Menu/utils"
import { useLocation } from "react-router"

const EventCtaButton: React.FC<CtaGraphType> = props => {
	const defaultProps = {
		label: props.label,
		rounded: true,
		stretch: true
	}
	const location = useLocation()

	return !props.url ? (
		<Button
			backgroundColor="gold"
			textColor="black"
			onClick={null}
			disabled={props.disabled}
			{...defaultProps}
		/>
	) : props.url.indexOf("http") > -1 ? (
		<Button.Anchor
			backgroundColor="gold"
			textColor="black"
			href={props.url}
			target={`_BLANK`}
			rel="noopener noreferrer"
			onClick={() =>
				trackTicketLinkClickEvent(props.url, location.pathname, "Programme")
			}
			disabled={props.disabled}
			{...defaultProps}
		/>
	) : (
		<Button.Link
			backgroundColor="gold"
			textColor="black"
			to={props.url}
			onClick={() =>
				trackTicketLinkClickEvent(props.url, location.pathname, "Programme")
			}
			disabled={props.disabled}
			{...defaultProps}
		/>
	)
}

const Event: React.FC<IEvent> = props => {
	const {
		title,
		scene,
		additionalInfo,
		cta,
		ticketInformation,
		date,
		url
	} = props

	return (
		<>
			<Flex
				display={{ _: "none", s: "flex" }}
				alignItems="flex-end"
				mr={{ _: 5, l: 9, xl: 16 }}
			>
				<Text fontSize={1.75} lineHeight={2.5} fontWeight="semibold" mb={0}>
					{date.time}
				</Text>
			</Flex>
			<Flex
				flexDirection="column"
				justifyContent={{ _: "space-between", s: "initial" }}
				w={1}
			>
				<Text
					fontSize={1.75}
					lineHeight={2.5}
					fontWeight="semibold"
					mb={0}
					display={{ s: "none" }}
				>
					{date.time}
				</Text>

				<Text
					as="h4"
					fontSize={{ _: 2, m: 2.5, l: 3 }}
					lineHeight={{ _: 2.5, m: 3, l: 3.5 }}
					fontFamily={fontFamilyHeadings}
					mb={{ _: 0.5, s: 1 }}
				>
					{url ? (
						<Link
							to={url}
							style={{ color: "inherit", textDecoration: "underline" }}
						>
							{title}
						</Link>
					) : (
						<>{title}</>
					)}
				</Text>

				<Flex
					w={1}
					flexDirection={{ _: "column", s: "row" }}
					alignItems={{ s: "flex-end" }}
				>
					<Text fontSize={1.75} lineHeight={2.5} mb={0}>
						{scene}, {props.location}
						{additionalInfo && (
							<Text
								as="span"
								fontSize={1.75}
								lineHeight={2.5}
								fontWeight="semibold"
								fontStyle="italic"
								mb={0}
								mx={1}
							>
								{additionalInfo}
							</Text>
						)}
					</Text>
					<Flex display={{ xs: "none" }} mt={1.5} alignItems="flex-end">
						<EventCtaButton {...cta} />

						{!cta.url && cta.label && (
							<Text
								fontSize={1.75}
								lineHeight={2.5}
								fontWeight="semibold"
								fontStyle="italic"
								ml={1}
								mb={0}
							>
								{cta.label}
							</Text>
						)}
					</Flex>
				</Flex>
			</Flex>
			<Flex
				display={{ _: "none", xs: "flex" }}
				flex="0 0 auto"
				ml={{ _: 0, s: "auto" }}
				mt={{ _: 1, s: 0 }}
				alignItems="flex-end"
				maxw={26}
			>
				{ticketInformation && (
					<Text
						fontSize={{ xs: 1.75, l: 2.25 }}
						lineHeight={{ xs: 2.5, l: 3 }}
						fontWeight="semibold"
						fontStyle="italic"
						textAlign="right"
						mr={1.5}
						mb={0}
					>
						{ticketInformation}
					</Text>
				)}
				<EventCtaButton {...cta} />
			</Flex>
		</>
	)
}

export { Event }
