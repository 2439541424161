import { InMemoryCache } from "@apollo/client"
const introspectionResult = require("./fragmentTypes.json")
import env from "@beam-australia/react-env"

const apolloClient = {
	uri: env("GRAPHQL_ENDPOINT"),
	cache: () =>
		new InMemoryCache({ possibleTypes: introspectionResult.possibleTypes }),
	timeout: 10000 // 10 seconds
}

export const config = {
	apolloClient
}
