import * as React from "react"

import {
	Rte,
	READABLE_LINE_MAX_WIDTH,
	Flex,
	Tabs,
	useTabsActiveTab,
	INNER_MAX_WIDTH
} from "@operan/ui-components"
import { Block } from "../Block/Block"
import {
	ParticipantsBlock as IParticipantsBlock,
	BlockType
} from "../../@types/graphql.generated"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { Participant, mapParticipantListsToTabs } from "./utils"

function createMarkup(markup: string) {
	return { __html: markup }
}

const ParticipantsBlock: React.FC<IParticipantsBlock> = props => {
	const locales = useLocales(
		translationNamespace.block(BlockType.ParticipantsBlock),
		["crewLabel", "castLabel", "readMoreCastAria", "readMoreCrewAria"]
	)

	const tabs = React.useRef(mapParticipantListsToTabs(props, locales))

	const { activeTab, setActiveTab } = useTabsActiveTab(
		tabs?.current ? tabs.current[0].id : ""
	)

	return (
		<Block themeColorVariant={props.themeColorVariant} theme={props.theme}>
			<Flex py={{ _: 8, l: 8 }} px={{ _: 2, m: 5, l: 10 }}>
				<Flex
					alignItems="center"
					flexDirection="column"
					w={1}
					maxw={INNER_MAX_WIDTH}
					style={{ margin: "0 auto" }}
				>
					<Tabs activeTab={activeTab} controlled>
						<>
							<Flex justifyContent="center">
								{tabs.current.map(tab => (
									<Flex mr={2} key={tab.id}>
										<Tabs.Tab
											onClick={() => {
												setActiveTab(tab.id)
											}}
											id={tab.id}
											px={2}
											pb={3}
											variant="ghost"
										>
											<Tabs.Label>{tab.label}</Tabs.Label>
										</Tabs.Tab>
									</Flex>
								))}
							</Flex>
							{tabs.current.map(tab => (
								<Tabs.Panel
									id={tab.id}
									key={tab.id}
									animation={"opacity-y-transform"}
								>
									<Flex display="block" py={{ _: 6, l: 8 }}>
										<>
											{tab.participants?.length > 0 && (
												<Flex
													flexWrap="wrap"
													justifyContent="center"
													ml={{ s: -1, l: -1.5 }}
													width={{
														_: 1,
														s: "calc(100% + 16px)",
														l: "calc(100% + 24px)"
													}}
												>
													{tab.participants.map((p, i) =>
														p.name ? (
															<Flex
																key={i}
																flex={{
																	_: "0 0 50%",
																	s: "0 0 25%",
																	l: "0 0 12.5%",
																	xxl: "0 0 8.33%"
																}}
																flexDirection="column"
																px={{ _: 1, l: 1.5 }}
																pb={{ _: 1, s: 3 }}
															>
																<Participant {...p} />
															</Flex>
														) : null
													)}
												</Flex>
											)}
											{tab.rte && (
												<Flex
													mt={
														tab.participants?.length > 0
															? { _: 8, m: 16, l: 16 }
															: 0
													}
													maxw={READABLE_LINE_MAX_WIDTH}
													mx="auto"
												>
													<Rte>
														<div
															dangerouslySetInnerHTML={createMarkup(tab.rte)}
														/>
													</Rte>
												</Flex>
											)}
										</>
									</Flex>
								</Tabs.Panel>
							))}
						</>
					</Tabs>
				</Flex>
			</Flex>
		</Block>
	)
}

export { ParticipantsBlock }
