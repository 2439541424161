"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var theme_1 = require("../theme");
/**
 * Returns a merged theme with a given ColorTheme
 */
exports.mergeColorTheme = function (theme) {
    // filter out null values
    var filteredTheme = Object.keys(theme).reduce(function (acc, key) {
        var _a;
        return (__assign(__assign({}, acc), (theme[key] !== null && (_a = {},
            _a[key] = theme[key],
            _a))));
    }, {});
    return theme.dark
        ? __assign(__assign({}, theme_1.darkTheme), filteredTheme) : __assign(__assign({}, theme_1.lightTheme), filteredTheme);
};
/**
 * Returns the ColorTheme of a given theme
 */
exports.extractColorTheme = function (theme) {
    return Object.keys(theme_1.lightTheme).reduce(function (acc, key) {
        var _a;
        return __assign(__assign({}, acc), (typeof theme[key] !== "undefined" ? (_a = {}, _a[key] = theme[key], _a) : {}));
    }, {});
};
/**
 * Checks if a theme is using the default color theme
 */
exports.isDefaultTheme = function (theme) {
    return JSON.stringify(exports.extractColorTheme(theme)) === JSON.stringify(theme_1.lightTheme);
};
