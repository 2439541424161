import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type ProfileListingBlockFieldsFragment = (
  { __typename?: 'profileListingBlock' }
  & Pick<Types.ProfileListingBlock, 'id' | 'type' | 'themeColorVariant' | 'title'>
  & { theme?: Types.Maybe<(
    { __typename?: 'theme' }
    & Pick<Types.Theme, 'primaryColor' | 'complementaryColor' | 'dark'>
  )>, profiles?: Types.Maybe<Array<(
    { __typename?: 'profilePageHeroBlock' }
    & Pick<Types.ProfilePageHeroBlock, 'email' | 'name' | 'phone' | 'preamble' | 'title'>
    & { image?: Types.Maybe<(
      { __typename?: 'image' }
      & Pick<Types.Image, 'id' | 'src' | 'alt' | 'placeholder'>
    )>, url?: Types.Maybe<(
      { __typename?: 'link' }
      & Pick<Types.Link, 'url' | 'target' | 'title'>
    )> }
  )>> }
);

export const ProfileListingBlockFieldsFragmentDoc = gql`
    fragment profileListingBlockFields on profileListingBlock {
  id
  type
  theme {
    primaryColor
    complementaryColor
    dark
  }
  themeColorVariant
  title
  profiles: items {
    email
    image {
      id
      src
      alt
      placeholder
    }
    name
    phone
    preamble
    title
    url {
      url
      target
      title
    }
  }
}
    `;