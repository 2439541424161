"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.colors = {
    black: "#0f0f0f",
    "black-light": "#222222",
    white: "#FFFFFF",
    "white-faded": "#FFFFFFA6",
    "off-white": "#FFFEFC",
    blue: "#215160",
    "blue-dark": "#072933",
    "blue-light": "#E1F2F0",
    "blue-lighter": "#F6FBFA",
    "brown-normal": "#EDECE9",
    brown: "#735B34",
    "brown-light": "#F8F7F6",
    gold: "#D8BF72",
    "gold-light": "#FFF0BB",
    grey: "#646768",
    "grey-light": "#EFEFEF",
    "grey-dark": "#494949",
    greybrown: "#ECEBE5",
    "greybrown-dark": "#6B6761",
    "greybrown-medium": "#CDCBC7",
    "greybrown-light": "#FFFEFC",
    "pink-light": "#FAE3DE",
    red: "#FF7373",
    "red-dark": "#F13D3D",
    green: "#4AA253",
    "green-light": "#DDF2DA",
    transparent: "transparent"
};
exports.colorKeys = Object.keys(exports.colors);
