"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var styled_components_1 = __importStar(require("styled-components"));
var styles_1 = require("../../styles");
var styled_system_1 = require("../../styles/styled-system");
var styled_system_2 = require("styled-system");
var components_1 = require("../../components");
var hoverStyles = styled_components_1.css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t&:hover:not([disabled]) {\n\t\tpadding: ", ";\n\t}\n"], ["\n\t&:hover:not([disabled]) {\n\t\tpadding: ",
    ";\n\t}\n"])), function (props) {
    return props.size === "xlarge"
        ? styles_1.scale.px(3) + " " + styles_1.scale.px(6)
        : props.size === "large"
            ? styles_1.scale.px(1.5) + " " + styles_1.scale.px(3)
            : styles_1.scale.px(1.5) + " " + styles_1.scale.px(2);
});
exports.ButtonStyles = styled_components_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tposition: relative;\n\tdisplay: inline-block;\n\ttext-decoration: none;\n\twidth: auto;\n\tvertical-align: middle;\n\ttransition: ", ";\n\tborder-radius: ", ";\n\ttext-align: ", ";\n\t", "\n\t", "\n\t", "\n\n\t&:disabled {\n\t\tbackground-color: #e5e5e4;\n\t\tcursor: default;\n\n\t\tspan {\n\t\t\tcolor: ", ";\n\t\t}\n\n\t\tpath {\n\t\t\tfill: ", ";\n\t\t}\n\t}\n\n\t", "\n\n\t", "\n\n\tsvg {\n\t\tdisplay: block;\n\t\tposition: absolute;\n\t\ttop: 50%;\n\t\tright: ", ";\n\t\tleft: ", ";\n\t\ttransform: ", ";\n\t\tpadding-bottom: 2px; // Linear to font\n\n\t\tpath, polygon {\n\t\t\tfill: ", ";\n\t\t}\n\n\t\t", "\n\t}\n"], ["\n\tposition: relative;\n\tdisplay: inline-block;\n\ttext-decoration: none;\n\twidth: auto;\n\tvertical-align: middle;\n\ttransition: ",
    ";\n\tborder-radius: ", ";\n\ttext-align: ",
    ";\n\t", "\n\t", "\n\t", "\n\n\t&:disabled {\n\t\tbackground-color: #e5e5e4;\n\t\tcursor: default;\n\n\t\tspan {\n\t\t\tcolor: ", ";\n\t\t}\n\n\t\tpath {\n\t\t\tfill: ", ";\n\t\t}\n\t}\n\n\t",
    "\n\n\t",
    "\n\n\tsvg {\n\t\tdisplay: block;\n\t\tposition: absolute;\n\t\ttop: 50%;\n\t\tright: ", ";\n\t\tleft: ",
    ";\n\t\ttransform: ",
    ";\n\t\tpadding-bottom: 2px; // Linear to font\n\n\t\tpath, polygon {\n\t\t\tfill: ", ";\n\t\t}\n\n\t\t",
    "\n\t}\n"])), function (props) {
    return props.stretch
        ? "padding 0.5s cubic-bezier(0.789, 0.301, 0, 0.956)"
        : "none";
}, function (props) { return (props.rounded ? styles_1.scale.px(0.5) : "none"); }, function (props) {
    return props.icon && props.iconPosition === "right"
        ? "left"
        : props.icon && props.iconPosition === "left"
            ? "right"
            : "center";
}, styled_system_1.color, styled_system_1.border, styled_system_2.display, styles_1.colors["greybrown-dark"], styles_1.colors["greybrown-dark"], styled_system_2.variant({
    prop: "size",
    variants: {
        small: {
            padding: styles_1.scale.px(1) + " " + styles_1.scale.px(2)
        },
        large: {
            padding: [
                "" + styles_1.scale.px(1),
                "" + styles_1.scale.px(1),
                "" + styles_1.scale.px(1),
                "" + styles_1.scale.px(1),
                "" + styles_1.scale.px(1),
                styles_1.scale.px(1.5) + " " + styles_1.scale.px(2) // l:
            ]
        },
        xlarge: {
            padding: [
                "" + styles_1.scale.px(0.75),
                "" + styles_1.scale.px(0.75),
                "" + styles_1.scale.px(0.75),
                "" + styles_1.scale.px(0.75),
                "" + styles_1.scale.px(0.75),
                styles_1.scale.px(3) + " " + styles_1.scale.px(4) // l:
            ],
            minHeight: ["auto", "auto", "auto", "auto", "auto", "" + styles_1.scale.px(9)]
        },
        icon: {
            padding: styles_1.scale.px(2) + " " + styles_1.scale.px(2.25)
        }
    }
}), styles_1.breakpoint("l")(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t\tfont-size: ", ";\n\t\tline-height:", ";\n\n\t\t", "\n\t"], ["\n\t\tfont-size: ", ";\n\t\tline-height:", ";\n\n\t\t", "\n\t"])), function (props) { return (props.size === "small" ? styles_1.scale.px(1.75) : styles_1.scale.px(2)); }, function (props) { return (props.size === "small" ? styles_1.scale.px(2.5) : styles_1.scale.px(3)); }, function (props) { return props.stretch && hoverStyles; }), function (props) { return (props.iconPosition === "right" ? styles_1.scale.px(1) : "auto"); }, function (props) {
    return props.iconPosition === "left"
        ? styles_1.scale.px(1)
        : props.iconPosition === "center"
            ? "50%"
            : "auto";
}, function (props) {
    return props.iconPosition === "center"
        ? "translateY(-50%) translateX(-50%)"
        : "translateY(-50%)";
}, function (props) { return props.textColor || "red"; }, styles_1.breakpoint("l")(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t\t\tright: ", ";\n\t\t\tleft: ", ";\n\t\t"], ["\n\t\t\tright: ", ";\n\t\t\tleft: ",
    ";\n\t\t"])), function (props) { return (props.iconPosition === "right" ? styles_1.scale.px(2) : "auto"); }, function (props) {
    return props.iconPosition === "left"
        ? styles_1.scale.px(2)
        : props.iconPosition === "center"
            ? "50%"
            : "auto";
}));
var ButtonLabel = styled_components_1.default.span(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tdisplay: inline-block;\n\tcolor: ", ";\n\tmargin-right: ", ";\n\tmargin-left: ", ";\n\tfont-size: ", ";\n\tline-height: ", ";\n\tfont-weight: ", ";\n\tletter-spacing: 1px;\n\ttext-transform: ", ";\n"], ["\n\tdisplay: inline-block;\n\tcolor: ", ";\n\tmargin-right: ",
    ";\n\tmargin-left: ",
    ";\n\tfont-size: ",
    ";\n\tline-height: ",
    ";\n\tfont-weight: ", ";\n\tletter-spacing: 1px;\n\ttext-transform: ", ";\n"])), function (props) { return props.textColor || "black"; }, function (props) {
    return props.icon && props.iconPosition === "right" ? styles_1.scale.px(4) : 0;
}, function (props) {
    return props.icon && props.iconPosition === "left" ? styles_1.scale.px(4) : 0;
}, function (props) {
    return props.size === "small" ? styles_1.scale.px(1.75) : styles_1.scale.px(2);
}, function (props) {
    return props.size === "small" ? styles_1.scale.px(2.5) : styles_1.scale.px(3);
}, styles_1.fontWeight.semibold, function (props) { return props.textTransform; });
var defaultProps = {
    rounded: false,
    size: "large",
    stretch: false,
    disabled: false,
    icon: null,
    iconPosition: "right",
    textTransform: "uppercase"
};
var Content = function (props) { return (React.createElement(React.Fragment, null,
    props.icon && props.iconPosition !== "right" && (React.createElement(components_1.Icon, { icon: props.icon, size: 2 })),
    props.label && props.iconPosition !== "center" && (React.createElement(ButtonLabel, __assign({}, props), props.label)),
    props.icon && props.iconPosition === "right" && (React.createElement(components_1.Icon, { icon: props.icon, size: 2 })))); };
exports.ButtonStyled = styled_components_1.default.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), exports.ButtonStyles);
exports.Button = function (props) { return (React.createElement(exports.ButtonStyled, __assign({}, props),
    React.createElement(Content, __assign({}, props)))); };
exports.Button.defaultProps = defaultProps;
/**
 * Link element
 */
var LinkStyled = styled_components_1.default(react_router_dom_1.Link).withConfig({
    shouldForwardProp: function (prop, defaultValidatorFn) {
        return !["rounded", "stretch", "size", "icon"].includes(prop) &&
            defaultValidatorFn(prop);
    }
})(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), exports.ButtonStyles);
exports.Button.Link = function (props) { return (React.createElement(LinkStyled, __assign({}, props),
    React.createElement(Content, __assign({}, props)))); };
exports.Button.Link.defaultProps = defaultProps;
/**
 * Anchor element
 */
var AnchorStyled = styled_components_1.default.a.withConfig({
    shouldForwardProp: function (prop, defaultValidatorFn) {
        return !["rounded", "stretch", "size", "icon"].includes(prop) &&
            defaultValidatorFn(prop);
    }
})(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), exports.ButtonStyles);
exports.Button.Anchor = function (props) { return (React.createElement(AnchorStyled, __assign({ as: "a" }, props),
    React.createElement(Content, __assign({}, props)))); };
exports.Button.Anchor.defaultProps = defaultProps;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
