"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_1 = __importStar(require("styled-components"));
var styles_1 = require("../../styles");
var styles_2 = require("../../styles");
var animations_1 = require("./animations");
var polished_1 = require("polished");
exports.SlideshowStyled = styled_components_1.default.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tposition: relative;\n\twidth: 100%;\n"], ["\n\tposition: relative;\n\twidth: 100%;\n"])));
exports.SlideshowImageWrapper = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\toverflow: hidden;\n"], ["\n\toverflow: hidden;\n"])));
exports.SlideshowContent = styled_components_1.default.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tposition: relative;\n\theight: 100%;\n\n\t", ",\n\t", " > img {\n\t\tposition: absolute;\n\t\ttop: 0;\n\t\tleft: 0;\n\t\twidth: 100%;\n\t\theight: 100%;\n\t}\n\n\t", " > img {\n\t\tobject-fit: cover;\n\t\tobject-position: center;\n\t\twill-change: transform;\n\t}\n\n\t&:after {\n\t\tcontent: \"\";\n\t\tdisplay: block;\n\t\twidth: 100%;\n\t\tpadding-bottom: 66.6%;\n\n\t\t", "\n\t}\n"], ["\n\tposition: relative;\n\theight: 100%;\n\n\t", ",\n\t", " > img {\n\t\tposition: absolute;\n\t\ttop: 0;\n\t\tleft: 0;\n\t\twidth: 100%;\n\t\theight: 100%;\n\t}\n\n\t", " > img {\n\t\tobject-fit: cover;\n\t\tobject-position: center;\n\t\twill-change: transform;\n\t}\n\n\t&:after {\n\t\tcontent: \"\";\n\t\tdisplay: block;\n\t\twidth: 100%;\n\t\tpadding-bottom: 66.6%;\n\n\t\t",
    "\n\t}\n"])), exports.SlideshowImageWrapper, exports.SlideshowImageWrapper, exports.SlideshowImageWrapper, styles_2.breakpoint("m")(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t\t\tpadding-bottom: 56.25%;\n\t\t"], ["\n\t\t\tpadding-bottom: 56.25%;\n\t\t"]))));
exports.FooterStyled = styled_components_1.default.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\tposition: relative;\n\tdisplay: flex;\n\tflex-direction: column-reverse;\n\talign-items: center;\n\twidth: 100%;\n\tpadding: 0 ", ";\n\n\t", "\n"], ["\n\tposition: relative;\n\tdisplay: flex;\n\tflex-direction: column-reverse;\n\talign-items: center;\n\twidth: 100%;\n\tpadding: 0 ", ";\n\n\t",
    "\n"])), styles_1.scale.px(2), styles_2.breakpoint("l")(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\t\tflex-direction: row;\n\t\talign-items: flex-start;\n\t\tpadding: ", " 0;\n\t"], ["\n\t\tflex-direction: row;\n\t\talign-items: flex-start;\n\t\tpadding: ", " 0;\n\t"])), styles_1.scale.px(2)));
exports.CaptionStyled = styled_components_1.default.p(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n\tfont-size: ", ";\n\tline-height: ", ";\n\ttext-align: center;\n\n\t& > span {\n\t\tfont-size: ", ";\n\t\tline-height: ", ";\n\t\tmargin-bottom: 0;\n\t}\n\n\t", "\n\n\t", "\n"], ["\n\tfont-size: ", ";\n\tline-height: ", ";\n\ttext-align: center;\n\n\t& > span {\n\t\tfont-size: ", ";\n\t\tline-height: ", ";\n\t\tmargin-bottom: 0;\n\t}\n\n\t",
    "\n\n\t",
    "\n"])), styles_1.scale.px(1.75), styles_1.scale.px(2.25), styles_1.scale.px(1.75), styles_1.scale.px(2.25), styles_2.breakpoint("l")(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\t\tfont-size: ", ";\n\t\tline-height: ", ";\n\t\tmargin-right: ", ";\n\t\ttext-align: left;\n\t\n\t\t& > span {\n\t\t\tfont-size: ", ";\n\t\t\tline-height: ", ";\n\t\t}\n\t"], ["\n\t\tfont-size: ", ";\n\t\tline-height: ", ";\n\t\tmargin-right: ", ";\n\t\ttext-align: left;\n\t\n\t\t& > span {\n\t\t\tfont-size: ", ";\n\t\t\tline-height: ", ";\n\t\t}\n\t"])), styles_1.scale.px(2), styles_1.scale.px(3), styles_1.scale.px(4), styles_1.scale.px(2), styles_1.scale.px(3)), styles_2.breakpoint("xl")(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n\t\tmax-width: 85%;\n\t\tmargin-right: ", ";\n\t"], ["\n\t\tmax-width: 85%;\n\t\tmargin-right: ", ";\n\t"])), styles_1.scale.px(10)));
exports.Indicator = styled_components_1.default.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n\tdisplay: flex;\n\talign-items: center;\n\tpadding: ", " 0;\n\n\t", "\n"], ["\n\tdisplay: flex;\n\talign-items: center;\n\tpadding: ", " 0;\n\n\t",
    "\n"])), styles_1.scale.px(2.5), styles_2.breakpoint("l")(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n\t\tpadding-top: ", ";\n\t\tpadding-bottom: 0;\n\t\tmargin-left: auto;\n\t"], ["\n\t\tpadding-top: ", ";\n\t\tpadding-bottom: 0;\n\t\tmargin-left: auto;\n\t"])), styles_1.scale.px(1)));
exports.IndicatorItem = styled_components_1.default.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n\twidth: ", ";\n\theight: 4px;\n\tbackground-color: ", ";\n\tmargin-right: ", ";\n\n\t&:last-of-type {\n\t\tmargin-right: 0;\n\t}\n\n\t&.active {\n\t\tbackground-color: ", ";\n\t}\n"], ["\n\twidth: ", ";\n\theight: 4px;\n\tbackground-color: ", ";\n\tmargin-right: ", ";\n\n\t&:last-of-type {\n\t\tmargin-right: 0;\n\t}\n\n\t&.active {\n\t\tbackground-color: ", ";\n\t}\n"])), styles_1.scale.px(1.25), function (_a) {
    var theme = _a.theme;
    return polished_1.rgba(theme.textColor, 0.4);
}, styles_1.scale.px(1), function (_a) {
    var theme = _a.theme;
    return theme.textColor;
});
exports.btnStyles = styled_components_1.css(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n\tposition: absolute;\n\ttop: 50%;\n\ttransform: translateY(-50%);\n\talign-items: center;\n\tjustify-content: center;\n\twidth: ", ";\n\theight: ", ";\n\tz-index: 1;\n\toverflow: hidden;\n\n\tdisplay: none;\n\n\t", "\n\n\tsvg,\n\tpath,\n\tpolygon {\n\t\toutline: none;\n\t\tfill: ", " !important;\n\t}\n"], ["\n\tposition: absolute;\n\ttop: 50%;\n\ttransform: translateY(-50%);\n\talign-items: center;\n\tjustify-content: center;\n\twidth: ", ";\n\theight: ", ";\n\tz-index: 1;\n\toverflow: hidden;\n\n\tdisplay: none;\n\n\t",
    "\n\n\tsvg,\n\tpath,\n\tpolygon {\n\t\toutline: none;\n\t\tfill: ",
    " !important;\n\t}\n"])), styles_1.scale.px(6), styles_1.scale.px(6), styles_2.breakpoint("l")(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n\t\tdisplay: flex;\n\t"], ["\n\t\tdisplay: flex;\n\t"]))), function (_a) {
    var theme = _a.theme;
    return theme.dark ? styles_1.colors["white"] : styles_1.colors["black"];
});
exports.PrevBtnStyled = styled_components_1.default.button.attrs(function (props) { return ({
    "aria-label": props.previousSlide
}); })(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n\t", "\n\tleft: 0;\n\ttransform: translateY(-50%);\n\ttransform-origin: center left;\n\tmargin-left: ", ";\n\tbackground: ", ";\n\n\t&.animate {\n\t\t", ";\n\n\t\tsvg {\n\t\t\t", ";\n\t\t}\n\t}\n"], ["\n\t", "\n\tleft: 0;\n\ttransform: translateY(-50%);\n\ttransform-origin: center left;\n\tmargin-left: ", ";\n\tbackground: ",
    ";\n\n\t&.animate {\n\t\t", ";\n\n\t\tsvg {\n\t\t\t", ";\n\t\t}\n\t}\n"])), exports.btnStyles, styles_1.scale.px(-3), function (_a) {
    var theme = _a.theme, rest = __rest(_a, ["theme"]);
    return rest.themeColorVariant === "PRIMARY"
        ? theme.complementaryColor
        : theme.primaryColor;
}, animations_1.scalePrevBtn, animations_1.movePrevBtnArrow);
exports.NextBtnStyled = styled_components_1.default.button.attrs(function (props) { return ({
    "aria-label": props.nextSlide
}); })(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n\t", "\n\tright: 0;\n\ttransform: translateY(-50%);\n\ttransform-origin: center right;\n\tmargin-right: ", ";\n\tbackground: ", ";\n\n\t&.animate {\n\t\t", ";\n\n\t\tsvg {\n\t\t\t", ";\n\t\t}\n\t}\n"], ["\n\t", "\n\tright: 0;\n\ttransform: translateY(-50%);\n\ttransform-origin: center right;\n\tmargin-right: ", ";\n\tbackground: ",
    ";\n\n\t&.animate {\n\t\t", ";\n\n\t\tsvg {\n\t\t\t", ";\n\t\t}\n\t}\n"])), exports.btnStyles, styles_1.scale.px(-3), function (_a) {
    var theme = _a.theme, rest = __rest(_a, ["theme"]);
    return rest.themeColorVariant === "PRIMARY"
        ? theme.complementaryColor
        : theme.primaryColor;
}, animations_1.scaleNextBtn, animations_1.moveNextBtnArrow);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
