"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_1 = require("styled-components");
var constants_1 = require("../../constants");
var styles_1 = require("../../styles");
exports.moveUp = styled_components_1.keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t0% {\n\t\topacity: 0;\n\t\ttransform: translateY(", ");\n\t}\n\t100% {\n\t\topacity: 1;\n\t\ttransform: translateY(0);\n\t}\n"], ["\n\t0% {\n\t\topacity: 0;\n\t\ttransform: translateY(", ");\n\t}\n\t100% {\n\t\topacity: 1;\n\t\ttransform: translateY(0);\n\t}\n"])), styles_1.scale.px(4));
exports.moveLeft = styled_components_1.keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\t0% {\n\t\topacity: 0;\n\t\ttransform: translateX(", ");\n\t}\n\t100% {\n\t\topacity: 1;\n\t\ttransform: translateX(0);\n\t}\n"], ["\n\t0% {\n\t\topacity: 0;\n\t\ttransform: translateX(", ");\n\t}\n\t100% {\n\t\topacity: 1;\n\t\ttransform: translateX(0);\n\t}\n"])), styles_1.scale.px(-4));
exports.zoomIn = styled_components_1.keyframes(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    0% {\n        transform: scale(1.1);\n    }\n\n    100% {\n        transform: scale(1);\n    }\n"], ["\n    0% {\n        transform: scale(1.1);\n    }\n\n    100% {\n        transform: scale(1);\n    }\n"])));
exports.fadeIn = styled_components_1.keyframes(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    0% {\n        opacity: 0;\n    }\n    100% {\n        opacity: 1;\n    }\n"], ["\n    0% {\n        opacity: 0;\n    }\n    100% {\n        opacity: 1;\n    }\n"])));
exports.zoomInImage = styled_components_1.css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-delay: 0.3s;\n\tanimation-fill-mode: both;\n"], ["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-delay: 0.3s;\n\tanimation-fill-mode: both;\n"])), exports.zoomIn, constants_1.TRANSITION_CURVE);
exports.fadeInContainer = styled_components_1.css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-delay: 0.3s;\n\tanimation-fill-mode: both;\n"], ["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-delay: 0.3s;\n\tanimation-fill-mode: both;\n"])), exports.fadeIn, constants_1.TRANSITION_CURVE);
exports.fadeInText = styled_components_1.css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 0.5s;\n\tanimation-fill-mode: both;\n"], ["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 0.5s;\n\tanimation-fill-mode: both;\n"])), exports.fadeIn, constants_1.TRANSITION_CURVE);
exports.revealContainerLarge = styled_components_1.css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n\tanimation-name: ", ";\n\tanimation-timing-function: cubic-bezier(0.789, 0.301, 0, 0.956);\n\tanimation-duration: 1.8s;\n\tanimation-fill-mode: both;\n"], ["\n\tanimation-name: ", ";\n\tanimation-timing-function: cubic-bezier(0.789, 0.301, 0, 0.956);\n\tanimation-duration: 1.8s;\n\tanimation-fill-mode: both;\n"])), exports.moveLeft);
exports.revealContainerSmall = styled_components_1.css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n\tanimation-name: ", ";\n\tanimation-timing-function: cubic-bezier(0.789, 0.301, 0, 0.956);\n\tanimation-duration: 1.8s;\n\tanimation-fill-mode: both;\n"], ["\n\tanimation-name: ", ";\n\tanimation-timing-function: cubic-bezier(0.789, 0.301, 0, 0.956);\n\tanimation-duration: 1.8s;\n\tanimation-fill-mode: both;\n"])), exports.moveUp);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
