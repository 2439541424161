import React from "react"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import { Text, Select } from "@operan/ui-components"
import { useLocation } from "react-router"
import {
	useLanguageSwitchQuery,
	LanguageSwitchQueryVariables
} from "./operations.generated"
import { LocalesContext } from "../../context/LocalesContext"

export interface LanguageSwitchProps {
	id?: string
	useShortTitle?: boolean
	useLabel?: boolean
}

const LanguageSwitch: React.FC<LanguageSwitchProps> = props => {
	const locales = useLocales(translationNamespace.common("LanguageSwitch"), [
		"primaryShortTitle",
		"secondaryShortTitle",
		"primaryTitle",
		"secondaryTitle",
		"label"
	])

	let location = useLocation()
	const { localeCode } = React.useContext(LocalesContext)

	const languageSwitchQueryVariables: LanguageSwitchQueryVariables = {
		path: location ? location.pathname : null,
		culture: localeCode || "sv-se"
	}

	const query = useLanguageSwitchQuery({
		variables: languageSwitchQueryVariables
	})

	return (
		<>
			{props.useLabel && (
				<Text
					as="label"
					htmlFor={props.id || "language-switch-header"}
					fontSize={2}
					mb={0}
					mr={1}
				>
					{locales.label}
				</Text>
			)}
			<Select
				fontSize={2}
				lineHeight={3}
				label={!props.useLabel ? locales.label : null}
				id={props.id || "language-switch-header"}
				options={[
					{
						value:
							localeCode === "en-gb"
								? query.data?.languageSwitch?.redirectUrlEn
								: query.data?.languageSwitch?.redirectUrlSv,
						label: props.useShortTitle
							? locales.primaryShortTitle
							: locales.primaryTitle
					},
					{
						value:
							localeCode === "en-gb"
								? query.data?.languageSwitch?.redirectUrlSv
								: query.data?.languageSwitch?.redirectUrlEn,
						label: props.useShortTitle
							? locales.secondaryShortTitle
							: locales.secondaryTitle
					}
				]}
				onChange={value => {
					const url = value.currentTarget.value
					window.location.href = url
				}}
			/>
		</>
	)
}

export { LanguageSwitch }
