import * as React from "react"
import { RteBlock as IRteBlock } from "../../@types/graphql.generated"
import { Block } from "../Block"
import { Rte, Flex, READABLE_LINE_MAX_WIDTH } from "@operan/ui-components"

function createMarkup(markup: string) {
	return { __html: markup }
}

const RteBlock: React.FC<IRteBlock> = props => (
	<Block themeColorVariant={props.themeColorVariant} theme={props.theme}>
		<Flex display="block" style={{ margin: "0 auto" }} py={{ _: 8, l: 8 }}>
			<Flex
				maxw={READABLE_LINE_MAX_WIDTH}
				w={1}
				style={{ margin: "0 auto" }}
				pl={{ _: 2, m: 5, l: 11 }}
				pr={{ _: 2, m: 5, l: 11 }}
			>
				<Rte>
					{props.text && (
						<div dangerouslySetInnerHTML={createMarkup(props.text)} />
					)}
				</Rte>
			</Flex>
		</Flex>
	</Block>
)

export { RteBlock }
