"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var styled_components_1 = __importStar(require("styled-components"));
var styles_1 = require("../../styles");
var components_1 = require("../../components");
var usePagination_1 = require("./usePagination");
var PaginationStyled = styled_components_1.default.nav.attrs({
    "aria-label": "pagination navigation"
})(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\tdisplay: flex;\n"], ["\n\tdisplay: flex;\n"])));
var PaginationList = styled_components_1.default.ul(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tdisplay: flex;\n\tborder: 1px solid;\n\tborder-color: ", ";\n\tborder-right: none;\n"], ["\n\tdisplay: flex;\n\tborder: 1px solid;\n\tborder-color: ",
    ";\n\tborder-right: none;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.dark ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)";
});
var paginationItemBaseStyles = styled_components_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tcolor: ", ";\n\twidth: ", ";\n\theight: ", ";\n\tfont-size: ", ";\n\tborder-right: 1px solid;\n\tborder-color: ", ";\n\n\t", "\n"], ["\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tcolor: ", ";\n\twidth: ", ";\n\theight: ", ";\n\tfont-size: ", ";\n\tborder-right: 1px solid;\n\tborder-color: ",
    ";\n\n\t",
    "\n"])), function (_a) {
    var theme = _a.theme;
    return (theme.dark ? styles_1.colors["white"] : styles_1.colors["black"]);
}, styles_1.scale.px(4), styles_1.scale.px(4), styles_1.scale.px(2.25), function (_a) {
    var theme = _a.theme;
    return theme.dark ? "rgba(255, 255, 255, 0.2)" : "rgba(0, 0, 0, 0.2)";
}, styles_1.breakpoint("l")(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\t\twidth: ", ";\n\t\theight: ", ";\n\t"], ["\n\t\twidth: ", ";\n\t\theight: ", ";\n\t"])), styles_1.scale.px(7.5), styles_1.scale.px(7.5)));
var PaginationItemStyled = styled_components_1.default.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\t", "\n\n\t& polygon {\n\t\tfill: ", ";\n\t}\n\n\t&:disabled {\n\t\tcursor: default;\n\n\t\t& polygon {\n\t\t\tfill: ", ";\n\t\t}\n\t}\n\n\t&.active {\n\t\tbackground-color: ", ";\n\t\tcolor: ", ";\n\t\tborder-right: none;\n\t}\n"], ["\n\t", "\n\n\t& polygon {\n\t\tfill: ", ";\n\t}\n\n\t&:disabled {\n\t\tcursor: default;\n\n\t\t& polygon {\n\t\t\tfill: ",
    ";\n\t\t}\n\t}\n\n\t&.active {\n\t\tbackground-color: ",
    ";\n\t\tcolor: ", ";\n\t\tborder-right: none;\n\t}\n"])), paginationItemBaseStyles, function (_a) {
    var theme = _a.theme;
    return (theme.dark ? styles_1.colors["white"] : styles_1.colors["black"]);
}, function (_a) {
    var theme = _a.theme;
    return theme.dark ? "rgba(255, 255, 255, 0.2)" : styles_1.colors["greybrown-medium"];
}, function (_a) {
    var theme = _a.theme;
    return theme.dark ? styles_1.colors["white"] : styles_1.colors["greybrown-medium"];
}, styles_1.colors["black"]);
var Ellipsis = styled_components_1.default.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), paginationItemBaseStyles);
var Pagination = function (props) {
    var items = usePagination_1.usePagination(__assign({}, props));
    return (React.createElement(PaginationStyled, null,
        React.createElement(PaginationList, null, items.map(function (item, i) { return (React.createElement("li", { key: i },
            React.createElement(Pagination.Item, __assign({}, item)))); }))));
};
exports.Pagination = Pagination;
Pagination.Item = function (props) {
    var as = props.as, ref = props.ref, onClick = props.onClick, page = props.page, type = props.type, active = props.active, rest = __rest(props, ["as", "ref", "onClick", "page", "type", "active"]);
    return type === "ellipsis" ? (React.createElement(Ellipsis, null, ". . .")) : (React.createElement(PaginationItemStyled, __assign({ onClick: onClick, className: active ? "active" : "" }, rest), type === "prev" ? (React.createElement(components_1.Icon, { icon: "chevron-left", size: 2.5 })) : type === "next" ? (React.createElement(components_1.Icon, { icon: "chevron-right", size: 2.5 })) : (page)));
};
Pagination.defaultProps = {
    currentPage: 1,
    siblingCount: 1,
    boundaryCount: 1,
    locales: {
        previousPage: "Previous page",
        nextPage: "Next page",
        goToPage: "Go to page"
    }
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
