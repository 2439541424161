import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type TabsBlockFieldsFragment = (
  { __typename?: 'tabsBlock' }
  & Pick<Types.TabsBlock, 'id' | 'type' | 'themeColorVariant' | 'title'>
  & { theme?: Types.Maybe<(
    { __typename?: 'theme' }
    & Pick<Types.Theme, 'dark' | 'complementaryColor' | 'primaryColor' | 'textColor'>
  )>, tabs?: Types.Maybe<Array<(
    { __typename?: 'tab' }
    & Pick<Types.Tab, 'icon' | 'id' | 'title'>
    & { content: (
      { __typename?: 'rteTab' }
      & Pick<Types.RteTab, 'id' | 'text'>
    ) | (
      { __typename?: 'videoTab' }
      & Pick<Types.VideoTab, 'id' | 'url'>
    ) | (
      { __typename?: 'slideshowTab' }
      & { items?: Types.Maybe<Array<(
        { __typename?: 'slideshowItem' }
        & Pick<Types.SlideshowItem, 'caption' | 'captionLabel' | 'credit' | 'creditLabel'>
        & { image?: Types.Maybe<(
          { __typename?: 'image' }
          & Pick<Types.Image, 'src' | 'alt' | 'id'>
        )> }
      )>> }
    ) }
  )>> }
);

export const TabsBlockFieldsFragmentDoc = gql`
    fragment tabsBlockFields on tabsBlock {
  id
  type
  themeColorVariant
  theme {
    dark
    complementaryColor
    primaryColor
    textColor
  }
  title
  tabs {
    icon
    id
    title
    content {
      ... on rteTab {
        id
        text
      }
      ... on videoTab {
        id
        url
      }
      ... on slideshowTab {
        items {
          caption
          captionLabel
          credit
          creditLabel
          image {
            src
            alt
            id
          }
        }
      }
    }
  }
}
    `;