import * as React from "react"
import { ThemeProvider } from "styled-components"
import { Theme, ThemeColorVariant } from "../@types/graphql.generated"
import { mergeColorTheme, theme, lightTheme } from "@operan/ui-components"
import throttle from "lodash/throttle"

export interface ColorThemeContextValue {
	setColorTheme: React.Dispatch<React.SetStateAction<Theme>>
	setThemeColorBackground: (
		inView: boolean,
		themeColorVariant: ThemeColorVariant
	) => void
}
export const ColorThemeContext = React.createContext<ColorThemeContextValue>({
	setColorTheme: Theme => {},
	setThemeColorBackground: () => {}
})

export const ColorThemeProvider: React.FC = props => {
	const [currentTheme, setColorTheme] = React.useState<Theme>(lightTheme)
	const [isPrimaryThemeColor, setIsPrimaryThemeColor] = React.useState(false)
	const [themeColorBackground, setBlockColor] = React.useState<string>()

	// Listen for change in ThemeColorVariant sent from blocks
	const setThemeColorBackground = throttle(
		(inView: boolean, themeColorVariant: ThemeColorVariant) => {
			if (inView) {
				themeColorVariant === "PRIMARY"
					? setIsPrimaryThemeColor(true)
					: setIsPrimaryThemeColor(false)
			} else {
				themeColorVariant === "PRIMARY"
					? setIsPrimaryThemeColor(false)
					: setIsPrimaryThemeColor(true)
			}
		},
		100,
		{ trailing: false }
	)

	// Update active theme colors based on ThemeColorVariant
	React.useEffect(() => {
		if (currentTheme) {
			isPrimaryThemeColor
				? setBlockColor(currentTheme.primaryColor)
				: setBlockColor(currentTheme.complementaryColor)
		}
	}, [isPrimaryThemeColor, currentTheme])

	return (
		<ColorThemeContext.Provider
			value={{ setColorTheme, setThemeColorBackground }}
		>
			<ThemeProvider
				theme={{
					// merge base theme ...
					...theme,
					// ... with color theme
					...(currentTheme ? mergeColorTheme(currentTheme) : lightTheme),
					// themeColorBackground
					...(themeColorBackground && {
						themeColorBackground: themeColorBackground
					})
				}}
				{...props}
			/>
		</ColorThemeContext.Provider>
	)
}
