import * as React from "react"
import { ThemeProvider } from "styled-components"
import {
	Flex,
	Text,
	Loader,
	SearchInput,
	Pagination,
	fontFamilyHeadings,
	darkTheme,
	lightTheme,
	INNER_MAX_WIDTH,
	INNER_CONTAINER_MAX_WIDTH,
	READABLE_LINE_MAX_WIDTH
} from "@operan/ui-components"
import {
	SearchBlock as ISearchBlock,
	BlockType
} from "../../@types/graphql.generated"
import { useLocales, translationNamespace } from "../../hooks/useLocales"
import {
	SearchQuery,
	SearchQueryVariables,
	useSearchLazyQuery
} from "./operations.generated"
import {
	mapSearchResultItems,
	parseSearchQueryString,
	searchQueryVariablesToSearchQueryString,
	trackSearchTermHitsCount
} from "./utils"

const SearchBlock: React.FC<ISearchBlock> = props => {
	const locales = useLocales(
		translationNamespace.block(BlockType.SearchBlock),
		[
			"searchFieldLabel",
			"searchFieldPlaceholder",
			"found",
			"hits",
			"nextPage",
			"previousPage",
			"goToPage",
			"searchFieldCancelLabel"
		]
	)

	// Set by onChange event in search form
	const [searchInput, setSearchInput] = React.useState("")

	// Sets variables used in GraphQl query
	const [searchQueryVariables, setSearchQueryVariables] = React.useState<
		SearchQueryVariables
	>({
		searchInput,
		pageInput: {
			page: 1
		}
	})

	// Bool state for initial query if search string in url
	const [initialSearchTriggered, setInitialSearchTrigger] = React.useState(
		false
	)

	// useLazyQuery for fetching data manually
	const [
		fetchSearchResults,
		{ called, loading, data, fetchMore }
	] = useSearchLazyQuery({
		variables: searchQueryVariables,
		fetchPolicy: "cache-and-network"
	})

	// Trigger search on page load if we have query in URL
	React.useEffect(() => {
		const searchQueryStringObject = parseSearchQueryString(
			window.location.search
		)
		const { q, page } = searchQueryStringObject

		if (q && !initialSearchTriggered) {
			setInitialSearchTrigger(true)

			const searchInputFromQuery = Array.isArray(q) ? q[0] : q
			const pageInputFromQuery = page
				? Array.isArray(page)
					? page[0]
					: page
				: null
			const updatedSearchQueryVariables: SearchQueryVariables = {
				searchInput: searchInputFromQuery,
				pageInput: {
					...searchQueryVariables.pageInput,
					page: page ? parseInt(pageInputFromQuery) : 1
				}
			}

			setSearchInput(searchInputFromQuery)
			setSearchQueryVariables(updatedSearchQueryVariables)

			fetchSearchResults({
				variables: updatedSearchQueryVariables
			})
		}
	}, [initialSearchTriggered, searchQueryVariables, fetchSearchResults])

	// Search query triggered on search input submit
	const updateSearchResultsState = (
		updatedSearchQueryVariables?: SearchQueryVariables
	) => {
		setSearchQueryVariables({
			...searchQueryVariables,
			...updatedSearchQueryVariables
		})

		fetchSearchResults({
			variables: {
				...searchQueryVariables,
				...updatedSearchQueryVariables
			}
		})

		if (
			!initialSearchTriggered ||
			Object.keys(searchQueryVariables).length > 0
		) {
			window.history.replaceState(
				{},
				document.title,
				`?${searchQueryVariablesToSearchQueryString({
					...searchQueryVariables,
					...updatedSearchQueryVariables
				})}`
			)
		}
	}

	const onSubmit = (e: React.FormEvent) => {
		e.preventDefault()

		updateSearchResultsState({
			...searchQueryVariables,
			searchInput
		})
	}

	// If user clears search field with native clear button
	const onCancel = () => {
		setSearchInput("")

		updateSearchResultsState({
			...searchQueryVariables,
			searchInput: ""
		})
	}

	// Ref for returning keyboard focus to results list on pagination
	const searchResultItemRef = React.useRef<HTMLAnchorElement>(null)

	// Handle onChange event from Pagination component
	const paginate = (updatedSearchQueryVariables?: SearchQueryVariables) => {
		fetchMore({
			variables: updatedSearchQueryVariables,
			updateQuery: (
				prev: SearchQuery,
				{ fetchMoreResult }: { fetchMoreResult: SearchQuery }
			) => {
				if (!fetchMoreResult) return prev

				return {
					...fetchMoreResult
				}
			}
		})

		if (Object.keys(searchQueryVariables).length > 0) {
			window.history.replaceState(
				{},
				document.title,
				`?${searchQueryVariablesToSearchQueryString({
					...searchQueryVariables,
					...updatedSearchQueryVariables
				})}`
			)
		}

		window.scroll({
			top: 0,
			left: 0
		})

		if (searchResultItemRef.current) {
			// Set focus on first item in search results after paginate
			searchResultItemRef.current.focus()
		}
	}

	React.useEffect(() => {
		if (data && data.search) {
			trackSearchTermHitsCount(
				data.search.searchInput,
				data.search.pagination.totalSize
			)
		}
	}, [data])

	return (
		<Flex flexDirection="column" id={props.id}>
			<ThemeProvider theme={darkTheme}>
				<Flex
					w={1}
					flexDirection="column"
					px={{ _: 2, m: 5, l: 10 }}
					pt={{ _: 6, m: 8, l: 8 }}
					pb={
						data && data?.search?.searchInput && data.search.searchInput !== ""
							? { _: 1, m: 3, l: 10 }
							: { _: 8, m: 12.75, l: 20 }
					}
					backgroundColor="black"
				>
					<Flex
						alignItems="center"
						flexDirection="column"
						w={1}
						maxw={INNER_MAX_WIDTH}
						style={{ margin: "0 auto" }}
					>
						<Flex
							maxw={READABLE_LINE_MAX_WIDTH}
							flexDirection="column"
							alignItems="center"
							style={{ marginLeft: "auto", marginRight: "auto" }}
						>
							{props.title && (
								<Text
									as="h1"
									fontFamily={fontFamilyHeadings}
									fontSize={{ _: 3, s: 5 }}
									lineHeight={{ _: 4, s: 6 }}
									textAlign="center"
									mb={{ _: 5, m: 3 }}
								>
									{props.title}
								</Text>
							)}
						</Flex>
					</Flex>
					<Flex w={1} flexDirection="column">
						<Flex
							maxw={INNER_CONTAINER_MAX_WIDTH}
							w={1}
							style={{ margin: "0 auto" }}
							flexDirection="column"
						>
							<form onSubmit={onSubmit}>
								<SearchInput
									label={locales.searchFieldLabel}
									placeholder={locales.searchFieldPlaceholder}
									value={searchInput}
									changeHandler={e => {
										setSearchInput(e.currentTarget.value)
									}}
									onCancel={onCancel}
									cancelButtonLabel={locales.searchFieldCancelLabel}
								/>
							</form>
							<Flex
								role="region"
								aria-live="polite"
								mt={{ _: 1, l: 2 }}
								flexDirection={{ _: "column", m: "row" }}
							>
								{called &&
									!loading &&
									data?.search?.searchInput &&
									data?.search?.searchInput !== "" && (
										<Text
											fontSize={{ _: 2.25, m: 2.5 }}
											lineHeight={{ _: 3, m: 3.5 }}
											mb={2}
											textColor="white-faded"
										>
											{locales.found}{" "}
											<Text as="span" textColor="white" mb={0}>
												{data.search.pagination.totalSize}
											</Text>{" "}
											{locales.hits}{" "}
											<Text as="span" textColor="white" mb={0}>
												{data.search.searchInput}
											</Text>
										</Text>
									)}

								{props.archiveLink && (
									<Text.Link
										to={props.archiveLink.url}
										ml={{ m: "auto" }}
										mb={
											data?.search?.searchInput &&
											data?.search?.searchInput !== ""
												? { _: 4, m: 0 }
												: 0
										}
										style={{ textDecoration: "underline" }}
										fontSize={{ _: 2, m: 2.25 }}
										lineHeight={{ _: 3, m: 3.25 }}
									>
										{props.archiveLink.label}
									</Text.Link>
								)}
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			</ThemeProvider>
			<ThemeProvider theme={lightTheme}>
				<Flex w={1} flexDirection="column" backgroundColor="white">
					{/* Search results */}
					{called && loading ? (
						<Flex w={1} p={10} justifyContent="center" alignItems="center">
							<Loader />
						</Flex>
					) : data && data.search?.results?.length > 0 ? (
						<Flex px={{ _: 2, m: 5, l: 10 }} py={{ _: 0, m: 4, l: 5 }}>
							<Flex
								maxw={INNER_CONTAINER_MAX_WIDTH}
								w={1}
								style={{ margin: "0 auto" }}
								flexDirection="column"
							>
								<Flex w={1} flexDirection="column">
									{data && mapSearchResultItems(data, searchResultItemRef)}
								</Flex>
							</Flex>
						</Flex>
					) : (
						<></>
					)}
					{/* Pagination */}
					{!loading &&
						data &&
						data.search?.pagination &&
						data.search.pagination.pageSize <
							data.search.pagination.totalSize && (
							<Flex w={1} justifyContent="center" mb={{ _: 8, l: 18 }}>
								<Pagination
									totalPages={data.search.pagination.totalPages}
									currentPage={data.search.pagination.page}
									siblingCount={1}
									boundaryCount={1}
									onChange={(e, page) =>
										paginate({
											...searchQueryVariables,
											pageInput: {
												...searchQueryVariables.pageInput,
												page
											}
										})
									}
									locales={{
										previousPage: locales.previousPage,
										nextPage: locales.nextPage,
										goToPage: locales.goToPage
									}}
								/>
							</Flex>
						)}
				</Flex>
			</ThemeProvider>
		</Flex>
	)
}

export { SearchBlock }
