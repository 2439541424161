import React from "react"
import { useLocation, useHistory, Redirect } from "react-router"
import { Helmet } from "react-helmet"
import { Flex, Loader, useIsomorphicLayoutEffect } from "@operan/ui-components"
import useSSR from "use-ssr"
import {
	GetPageQueryResult,
	useGetPageQuery,
	GetPageQueryVariables
} from "./operations.generated"
import { BlockResolver } from ".."
import * as Types from "../../@types/graphql.generated"
import { ColorThemeContext } from "../../context/ColorThemeContext"
import { ThemeColorBackground } from "../ThemeColorBackground"
import {
	loadCookieControl,
	getCookieConsentByCategory
} from "../../utils/cookieControl"
import { useAnchorLinkScrolling } from "../../hooks"
import queryString from "query-string"
import { sendMatomoEvent } from "../../utils"

interface PageContextValue {
	pageQueryResult?: GetPageQueryResult
}

export const PageContext = React.createContext<PageContextValue>({})

export const Page: React.FC = () => {
	const location = useLocation()
	const history = useHistory()
	const { setColorTheme } = React.useContext(ColorThemeContext)

	const { isServer } = useSSR()
	useAnchorLinkScrolling()

	// Pick previewToken and pageId for Umbraco Preview banner if they exist
	const searchQueryStringObject = !isServer
		? queryString.parse(window?.location.search)
		: {}
	const { pageId, token } = searchQueryStringObject
	const previewContentId: string = pageId?.toString() ?? null
	const previewToken: string = token?.toString() ?? null

	// Main variables for page query - only 'path' is required
	const getPageQueryVariables: GetPageQueryVariables = {
		path: location.pathname + location.search,
		...(pageId && {
			previewContentId: parseInt(previewContentId)
		}),
		...(token && { previewToken })
	}

	// Get page data
	const pageQueryResult = useGetPageQuery({
		notifyOnNetworkStatusChange: true,
		variables: getPageQueryVariables
	})

	// sets page color theme based on page theme
	useIsomorphicLayoutEffect(() => {
		setColorTheme(pageQueryResult.data?.page?.theme)
	}, [pageQueryResult.data, setColorTheme])

	// reset scroll position to 0 after page load
	React.useEffect(() => {
		if (!isServer && history.action !== "POP") {
			let timer = setTimeout(() => window.scrollTo(0, 0), 10)

			return () => {
				clearTimeout(timer)
			}
		}
	}, [location.pathname, isServer, history.action])

	React.useEffect(() => {
		loadCookieControl()
	}, [])

	// Send event to Matomo Tag Manager on every page load to get consent on analytical and marketing cookies
	React.useEffect(() => {
		if (!isServer && window?.CookieControl) {
			const hasAnalyticsCookiesConsent = getCookieConsentByCategory("analytics")
			const hasMarketingCookiesConsent = getCookieConsentByCategory("marketing")

			if (hasAnalyticsCookiesConsent) {
				sendMatomoEvent({
					event: "analyticsConsent",
					analyticsConsentState: true
				})
			} else {
				sendMatomoEvent({
					event: "analyticsConsent",
					analyticsConsentState: false
				})
			}

			if (hasMarketingCookiesConsent) {
				sendMatomoEvent({
					event: "marketingConsent",
					marketingConsentState: true
				})
			} else {
				sendMatomoEvent({
					event: "marketingConsent",
					marketingConsentState: false
				})
			}
		}
	}, [location.pathname, isServer])

	return (
		<>
			{pageQueryResult.loading && !isServer ? (
				<Flex
					style={{
						width: "100vw",
						height: "100vh"
					}}
					justifyContent="center"
					alignItems="center"
				>
					<Loader />
				</Flex>
			) : (
				<>
					{/* handle 301 redirect */}
					{pageQueryResult?.data?.page?.redirectUrl && (
						<Redirect to={pageQueryResult.data.page.redirectUrl} />
					)}
					<main id="main" key={location.pathname}>
						<Flex>
							<Helmet>
								{pageQueryResult.data?.page?.meta?.title && (
									<title>{pageQueryResult.data?.page?.meta?.title}</title>
								)}
								{pageQueryResult.data?.page?.meta?.description && (
									<meta
										name="description"
										content={pageQueryResult.data?.page?.meta?.description}
									/>
								)}
								<meta
									name="viewport"
									content="width=device-width, initial-scale=1.0"
								/>
								<meta
									name="detectify-verification"
									content="fee1429029266c69c7ebf0c43fe94dde"
								/>
							</Helmet>
							<Flex
								display="block"
								w={1}
								style={{
									position: "relative"
								}}
							>
								<>
									<PageContext.Provider
										value={{
											pageQueryResult
										}}
									>
										{pageQueryResult.data?.page?.blocks && (
											<BlockResolver
												blocks={
													pageQueryResult.data?.page?.blocks as Array<
														Types.Blocks
													>
												}
											/>
										)}
									</PageContext.Provider>
									<ThemeColorBackground />
								</>
							</Flex>
						</Flex>
					</main>
				</>
			)}
		</>
	)
}
