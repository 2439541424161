import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type ThreeColumnPromoBlockFieldsFragment = (
  { __typename?: 'threeColumnPromoBlock' }
  & Pick<Types.ThreeColumnPromoBlock, 'id' | 'type' | 'title' | 'preamble' | 'themeColorVariant'>
  & { pages?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'pageSummary' }
    & Pick<Types.PageSummary, 'url' | 'title' | 'preamble'>
    & { image?: Types.Maybe<(
      { __typename?: 'image' }
      & Pick<Types.Image, 'src' | 'alt'>
    )> }
  )>>>, theme?: Types.Maybe<(
    { __typename?: 'theme' }
    & Pick<Types.Theme, 'dark' | 'complementaryColor' | 'primaryColor' | 'textColor'>
  )> }
);

export const ThreeColumnPromoBlockFieldsFragmentDoc = gql`
    fragment threeColumnPromoBlockFields on threeColumnPromoBlock {
  id
  type
  title
  preamble
  pages {
    url
    title
    preamble
    image {
      src
      alt
    }
  }
  themeColorVariant
  theme {
    dark
    complementaryColor
    primaryColor
    textColor
  }
}
    `;