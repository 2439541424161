import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetFooterQueryVariables = Types.Exact<{
  path?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetFooterQuery = (
  { __typename?: 'query' }
  & { footer?: Types.Maybe<(
    { __typename?: 'footerBlock' }
    & Pick<Types.FooterBlock, 'id' | 'type' | 'operaSocialMediaTitle' | 'danceCompanySocialMediaTitle'>
    & { contactDepartments?: Types.Maybe<Array<(
      { __typename?: 'contactDepartment' }
      & Pick<Types.ContactDepartment, 'departmentName' | 'phone' | 'email'>
    )>>, faq?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'faqItem' }
      & { title: (
        { __typename?: 'link' }
        & Pick<Types.Link, 'title' | 'url'>
      ), items?: Types.Maybe<Array<(
        { __typename?: 'link' }
        & Pick<Types.Link, 'title' | 'url'>
      )>> }
    )>>>, faqPage?: Types.Maybe<(
      { __typename?: 'link' }
      & Pick<Types.Link, 'title' | 'url'>
    )>, operaLogo?: Types.Maybe<(
      { __typename?: 'image' }
      & Pick<Types.Image, 'alt' | 'src' | 'id'>
    )>, regionLogo?: Types.Maybe<(
      { __typename?: 'image' }
      & Pick<Types.Image, 'id' | 'src' | 'placeholder' | 'alt'>
    )>, operaSocialMedia?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'link' }
      & Pick<Types.Link, 'title' | 'url'>
    )>>>, danceCompanySocialMedia?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'link' }
      & Pick<Types.Link, 'title' | 'url'>
    )>>>, contactInfoPage?: Types.Maybe<(
      { __typename?: 'link' }
      & Pick<Types.Link, 'title' | 'url'>
    )>, aboutSite?: Types.Maybe<(
      { __typename?: 'link' }
      & Pick<Types.Link, 'title' | 'url'>
    )>, personalData?: Types.Maybe<(
      { __typename?: 'link' }
      & Pick<Types.Link, 'title' | 'url'>
    )>, termsPage?: Types.Maybe<(
      { __typename?: 'link' }
      & Pick<Types.Link, 'title' | 'url'>
    )> }
  )> }
);


export const GetFooterDocument = gql`
    query GetFooter($path: String) {
  footer(path: $path) {
    id
    type
    contactDepartments {
      departmentName
      phone
      email
    }
    faq {
      title {
        title
        url
      }
      items {
        title
        url
      }
    }
    faqPage {
      title
      url
    }
    operaLogo {
      alt
      src
      id
    }
    regionLogo {
      id
      src
      placeholder
      alt
    }
    operaSocialMediaTitle
    operaSocialMedia {
      title
      url
    }
    danceCompanySocialMediaTitle
    danceCompanySocialMedia {
      title
      url
    }
    contactInfoPage {
      title
      url
    }
    aboutSite {
      title
      url
    }
    personalData {
      title
      url
    }
    termsPage {
      title
      url
    }
  }
}
    `;

/**
 * __useGetFooterQuery__
 *
 * To run a query within a React component, call `useGetFooterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFooterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFooterQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useGetFooterQuery(baseOptions?: Apollo.QueryHookOptions<GetFooterQuery, GetFooterQueryVariables>) {
        return Apollo.useQuery<GetFooterQuery, GetFooterQueryVariables>(GetFooterDocument, baseOptions);
      }
export function useGetFooterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFooterQuery, GetFooterQueryVariables>) {
          return Apollo.useLazyQuery<GetFooterQuery, GetFooterQueryVariables>(GetFooterDocument, baseOptions);
        }
export type GetFooterQueryHookResult = ReturnType<typeof useGetFooterQuery>;
export type GetFooterLazyQueryHookResult = ReturnType<typeof useGetFooterLazyQuery>;
export type GetFooterQueryResult = Apollo.QueryResult<GetFooterQuery, GetFooterQueryVariables>;