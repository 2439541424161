import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import "intersection-observer"
import "focus-visible"
import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import "./styles.module.css"

const renderMethod = module.hot ? ReactDOM.render : ReactDOM.hydrate

renderMethod(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
