"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_1 = require("styled-components");
var styles_1 = require("../../styles");
var constants_1 = require("../../constants");
exports.moveRightAndFadeIn = styled_components_1.keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    0% {\n        transform: translateX(", ");\n        opacity: 0;\n    }\n    100% {\n        transform: translateX(0);\n        opacity: 1;\n    }\n"], ["\n    0% {\n        transform: translateX(", ");\n        opacity: 0;\n    }\n    100% {\n        transform: translateX(0);\n        opacity: 1;\n    }\n"])), styles_1.scale.px(-6));
exports.moveRightBig = styled_components_1.keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    0% {\n        transform: translateX(", ");\n    }\n    100% {\n        transform: translateX(0);\n    }\n"], ["\n    0% {\n        transform: translateX(", ");\n    }\n    100% {\n        transform: translateX(0);\n    }\n"])), styles_1.scale.px(-17));
exports.moveDownAndFadeIn = styled_components_1.keyframes(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    0% {\n        transform: translateY(", ");\n        opacity: 0;\n    }\n    100% {\n        transform: translateY(0);\n        opacity: 1;\n    }\n"], ["\n    0% {\n        transform: translateY(", ");\n        opacity: 0;\n    }\n    100% {\n        transform: translateY(0);\n        opacity: 1;\n    }\n"])), styles_1.scale.px(6));
exports.contentBlockAnimation = styled_components_1.css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1.5s;\n\tanimation-fill-mode: forwards;\n\tanimation-delay: ", ";\n"], ["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1.5s;\n\tanimation-fill-mode: forwards;\n\tanimation-delay: ",
    ";\n"])), exports.moveRightAndFadeIn, constants_1.TRANSITION_CURVE, function (props) {
    return props.pageGridOrder ? props.pageGridOrder * 0.2 + "s" : "0s";
});
exports.headingAnimation = styled_components_1.css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-fill-mode: forwards;\n\tanimation-delay: ", ";\n"], ["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-fill-mode: forwards;\n\tanimation-delay: ",
    ";\n"])), exports.moveDownAndFadeIn, constants_1.TRANSITION_CURVE, function (props) {
    return props.pageGridOrder ? 0.9 + props.pageGridOrder * 0.2 + "s" : "0s";
});
exports.preambleAnimation = styled_components_1.css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-fill-mode: forwards;\n\tanimation-delay: ", ";\n"], ["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-fill-mode: forwards;\n\tanimation-delay: ",
    ";\n"])), exports.moveDownAndFadeIn, constants_1.TRANSITION_CURVE, function (props) {
    return props.pageGridOrder ? 1 + props.pageGridOrder * 0.2 + "s" : "0s";
});
exports.arrowAnimation = styled_components_1.css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-fill-mode: forwards;\n\tanimation-delay: ", ";\n"], ["\n\tanimation-name: ", ";\n\tanimation-timing-function: ", ";\n\tanimation-duration: 1s;\n\tanimation-fill-mode: forwards;\n\tanimation-delay: ",
    ";\n"])), exports.moveRightBig, constants_1.TRANSITION_CURVE, function (props) {
    return props.pageGridOrder && props.pageGridOrder > 1
        ? props.pageGridOrder * 0.2 + "s"
        : "0s";
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
