import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type ProgramListingBlockFieldsFragment = (
  { __typename?: 'programListingBlock' }
  & Pick<Types.ProgramListingBlock, 'id' | 'type' | 'themeColorVariant'>
  & { pages?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'pageSummary' }
    & Pick<Types.PageSummary, 'tag' | 'name' | 'meta' | 'title' | 'url' | 'editorsPick'>
    & { theme?: Types.Maybe<(
      { __typename?: 'theme' }
      & Pick<Types.Theme, 'dark' | 'complementaryColor' | 'primaryColor'>
    )>, image?: Types.Maybe<(
      { __typename?: 'image' }
      & Pick<Types.Image, 'src' | 'placeholder'>
    )> }
  )>>> }
);

export const ProgramListingBlockFieldsFragmentDoc = gql`
    fragment programListingBlockFields on programListingBlock {
  id
  type
  pages {
    tag
    name
    meta
    title
    url
    theme {
      dark
      complementaryColor
      primaryColor
    }
    image {
      src
      placeholder
    }
    editorsPick
  }
  themeColorVariant
}
    `;