import env from "@beam-australia/react-env"

/* Predefined exisiting dimensions */
/*
	Aspect ratios for validDimensions:
	600w: 1x1
*/
type validDimensions = "600" | "750" | "768" | "1600" | "1900"

export const qBankBaseUrl = env("QBANK_BASE_URL")

export const qBankImageSrc = (image, dimension: validDimensions = "750") =>
	`${qBankBaseUrl}/${dimension}w/${image}`

export const qBankVideoSrc = videoUrl => `${qBankBaseUrl}/${videoUrl}`
