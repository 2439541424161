import React, { useEffect } from "react"
import { ThemeProvider } from "styled-components"
import { useInView } from "react-intersection-observer"
import { ColorThemeContext } from "../../context/ColorThemeContext"
import { ThemeColorVariant, Theme } from "../../@types/graphql.generated"
import { Flex, lightTheme, mergeColorTheme } from "@operan/ui-components"

interface BlockProps {
	themeColorVariant?: ThemeColorVariant
	theme?: Theme
}

export const Block: React.FC<BlockProps> = props => {
	const [ref, inView] = useInView({
		threshold: 0.5
	})
	const { setThemeColorBackground } = React.useContext(ColorThemeContext)

	const setThemeColorBackgroundRef = React.useRef(() =>
		setThemeColorBackground(inView, props.themeColorVariant)
	)

	useEffect(() => {
		setThemeColorBackgroundRef.current()
	}, [inView])

	return (
		<div ref={props.themeColorVariant ? ref : null}>
			{/* use page or block theming based on themeColorVariant value */}
			{props.themeColorVariant ? (
				props.children
			) : (
				<ThemeProvider theme={mergeColorTheme(props.theme || lightTheme)}>
					<Flex
						display="block"
						style={{
							backgroundColor: props.theme
								? props.theme.primaryColor
								: `#FFFEFC`
						}}
					>
						{props.children}
					</Flex>
				</ThemeProvider>
			)}
		</div>
	)
}
