import React from "react"
import { Blocks, BlockType } from "../../@types/graphql.generated"
import {
	RteBlock,
	ArticleRteBlock,
	PageIntroBlock,
	ShowHeroBlock,
	ShowIntroBlock,
	RelatedPagesBlock,
	VideoBlock,
	ProgramListingBlock,
	PageGridBlock,
	LandingPageHeroBlock,
	ThreeColumnPromoBlock,
	MediaContentBlock,
	AccordionBlock,
	BreadcrumbsBlock,
	SlideshowBlock,
	SearchBlock,
	ProfilePageHeroBlock,
	ProfileListingBlock,
	HeroBlock,
	IFrameBlock,
	ParticipantsBlock,
	MultipleShowsBlock,
	AlertBlock,
	PerformancePastBlock,
	PreviewBlock,
	SeasonCalendarBlock,
	TabsBlock,
	ShowAlertBlock,
	VideoPageIntroBlock,
	VideoGridBlock
} from "../"

export interface BlocksResolverProps {
	blocks?: Blocks[]
}

const getBlockTypes = (blockType: BlockType): React.FC<any> =>
	({
		[BlockType.RteBlock]: RteBlock,
		[BlockType.ArticleRteBlock]: ArticleRteBlock,
		[BlockType.PageIntroBlock]: PageIntroBlock,
		[BlockType.ShowHeroBlock]: ShowHeroBlock,
		[BlockType.ShowIntroBlock]: ShowIntroBlock,
		[BlockType.RelatedPagesBlock]: RelatedPagesBlock,
		[BlockType.VideoBlock]: VideoBlock,
		[BlockType.ProgramListingBlock]: ProgramListingBlock,
		[BlockType.PageGridBlock]: PageGridBlock,
		[BlockType.LandingPageHeroBlock]: LandingPageHeroBlock,
		[BlockType.ThreeColumnPromoBlock]: ThreeColumnPromoBlock,
		[BlockType.MediaContentBlock]: MediaContentBlock,
		[BlockType.AccordionBlock]: AccordionBlock,
		[BlockType.BreadcrumbsBlock]: BreadcrumbsBlock,
		[BlockType.SlideshowBlock]: SlideshowBlock,
		[BlockType.SearchBlock]: SearchBlock,
		[BlockType.ProfilePageHeroBlock]: ProfilePageHeroBlock,
		[BlockType.ProfileListingBlock]: ProfileListingBlock,
		[BlockType.HeroBlock]: HeroBlock,
		[BlockType.IframeBlock]: IFrameBlock,
		[BlockType.ParticipantsBlock]: ParticipantsBlock,
		[BlockType.MultipleShowsBlock]: MultipleShowsBlock,
		[BlockType.AlertBlock]: AlertBlock,
		[BlockType.PerformancePastBlock]: PerformancePastBlock,
		[BlockType.PreviewBlock]: PreviewBlock,
		[BlockType.SeasonCalendarBlock]: SeasonCalendarBlock,
		[BlockType.TabsBlock]: TabsBlock,
		[BlockType.ShowAlertBlock]: ShowAlertBlock,
		[BlockType.VideoPageBlock]: VideoPageIntroBlock,
		[BlockType.VideoGridBlock]: VideoGridBlock,
		[BlockType.Unknown]: React.Fragment
	}[blockType] || React.Fragment)

const BlockResolver: React.FC<BlocksResolverProps> = props => (
	<>
		{props.blocks &&
			props.blocks.reduce((acc: React.ReactElement[], block) => {
				const { __typename, ...rest } = block

				return block
					? [
							...acc,
							React.createElement(getBlockTypes(block.type), {
								...rest,
								key: block.id
							})
					  ]
					: [...acc]
			}, [])}
	</>
)

export { BlockResolver }
