import * as React from "react"
import { Link } from "react-router-dom"
import {
	ThreeColumnPromoBlock as IThreeColumnPromoBlock,
	PageSummary
} from "../../@types/graphql.generated"
import { Flex, CircledImagePromo } from "@operan/ui-components"
import { Block } from "../Block/Block"
import { qBankImageSrc, FallbackImage } from "../../utils"
import { BlockTitlePreamble } from "../../utils/layout"
import { BlockCommonNarrowLayout } from "../../utils/layout"

const ThreeColumnPromoBlock: React.FC<IThreeColumnPromoBlock> = props => {
	const pageLength = props.pages.length

	return (
		<Block themeColorVariant={props.themeColorVariant} theme={props.theme}>
			<BlockCommonNarrowLayout>
				{props.title && (
					<BlockTitlePreamble
						title={props.title}
						preamble={props.preamble || null}
					/>
				)}
				<Flex
					w={1}
					style={{ margin: "0 auto" }}
					flexDirection={{ _: "column", m: "row" }}
					justifyContent={pageLength === 3 ? "space-between" : "center"}
				>
					{props.pages.map((page: PageSummary, i) => {
						const firstChild = i === 0
						const lastChild = i + 1 === pageLength

						return (
							<Link to={page.url} key={i}>
								<Flex
									my={{ _: 3, m: 0 }}
									ml={{ _: 0, m: firstChild ? 0 : 2, xl: firstChild ? 0 : 4 }}
									mr={{ _: 0, m: lastChild ? 0 : 2, xl: lastChild ? 0 : 4 }}
									justifyContent="center"
								>
									<CircledImagePromo
										media={
											page.image ? (
												<img
													src={qBankImageSrc(page.image.src, "750")}
													alt=""
												/>
											) : (
												<FallbackImage />
											)
										}
										{...page}
									/>
								</Flex>
							</Link>
						)
					})}
				</Flex>
			</BlockCommonNarrowLayout>
		</Block>
	)
}

export { ThreeColumnPromoBlock }
