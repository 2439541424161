import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type PageIntroBlockFieldsFragment = (
  { __typename?: 'pageIntroBlock' }
  & Pick<Types.PageIntroBlock, 'id' | 'themeColorVariant' | 'type' | 'title' | 'preamble'>
  & { image?: Types.Maybe<(
    { __typename?: 'image' }
    & Pick<Types.Image, 'id' | 'alt' | 'src' | 'placeholder'>
  )>, menu?: Types.Maybe<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'title' | 'url'>
    & { children?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'menu' }
      & Pick<Types.Menu, 'id' | 'title' | 'url'>
      & { children?: Types.Maybe<Array<Types.Maybe<(
        { __typename?: 'menu' }
        & Pick<Types.Menu, 'id' | 'title' | 'url'>
      )>>> }
    )>>> }
  )>, campaignArea?: Types.Maybe<(
    { __typename?: 'campaignArea' }
    & Pick<Types.CampaignArea, 'campaignAreaTitle' | 'campaignAreaText' | 'campaignAreaShowHide' | 'campaignAreaCardColor' | 'campaignAreaDarkMode'>
    & { campaignAreaLink?: Types.Maybe<(
      { __typename?: 'link' }
      & Pick<Types.Link, 'alt' | 'target' | 'url' | 'title'>
    )>, campaignAreaImage?: Types.Maybe<(
      { __typename?: 'image' }
      & Pick<Types.Image, 'id' | 'alt' | 'src' | 'placeholder'>
    )> }
  )> }
);

export const PageIntroBlockFieldsFragmentDoc = gql`
    fragment pageIntroBlockFields on pageIntroBlock {
  id
  themeColorVariant
  type
  title
  preamble
  image {
    id
    alt
    src
    placeholder
  }
  menu {
    id
    title
    url
    children {
      id
      title
      url
      children {
        id
        title
        url
      }
    }
  }
  campaignArea {
    campaignAreaTitle
    campaignAreaText
    campaignAreaLink {
      alt
      target
      url
      title
    }
    campaignAreaShowHide
    campaignAreaCardColor
    campaignAreaDarkMode
    campaignAreaImage {
      id
      alt
      src
      placeholder
    }
  }
}
    `;