import * as React from "react"
import { RteBlock as IRteBlock } from "../../@types/graphql.generated"
import { Rte, Flex, READABLE_LINE_MAX_WIDTH } from "@operan/ui-components"
import { BlockCommonNarrowLayout } from "../../utils/layout"
import { Block } from "../Block"

function createMarkup(markup: string) {
	return { __html: markup }
}

const ArticleRteBlock: React.FC<IRteBlock> = props => (
	<Block themeColorVariant={props.themeColorVariant} theme={props.theme}>
		<BlockCommonNarrowLayout>
			<Flex
				style={{ alignSelf: "flex-start" }}
				maxw={READABLE_LINE_MAX_WIDTH}
				pr={{ _: 0, l: 39, xxl: 30, xxxl: 22 }}
			>
				<Rte>
					{props.text && (
						<div dangerouslySetInnerHTML={createMarkup(props.text)} />
					)}
				</Rte>
			</Flex>
		</BlockCommonNarrowLayout>
	</Block>
)

export { ArticleRteBlock }
