import { Image } from "../@types/graphql.generated"
import { qBankImageSrc } from "."

export const imageDataToImageProps = (imageData: Image) => {
	return {
		alt: imageData.alt,
		width: 1600,
		height: 900,
		src: qBankImageSrc(imageData.src, "1600"),
		srcSet: `
			${qBankImageSrc(imageData.src, "750")} 750w,
			${qBankImageSrc(imageData.src, "1600")} 1600w,
			${qBankImageSrc(imageData.src, "1900")} 1900w`
	}
}
