import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type VideoGridBlockFieldsFragment = (
  { __typename?: 'videoGridBlock' }
  & Pick<Types.VideoGridBlock, 'id' | 'type' | 'title' | 'description' | 'themeColorVariant'>
  & { theme?: Types.Maybe<(
    { __typename?: 'theme' }
    & Pick<Types.Theme, 'dark' | 'complementaryColor' | 'primaryColor' | 'textColor'>
  )>, videoPages?: Types.Maybe<Array<(
    { __typename?: 'videoPageIntroBlock' }
    & Pick<Types.VideoPageIntroBlock, 'title' | 'preamble' | 'text' | 'fromDate' | 'toDate' | 'themeColorVariant'>
    & { link?: Types.Maybe<(
      { __typename?: 'link' }
      & Pick<Types.Link, 'title' | 'url' | 'target'>
    )>, videoDetails?: Types.Maybe<(
      { __typename?: 'vimeoDetails' }
      & Pick<Types.VimeoDetails, 'title' | 'videoThumbnail'>
    )> }
  )>> }
);

export const VideoGridBlockFieldsFragmentDoc = gql`
    fragment videoGridBlockFields on videoGridBlock {
  id
  type
  title
  description
  themeColorVariant
  theme {
    dark
    complementaryColor
    primaryColor
    textColor
  }
  videoPages {
    title
    preamble
    text
    fromDate
    toDate
    themeColorVariant
    link {
      title
      url
      target
    }
    videoDetails {
      title
      videoThumbnail
    }
  }
}
    `;