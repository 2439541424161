import * as React from "react"
import { Link } from "react-router-dom"
import {
	PageGridBlock as IPageGridBlock,
	PageSummary
} from "../../@types/graphql.generated"
import { PageGrid, Promo, VideoPlayer } from "@operan/ui-components"
import { Block } from "../Block/Block"
import { qBankImageSrc, qBankVideoSrc, FallbackImage } from "../../utils"
import { BlockCommonLayout, BlockTitlePreamble } from "../../utils/layout"

const PageGridBlock: React.FC<IPageGridBlock> = props => {
	return (
		<Block themeColorVariant={props.themeColorVariant} theme={props.theme}>
			<BlockCommonLayout>
				{props.title && (
					<BlockTitlePreamble
						title={props.title}
						preamble={props.preamble || null}
					/>
				)}
				<PageGrid mirrorLayout={props.mirrorLayout}>
					{props.pages.map((page: PageSummary, i) => {
						const pageGridOrder = i + 1
						const hideMedia = props.pages.length === 4 && pageGridOrder > 2

						return (
							<Link to={page.url} style={{ width: "100%" }} key={i}>
								<Promo
									media={
										!hideMedia ? (
											page.videoUrl ? (
												<VideoPlayer
													useCustomControls={true}
													autoplay={true}
													url={qBankVideoSrc(page.videoUrl)}
													muted
													playsinline
													loop
												/>
											) : page.image ? (
												<img
													loading="lazy"
													alt=""
													src={qBankImageSrc(page.image.src, "1600")}
												/>
											) : (
												<FallbackImage />
											)
										) : null
									}
									pageGridOrder={pageGridOrder}
									bgColor={page.theme && page.theme.primaryColor}
									textColor={page.theme && page.theme.textColor}
									{...page}
								/>
							</Link>
						)
					})}
				</PageGrid>
			</BlockCommonLayout>
		</Block>
	)
}

export { PageGridBlock }
