import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type ShowAlertBlockFieldsFragment = (
  { __typename?: 'showAlertBlock' }
  & Pick<Types.ShowAlertBlock, 'id' | 'type' | 'themeColorVariant' | 'body' | 'icon'>
  & { theme?: Types.Maybe<(
    { __typename?: 'theme' }
    & Pick<Types.Theme, 'dark' | 'textColor' | 'complementaryColor'>
  )> }
);

export const ShowAlertBlockFieldsFragmentDoc = gql`
    fragment showAlertBlockFields on showAlertBlock {
  id
  type
  theme {
    dark
    textColor
    complementaryColor
  }
  themeColorVariant
  body
  icon
}
    `;