"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var framer_motion_1 = require("framer-motion");
var popcorn_1 = require("@popmotion/popcorn");
var components_1 = require("../../components");
var styled_1 = require("./styled");
// Define how slides should animate from one state to another.
// Note: X offset needs to take scale into account
var variants = {
    enter: function (direction) {
        return {
            x: direction > 0 ? "114%" : "-115%",
            scale: 1.3
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        scale: 1
    },
    exit: function (direction) {
        return {
            zIndex: 0,
            x: direction < 0 ? "105%" : "-105%",
            scale: 1.1
        };
    }
};
var Slideshow = function (props) {
    var _a = React.useState([0, 0]), _b = _a[0], item = _b[0], direction = _b[1], setItem = _a[1];
    var itemCount = props.items ? props.items.length : 0;
    // Wraps a number when it breaches the defined range
    // Allows animatePrescence to infinitely paginate an array of items
    var activeIndex = popcorn_1.wrap(0, itemCount, item);
    // Paginate with nav btns
    var paginate = function (newDirection) {
        setItem([item + newDirection, newDirection]);
    };
    // Nav btns animation states
    var _c = React.useState(false), prevBtnAnimate = _c[0], setPrevBtnAnimate = _c[1];
    var _d = React.useState(false), nextBtnAnimate = _d[0], setNextBtnAnimate = _d[1];
    // Swipe calculation
    var swipeConfidenceThreshold = 10000;
    var swipePower = function (offset, velocity) {
        return Math.abs(offset) * velocity;
    };
    var _e = props.items[activeIndex], image = _e.image, captionLabel = _e.captionLabel, caption = _e.caption, creditLabel = _e.creditLabel, credit = _e.credit;
    return (React.createElement(styled_1.SlideshowStyled, null,
        React.createElement(styled_1.SlideshowContent, null,
            React.createElement(styled_1.SlideshowImageWrapper, null,
                React.createElement(framer_motion_1.AnimatePresence, { initial: false, custom: direction },
                    React.createElement(framer_motion_1.motion.img, { key: item, src: image.src, alt: image.alt || "", custom: direction, variants: variants, initial: "enter", animate: "center", exit: "exit", transition: { ease: [0.62, 0.102, 0.082, 1.009], duration: 1 }, drag: "x", dragConstraints: { left: 0, right: 0 }, dragElastic: 1, onDragEnd: function (e, _a) {
                            var offset = _a.offset, velocity = _a.velocity;
                            var swipe = swipePower(offset.x, velocity.x);
                            if (swipe < -swipeConfidenceThreshold) {
                                paginate(1);
                            }
                            else if (swipe > swipeConfidenceThreshold) {
                                paginate(-1);
                            }
                        } }))),
            React.createElement(styled_1.PrevBtnStyled, __assign({ onClick: function () {
                    paginate(-1);
                    setPrevBtnAnimate(true);
                }, onAnimationEnd: function () { return setPrevBtnAnimate(false); }, className: prevBtnAnimate ? "animate" : "", themeColorVariant: props.themeColorVariant || "PRIMARY" }, props.locales),
                React.createElement(components_1.Icon, { icon: "arrow-left", size: 1.5 })),
            React.createElement(styled_1.NextBtnStyled, __assign({ onClick: function () {
                    paginate(1);
                    setNextBtnAnimate(true);
                    props.onClickListener && props.onClickListener();
                }, onAnimationEnd: function () { return setNextBtnAnimate(false); }, className: nextBtnAnimate ? "animate" : "", themeColorVariant: props.themeColorVariant || "PRIMARY" }, props.locales),
                React.createElement(components_1.Icon, { icon: "arrow-right", size: 1.5 }))),
        React.createElement(styled_1.FooterStyled, null,
            React.createElement(styled_1.CaptionStyled, null,
                caption && (React.createElement(React.Fragment, null,
                    React.createElement(components_1.Text, { as: "span", fontWeight: "semibold" },
                        captionLabel,
                        ": \u00A0"),
                    React.createElement(components_1.Text, { as: "span", mb: 0 }, caption),
                    React.createElement(components_1.Text, { as: "span", mx: { _: 1, s: 2 }, mb: 0 }, "|"))),
                credit && (React.createElement(React.Fragment, null,
                    React.createElement(components_1.Text, { as: "span", fontWeight: "semibold", fontStyle: "italic" },
                        creditLabel,
                        ": \u00A0"),
                    React.createElement(components_1.Text, { as: "span", fontWeight: "normal", fontStyle: "italic" }, credit)))),
            React.createElement(styled_1.Indicator, null, props.items.map(function (item, i) { return (React.createElement(styled_1.IndicatorItem, { key: i, className: i === activeIndex ? "active" : null })); })))));
};
exports.Slideshow = Slideshow;
