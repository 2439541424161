"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
// https://dev.to/namirsab/comment/2050
var range = function (start, end) {
    var length = end - start + 1;
    return Array.from({ length: length }, function (_, i) { return start + i; });
};
exports.usePagination = function (props) {
    var totalPages = props.totalPages, currentPage = props.currentPage, siblingCount = props.siblingCount, boundaryCount = props.boundaryCount, onChange = props.onChange, locales = props.locales;
    var _a = React.useState(currentPage ? currentPage : 1), page = _a[0], setCurrentPage = _a[1];
    React.useEffect(function () {
        if (page !== currentPage) {
            setCurrentPage(currentPage);
        }
    }, [page, currentPage]);
    var startPages = range(1, Math.min(boundaryCount, totalPages));
    var endPages = range(Math.max(totalPages - boundaryCount + 1, boundaryCount + 1), totalPages);
    var siblingsStart = Math.max(Math.min(
    // Natural start
    page - siblingCount, 
    // Lower boundary when page is high
    totalPages - boundaryCount - siblingCount * 2 - 1), 
    // Greater than startPages
    boundaryCount + 2);
    var siblingsEnd = Math.min(Math.max(
    // Natural end
    page + siblingCount, 
    // Upper boundary when page is low
    boundaryCount + siblingCount * 2 + 2), 
    // Less than endPages
    endPages[0] - 2);
    // Eg output: ['prev', 1, 'ellipsis', 4, 5, 6, 'ellipsis', 10, 'next']
    var itemList = __spreadArrays(["prev"], startPages, (siblingsStart > boundaryCount + 2
        ? ["ellipsis"]
        : boundaryCount + 1 < totalPages - boundaryCount
            ? [boundaryCount + 1]
            : []), range(siblingsStart, siblingsEnd), (siblingsEnd < totalPages - boundaryCount - 1
        ? ["ellipsis"]
        : totalPages - boundaryCount > boundaryCount
            ? [totalPages - boundaryCount]
            : []), endPages, ["next"]);
    var handleClick = function (event, value) {
        setCurrentPage(value);
        if (onChange) {
            onChange(event, value);
        }
    };
    var items = itemList.map(function (item) {
        return typeof item === "number"
            ? {
                onClick: function (e) {
                    handleClick(e, item);
                },
                type: "page",
                page: item,
                active: item === page,
                disabled: item === page ? true : false,
                "aria-current": item === page ? "true" : undefined,
                "aria-label": item === page ? "" : locales.goToPage + " " + item
            }
            : {
                onClick: function (e) {
                    handleClick(e, item === "prev" ? page - 1 : page + 1);
                },
                type: item,
                page: item === "prev" ? page - 1 : page + 1,
                active: false,
                disabled: item.indexOf("ellipsis") > -1 ||
                    (item === "next" ? page >= totalPages : page <= 1),
                "aria-label": item === "prev" ? locales.previousPage : locales.nextPage
            };
    });
    return items;
};
