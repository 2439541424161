import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type CalendarDayFieldsFragment = (
  { __typename?: 'calendarDay' }
  & Pick<Types.CalendarDay, 'dayMonth' | 'dayNumber' | 'name'>
  & { events?: Types.Maybe<Array<(
    { __typename?: 'event' }
    & Pick<Types.Event, 'id' | 'eventGroupId' | 'eventGroupTitle' | 'title' | 'tag' | 'location' | 'scene' | 'ticketInformation' | 'additionalInfo' | 'url'>
    & { date: (
      { __typename?: 'eventDate' }
      & Pick<Types.EventDate, 'day' | 'dayMonth' | 'formatted' | 'time'>
    ), cta?: Types.Maybe<(
      { __typename?: 'CtaGraphType' }
      & Pick<Types.CtaGraphType, 'label' | 'url' | 'disabled'>
    )> }
  )>> }
);

export type DateRangeFieldsFragment = (
  { __typename?: 'dateRange' }
  & { from?: Types.Maybe<(
    { __typename?: 'dateTime' }
    & Pick<Types.DateTime, 'datetime'>
    & { formatted?: Types.Maybe<(
      { __typename?: 'formattedDate' }
      & Pick<Types.FormattedDate, 'date' | 'month' | 'time' | 'weekday' | 'year'>
    )> }
  )>, to?: Types.Maybe<(
    { __typename?: 'dateTime' }
    & Pick<Types.DateTime, 'datetime'>
    & { formatted?: Types.Maybe<(
      { __typename?: 'formattedDate' }
      & Pick<Types.FormattedDate, 'date' | 'month' | 'time' | 'weekday' | 'year'>
    )> }
  )> }
);

export type SeasonCalendarBlockFieldsFragment = (
  { __typename?: 'seasonCalendarBlock' }
  & Pick<Types.SeasonCalendarBlock, 'id' | 'type' | 'title' | 'preamble' | 'themeColorVariant'>
  & { theme?: Types.Maybe<(
    { __typename?: 'theme' }
    & Pick<Types.Theme, 'complementaryColor' | 'dark' | 'primaryColor' | 'textColor'>
  )>, content?: Types.Maybe<(
    { __typename?: 'calendarContent' }
    & { selectMonthFacets?: Types.Maybe<Array<(
      { __typename?: 'calendarMonthFacet' }
      & Pick<Types.CalendarMonthFacet, 'label' | 'value' | 'disabled'>
      & { days: Array<(
        { __typename?: 'facet' }
        & Pick<Types.Facet, 'label' | 'value' | 'disabled'>
      )> }
    )>>, showFacets?: Types.Maybe<Array<(
      { __typename?: 'showFacet' }
      & Pick<Types.ShowFacet, 'label' | 'value' | 'tag' | 'disabled'>
    )>>, tagFacets?: Types.Maybe<Array<(
      { __typename?: 'facet' }
      & Pick<Types.Facet, 'label' | 'value' | 'disabled'>
    )>>, months: Array<(
      { __typename?: 'calendarMonth' }
      & Pick<Types.CalendarMonth, 'name' | 'monthYear'>
      & { days?: Types.Maybe<Array<(
        { __typename?: 'calendarDay' }
        & CalendarDayFieldsFragment
      ) | (
        { __typename?: 'dateRange' }
        & DateRangeFieldsFragment
      )>> }
    )> }
  )> }
);

export const CalendarDayFieldsFragmentDoc = gql`
    fragment calendarDayFields on calendarDay {
  dayMonth
  dayNumber
  name
  events {
    id
    eventGroupId
    eventGroupTitle
    title
    tag
    date {
      day
      dayMonth
      formatted
      time
    }
    location
    scene
    ticketInformation
    additionalInfo
    cta {
      label
      url
      disabled
    }
    url
  }
}
    `;
export const DateRangeFieldsFragmentDoc = gql`
    fragment dateRangeFields on dateRange {
  from {
    datetime
    formatted {
      date
      month
      time
      weekday
      year
    }
  }
  to {
    datetime
    formatted {
      date
      month
      time
      weekday
      year
    }
  }
}
    `;
export const SeasonCalendarBlockFieldsFragmentDoc = gql`
    fragment seasonCalendarBlockFields on seasonCalendarBlock {
  id
  type
  title
  preamble
  theme {
    complementaryColor
    dark
    primaryColor
    textColor
  }
  themeColorVariant
  content {
    selectMonthFacets {
      days {
        label
        value
        disabled
      }
      label
      value
      disabled
    }
    showFacets {
      label
      value
      tag
      disabled
    }
    tagFacets {
      label
      value
      disabled
    }
    months {
      name
      monthYear
      days {
        ... on calendarDay {
          ...calendarDayFields
        }
        ... on dateRange {
          ...dateRangeFields
        }
      }
    }
  }
}
    ${CalendarDayFieldsFragmentDoc}
${DateRangeFieldsFragmentDoc}`;