import * as Types from '../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetTranslationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetTranslationsQuery = (
  { __typename?: 'query' }
  & { translations?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'translation' }
    & Pick<Types.Translation, 'key' | 'value'>
  )>>> }
);


export const GetTranslationsDocument = gql`
    query GetTranslations {
  translations {
    key
    value
  }
}
    `;

/**
 * __useGetTranslationsQuery__
 *
 * To run a query within a React component, call `useGetTranslationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTranslationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTranslationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTranslationsQuery(baseOptions?: Apollo.QueryHookOptions<GetTranslationsQuery, GetTranslationsQueryVariables>) {
        return Apollo.useQuery<GetTranslationsQuery, GetTranslationsQueryVariables>(GetTranslationsDocument, baseOptions);
      }
export function useGetTranslationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTranslationsQuery, GetTranslationsQueryVariables>) {
          return Apollo.useLazyQuery<GetTranslationsQuery, GetTranslationsQueryVariables>(GetTranslationsDocument, baseOptions);
        }
export type GetTranslationsQueryHookResult = ReturnType<typeof useGetTranslationsQuery>;
export type GetTranslationsLazyQueryHookResult = ReturnType<typeof useGetTranslationsLazyQuery>;
export type GetTranslationsQueryResult = Apollo.QueryResult<GetTranslationsQuery, GetTranslationsQueryVariables>;