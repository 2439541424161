import * as React from "react"
import { useLocation } from "react-router"
import { Flex, INNER_CONTAINER_MAX_WIDTH, VideoPlayer } from "@operan/ui-components"
import { Block } from "../Block/Block"
import { VideoBlock as IVideoBlock } from "../../@types/graphql.generated"
import { BlockFullWidthLayout, BlockTitlePreamble } from "../../utils/layout"
import { trackVideoInteraction } from "../../utils"

const VideoBlock: React.FC<IVideoBlock> = props => {
	const location = useLocation()
	const [isPlaying, setIsPlaying] = React.useState(false)
	const [progressEventSent, setProgressEventSent] = React.useState(false)

	return (
		<Block themeColorVariant={props.themeColorVariant} theme={props.theme}>
			<BlockFullWidthLayout>
				<Flex 
					px={{ _: 0, l: 10 }}
					w={{ _: "100%" }}
					maxw={INNER_CONTAINER_MAX_WIDTH} 
					alignItems="center"
					flexDirection="column"
				>
					<BlockTitlePreamble
						title={props.title}
						preamble={props.preamble || null}
					/>
					<VideoPlayer
						url={props.url}
						loadWhenInView={true}
						onStart={() => {
							trackVideoInteraction("Play", location.pathname)
							setIsPlaying(true)
						}}
						onProgress={({ played }) => {
							if (
								isPlaying &&
								!progressEventSent &&
								parseFloat(played.toFixed(1)) === 0.5
							) {
								trackVideoInteraction("50% progress", location.pathname)
								setProgressEventSent(true)
							}
						}}
						onEnded={() => {
							trackVideoInteraction("Complete", location.pathname)
							setIsPlaying(false)
						}}
					/>
				</Flex>
			</BlockFullWidthLayout>
		</Block>
	)
}

export { VideoBlock }
