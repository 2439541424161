"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var framer_motion_1 = require("framer-motion");
var AnimatedContainer = function (props) {
    return (React.createElement(framer_motion_1.AnimatePresence, { initial: false }, props.active && (React.createElement(framer_motion_1.motion.section, { style: { width: "100%" }, initial: "active", animate: "open", exit: "active", variants: {
            open: { opacity: 1, y: 0, height: "auto" },
            active: { opacity: 0, y: -80, height: 0 }
        }, transition: { ease: [0.57, 0.05, 0.08, 0.96], duration: 0.7 } }, props.children))));
};
exports.AnimatedContainer = AnimatedContainer;
AnimatedContainer.defaultProps = {
    active: false
};
