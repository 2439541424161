import * as React from "react"
import { useGetTranslationsQuery } from "./operations.generated"
import { Translation } from "../@types/graphql.generated"

export interface LocalesContextProviderProps {
	localeCode: string
}
export interface LocalesContextValue extends LocalesContextProviderProps {
	translations: Record<string, string>
}

export const LocalesContext = React.createContext<LocalesContextValue>({
	translations: {},
	localeCode: ""
})

function translationsArrayToRecord<T extends string>(
	translations: Translation[]
): Record<T, string> {
	return translations.reduce(
		(acc: Record<T, string>, translation) => ({
			...acc,
			[translation.key!]: translation.value
		}),
		{} as any
	)
}

export const LocalesContextProvider: React.FC<LocalesContextProviderProps> = props => {
	const translationsQuery = useGetTranslationsQuery()

	return (
		<LocalesContext.Provider
			value={{
				localeCode: props.localeCode,
				translations: translationsQuery.data?.translations
					? translationsArrayToRecord(
							translationsQuery.data.translations as Translation[]
					  )
					: {}
			}}
		>
			{translationsQuery.loading ? "Loading" : props.children}
		</LocalesContext.Provider>
	)
}
