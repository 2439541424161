import React from "react"
import {
	AppContextProviderCommon,
	AppContextProviderCommonProps
} from "@operan/www-common/context/AppContextCommon"

interface AppContextProviderProps extends AppContextProviderCommonProps {}

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
	localesContextProviderProps,
	...rest
}) => {
	return (
		<AppContextProviderCommon
			localesContextProviderProps={localesContextProviderProps}
		>
			{rest.children}
		</AppContextProviderCommon>
	)
}
