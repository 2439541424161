import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type ProfilePageHeroBlockFieldsFragment = (
  { __typename?: 'profilePageHeroBlock' }
  & Pick<Types.ProfilePageHeroBlock, 'id' | 'type' | 'name' | 'title' | 'phone' | 'email' | 'preamble' | 'text'>
  & { image?: Types.Maybe<(
    { __typename?: 'image' }
    & Pick<Types.Image, 'alt' | 'id' | 'placeholder' | 'src'>
  )> }
);

export const ProfilePageHeroBlockFieldsFragmentDoc = gql`
    fragment profilePageHeroBlockFields on profilePageHeroBlock {
  id
  type
  name
  title
  image {
    alt
    id
    placeholder
    src
  }
  phone
  email
  preamble
  text
}
    `;