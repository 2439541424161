import React from "react"
import { Header, Footer } from "@operan/www-client/src/components"
import { Routes } from "./Routes"
import { globalStyles } from "@operan/ui-components"
import { AppShell } from "./AppShell"
import { AppContextProviderCommon } from "../context/AppContextCommon"
import { localeCodeFromUrl } from "@operan/www-common/utils/locale"
import { useLocation } from "react-router"

const GlobalStyles = globalStyles()

export const Layout = () => {
	const location = useLocation()
	const localeCode = localeCodeFromUrl(location.pathname)
	return (
		<AppContextProviderCommon
			localesContextProviderProps={{ localeCode: localeCode }}
		>
			<GlobalStyles />
			<AppShell head={Header} main={Routes} footer={Footer} />
		</AppContextProviderCommon>
	)
}
