"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var styled_1 = require("./styled");
var components_1 = require("../../components");
var ShowHero = React.memo(function (_a) {
    var children = _a.children, locales = _a.locales, media = _a.media, mediaRef = _a.mediaRef, contentRef = _a.contentRef;
    return (React.createElement(styled_1.ShowHeroStyled, null,
        React.createElement(styled_1.ShowHeroFigure, { ref: mediaRef }, media),
        React.createElement(styled_1.ShowHeroContentStyled, { ref: contentRef },
            React.createElement(styled_1.ShowHeroContentInnerStyled, null,
                React.createElement(components_1.Flex, { mb: { _: 4, xxl: 4 }, flexDirection: "column" },
                    locales.tag && (React.createElement(components_1.Text, { as: "span", variant: "tag", mb: 2 }, locales.tag)),
                    locales.name && (React.createElement(components_1.Text, { variant: "headline-3", as: "h1", fontSize: {
                            _: 2.5,
                            l: 3
                        }, lineHeight: {
                            _: 3,
                            l: 4
                        } }, locales.name)),
                    locales.riderAndCreator && (React.createElement(components_1.Text, { as: "span", mb: 0, fontSize: {
                            _: 1.75,
                            xxl: 2
                        }, lineHeight: {
                            _: 2.5,
                            xxl: 2.75
                        }, mt: {
                            _: 0,
                            m: -1
                        } }, locales.riderAndCreator))),
                locales.title && (React.createElement(components_1.Text, { variant: "headline-2", fontSize: {
                        _: 3,
                        l: 4,
                        xxl: 5
                    }, lineHeight: {
                        _: 3.5,
                        l: 5,
                        xxl: 6
                    } }, locales.title))),
            children)));
});
exports.ShowHero = ShowHero;
