"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var useIsomorphicLayoutEffect_1 = require("./useIsomorphicLayoutEffect");
var useDimensions = function (ref, _a) {
    var _b = (_a === void 0 ? {} : _a).liveMeasure, liveMeasure = _b === void 0 ? true : _b;
    var _c = react_1.useState(), dimensions = _c[0], setDimensions = _c[1];
    useIsomorphicLayoutEffect_1.useIsomorphicLayoutEffect(function () {
        if (ref.current) {
            var measure_1 = function () {
                return window.requestAnimationFrame(function () {
                    return setDimensions(ref.current.getBoundingClientRect());
                });
            };
            measure_1();
            if (liveMeasure) {
                window.addEventListener("resize", measure_1);
                return function () {
                    window.removeEventListener("resize", measure_1);
                };
            }
        }
    }, [ref, liveMeasure]);
    return dimensions;
};
exports.useDimensions = useDimensions;
