import * as React from "react"
import { CalendarMonth, CalendarDay } from "../../../@types/graphql.generated"

export interface SeasonCalendarContextValue {
	activeMonth?: CalendarMonth
	setActiveMonth: React.Dispatch<React.SetStateAction<CalendarMonth>>
	activeDay?: CalendarDay
	setActiveDay: React.Dispatch<React.SetStateAction<CalendarDay>>
	activeTag?: string
	setActiveTag: React.Dispatch<React.SetStateAction<string>>
	activeShowName?: string
	setActiveShowName: React.Dispatch<React.SetStateAction<string>>
}

export const SeasonCalendarContext = React.createContext<
	SeasonCalendarContextValue
>({
	activeMonth: null,
	setActiveMonth: CalendarMonth => {},
	activeDay: null,
	setActiveDay: Day => {},
	activeTag: null,
	setActiveTag: string => {},
	activeShowName: null,
	setActiveShowName: string => {}
})

export const SeasonCalendarContextProvider: React.FC = props => {
	const [activeMonth, setActiveMonth] = React.useState<CalendarMonth>(null)
	const [activeDay, setActiveDay] = React.useState<CalendarDay>(null)
	const [activeTag, setActiveTag] = React.useState<string>("")
	const [activeShowName, setActiveShowName] = React.useState<string>("")

	return (
		<SeasonCalendarContext.Provider
			value={{
				activeMonth,
				setActiveMonth,
				activeDay,
				setActiveDay,
				activeTag,
				setActiveTag,
				activeShowName,
				setActiveShowName
			}}
			{...props}
		/>
	)
}
