import * as React from "react"
import {
	Flex,
	Text,
	Accordion,
	CustomLink,
	Submenu,
	Subnav
} from "@operan/ui-components"
import { Menu as IMenu } from "../../@types/graphql.generated"
import { DefaultTheme } from "styled-components"

export const mapSubnavMenuItems = (
	menu: IMenu[],
	path: string,
	themeContext: DefaultTheme,
	locales?: Record<string, string>,
	mobileView?: boolean
) =>
	menu.map((item, i) => {
		const hasActiveChild =
			item.children && item.children.find(item => item.url === path)
				? true
				: false

		return item.children ? (
			<Accordion key={item.id} activeId={hasActiveChild ? item.url : ""}>
				<Accordion.Header
					id={mobileView ? `submenu-${item.url}-mobile` : `submenu-${item.url}`}
					noBorder={true}
					toggleLabel={locales.submenuAccordionToggleLabel.replace(
						/%s/g,
						item.title
					)}
				>
					<CustomLink
						to={item.url}
						label={item.title}
						withChevron={true}
						active={path === item.url}
					/>
				</Accordion.Header>
				<Accordion.Panel
					id={mobileView ? `submenu-${item.url}-mobile` : `submenu-${item.url}`}
				>
					<Submenu>
						<Submenu.List>
							{item.children?.map((link, idx) => (
								<Submenu.Item active={path === link.url} key={idx}>
									<Submenu.Link to={link.url}>{link.title}</Submenu.Link>
								</Submenu.Item>
							))}
						</Submenu.List>
					</Submenu>
				</Accordion.Panel>
			</Accordion>
		) : (
			<Flex key={item.id}>
				{/* Need to display link with/without border depending on mobile/desktop */}
				<Flex w={1} py={2.5} pl={5} display={{ _: "block", l: "none" }}>
					<CustomLink
						active={path === item.url}
						to={item.url}
						label={item.title}
						withChevron={true}
					/>
				</Flex>
				<Flex
					w={1}
					py={2.5}
					pl={2}
					display={{ _: "none", l: "block" }}
					style={{
						borderBottom: i + 1 === menu.length ? `none` : `1px solid`,
						borderColor: themeContext.dark
							? `rgba(255, 255, 255, 0.2)`
							: `rgba(0, 0, 0, 0.2)`
					}}
				>
					<CustomLink
						active={path === item.url}
						to={item.url}
						label={item.title}
						withChevron={true}
					/>
				</Flex>
			</Flex>
		)
	})

export const renderSubnav = (
	subnav: IMenu,
	path: string,
	themeContext: DefaultTheme,
	locales?: Record<string, string>
) => {
	return (
		<Subnav>
			<Flex display={{ _: "block", l: "none" }}>
				<Accordion key={subnav.url}>
					<Accordion.Header
						id={`${subnav.url}-mobile`}
						toggleLabel={locales.submenuAccordionToggleLabel.replace(
							/%s/g,
							subnav.title
						)}
					>
						<Flex pl="1">
							<Text
								variant="paragraph-1"
								fontSize={2.5}
								fontWeight="semibold"
								mb={0}
							>
								{subnav.title}
							</Text>
						</Flex>
					</Accordion.Header>
					<Accordion.Panel id={`${subnav.url}-mobile`}>
						{mapSubnavMenuItems(
							subnav.children,
							path,
							themeContext,
							locales,
							true
						)}
					</Accordion.Panel>
				</Accordion>
			</Flex>
			<Flex display={{ _: "none", l: "block" }}>
				{mapSubnavMenuItems(subnav.children, path, themeContext, locales)}
			</Flex>
		</Subnav>
	)
}
