import * as React from "react"
import {
	Flex,
	HorizontalScroller,
	Tabs,
	MultipleShowsTab
} from "@operan/ui-components"
import { qBankImageSrc } from "../../utils/assetPaths"
import { MultipleShowsTab as IMultipleShowsTab } from "../../@types/graphql.generated"
import { sendMatomoEvent } from "../../utils"

export const mapMultipleShowsTabs = (
	multipleShowsTabs: IMultipleShowsTab[],
	setActiveTab: (id: string) => void
) => {
	const useFullTabs = multipleShowsTabs.length < 5

	return (
		<HorizontalScroller flexDirection="column" showFade={false}>
			{multipleShowsTabs.map((tab, i) => (
				<Flex
					key={tab.title}
					flexDirection="column"
					w={1}
					flex={
						useFullTabs
							? {
									s: "0 0 25%"
							  }
							: {}
					}
					px={{ _: 1, xl: 1.5 }}
				>
					<Tabs.Tab
						id={tab.title}
						key={i}
						style={{ height: "100%" }}
						onClick={() => {
							setActiveTab(tab.title)
							trackMultipleShowsTabClick(tab.title)
						}}
						variant="ghost"
					>
						<MultipleShowsTab
							image={
								useFullTabs && tab.image ? (
									<img
										src={qBankImageSrc(tab.image.src, "768")}
										alt={tab.image.alt || ""}
									/>
								) : null
							}
						>
							<MultipleShowsTab.Title>{tab.title}</MultipleShowsTab.Title>
							{useFullTabs && tab.subTitle && (
								<MultipleShowsTab.Subtitle>
									{tab.subTitle}
								</MultipleShowsTab.Subtitle>
							)}
						</MultipleShowsTab>
					</Tabs.Tab>
				</Flex>
			))}
		</HorizontalScroller>
	)
}

/**
 *   Push Matomo event for click on multiple shows tab
 */
export const trackMultipleShowsTabClick = (title: string) => {
	sendMatomoEvent({
		event: "Multiple show tabs",
		analyticsInfo: title,
		clickTarget: window && window.location.pathname
	})
}
