import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type PreviewBlockFieldsFragment = (
  { __typename?: 'previewBlock' }
  & Pick<Types.PreviewBlock, 'id' | 'type' | 'heading'>
);

export const PreviewBlockFieldsFragmentDoc = gql`
    fragment previewBlockFields on previewBlock {
  id
  type
  heading
}
    `;