import * as React from "react"
import {
	Flex,
	INNER_CONTAINER_MAX_WIDTH,
	Rte,
	VideoPlayer,
	mergeColorTheme,
	Text,
	fontFamilyBody,
	BlockAlert,
	Icon
} from "@operan/ui-components"
import {
	BlockType,
	VideoPageIntroBlock as IVideoPageIntroBlock
} from "../../@types/graphql.generated"
import { BlockFullWidthLayout, BlockTitlePreamble } from "../../utils/layout"
import { trackVideoInteraction } from "../../utils"
import { useLocation } from "react-router"
import { ThemeProvider } from "styled-components"
import { localeCodeFromUrl } from "@operan/www-common/utils/locale"
import { formatDateSpan } from "../../utils/dateFormatter"
import { translationNamespace, useLocales } from "../../hooks"

function createMarkup(markup: string) {
	return { __html: markup }
}

const VideoPageIntroBlock: React.FC<IVideoPageIntroBlock> = props => {
	const location = useLocation();
	const [isPlaying, setIsPlaying] = React.useState(false);
	const [progressEventSent, setProgressEventSent] = React.useState(false);
	const localeCode = localeCodeFromUrl(location.pathname);
	const locales = useLocales(
		translationNamespace.block(BlockType.VideoPageBlock),
		["currentlyNotPlaying", "notAvailable"]
	);
	
	const startDate = props.fromDate && new Date(props.fromDate);
	const endDate = props.toDate && new Date(props.toDate);
	const description = (startDate && endDate) && 
		`${formatDateSpan(startDate, endDate, localeCode)}`;

	return (
		<ThemeProvider theme={mergeColorTheme(props.theme)}>
			<BlockFullWidthLayout>
				<Flex
					px={{ _: 0, l: 10 }}
					w={{ _: "100%" }}
					maxw={INNER_CONTAINER_MAX_WIDTH}
					alignItems="center"
					flexDirection="column"
				>
					{props.videoURL != null && props.videoDetails != null ? (
							<VideoPlayer
								url={props.videoURL}
								loadWhenInView={true}
								onStart={() => {
									trackVideoInteraction("Play", location.pathname);
									setIsPlaying(true);
								}}
								onProgress={({ played }) => {
									if (
										isPlaying &&
										!progressEventSent &&
										parseFloat(played.toFixed(1)) === 0.5
									) {
										trackVideoInteraction("50% progress", location.pathname);
										setProgressEventSent(true);
									}
								}}
								onEnded={() => {
									trackVideoInteraction("Complete", location.pathname);
									setIsPlaying(false);
								}}
							/>
						) : props.videoDetails != null ? (
							<img width="100%" alt={props.title} src={props.videoDetails.videoThumbnail} />
						) : (<Flex
								w="100%"
								pt="56.25%"
								backgroundColor={props.theme.dark ? 
									"grey-light" : 
									"grey-dark"
								}
							></Flex>
						)
					}
					
					<Flex
						mt={{ _: 2 }}
						maxw={INNER_CONTAINER_MAX_WIDTH}
					>
						{props.videoDetails == null || props.videoURL == null  ? (
								<BlockAlert>
									<BlockAlert.Icon>
										<Icon
											mt={{ _: -0.375, l: -1.25 }}
											icon="red_alert"
											color={props.theme.dark ? "white" : "black"}
											size={{ _: 1.75, l: 3.5 }}
										/>
									</BlockAlert.Icon>
									<Flex textColor={props.theme.dark ? "white" : "black"}>
										{props.videoDetails ? locales.currentlyNotPlaying : locales.notAvailable}
									</Flex>
								</BlockAlert>
							) : (<></>)
						}
					</Flex>
					<Text
						as="span"
						display="block"
						textAlign="center"
						variant="tag"
						pt={3}
						pb={{ _: 0, m: 5 }}
						fontFamily={fontFamilyBody}
						fontSize={1.5}
						lineHeight={2.25}
					>
						{description}
					</Text>
					{props.title || props.preamble ? (
						<Flex pt={{ _: description ? 0 : 5 }}>
							<BlockTitlePreamble
								title={props.title}
								preamble={props.preamble || null}
							/>
						</Flex>
					) : null}
					<Flex w={1} flexDirection="column" px={{ _: 5, m: 0 }}>
						<Rte>
							{props.text && (
								<div dangerouslySetInnerHTML={createMarkup(props.text)} />
							)}
						</Rte>
					</Flex>
				</Flex>
			</BlockFullWidthLayout>
		</ThemeProvider>
	);
}

export { VideoPageIntroBlock }
