"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
Object.defineProperty(exports, "__esModule", { value: true });
var styled_components_1 = require("styled-components");
exports.scalePrevBtnKeyframes = styled_components_1.keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    0% {\n        transform: translateY(-50%) translateX(0) scaleX(1);\n    }\n\n    30% {\n        transform: translateY(-50%) translateX(-12px) scaleX(0);\n    }\n\n    60% {\n        transform: translateY(-50%) translateX(40px) scaleX(0);\n    }\n\n    80% {\n        transform: translateY(-50%) translateX(0) scaleX(1);\n    }\n"], ["\n    0% {\n        transform: translateY(-50%) translateX(0) scaleX(1);\n    }\n\n    30% {\n        transform: translateY(-50%) translateX(-12px) scaleX(0);\n    }\n\n    60% {\n        transform: translateY(-50%) translateX(40px) scaleX(0);\n    }\n\n    80% {\n        transform: translateY(-50%) translateX(0) scaleX(1);\n    }\n"])));
exports.movePrevBtnArrowKeyframes = styled_components_1.keyframes(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    0% {\n        transform: translateX(0px);\n        opacity: 1;\n    }\n\n    5% {\n        transform: translateX(0px);\n        opacity: 1;\n    }\n\n    30% {\n        transform: translateX(-24px);\n        opacity: 0;\n    }\n\n    60% {\n        transform: translateX(80px);\n        opacity: 0;\n    }\n\n    100% {\n        transform: translateX(0);\n        opacity: 1;\n    }\n"], ["\n    0% {\n        transform: translateX(0px);\n        opacity: 1;\n    }\n\n    5% {\n        transform: translateX(0px);\n        opacity: 1;\n    }\n\n    30% {\n        transform: translateX(-24px);\n        opacity: 0;\n    }\n\n    60% {\n        transform: translateX(80px);\n        opacity: 0;\n    }\n\n    100% {\n        transform: translateX(0);\n        opacity: 1;\n    }\n"])));
exports.scaleNextBtnKeyframes = styled_components_1.keyframes(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    0% {\n        transform: translateY(-50%) translateX(0) scaleX(1);\n    }\n\n    30% {\n        transform: translateY(-50%) translateX(12px) scaleX(0);\n    }\n\n    60% {\n        transform: translateY(-50%) translateX(-40px) scaleX(0);\n    }\n\n    80% {\n        transform: translateY(-50%) translateX(0) scaleX(1);\n    }\n"], ["\n    0% {\n        transform: translateY(-50%) translateX(0) scaleX(1);\n    }\n\n    30% {\n        transform: translateY(-50%) translateX(12px) scaleX(0);\n    }\n\n    60% {\n        transform: translateY(-50%) translateX(-40px) scaleX(0);\n    }\n\n    80% {\n        transform: translateY(-50%) translateX(0) scaleX(1);\n    }\n"])));
exports.moveNextBtnArrowKeyframes = styled_components_1.keyframes(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    0% {\n        transform: translateX(0px);\n        opacity: 1;\n    }\n\n    5% {\n        transform: translateX(0px);\n        opacity: 1;\n    }\n\n    30% {\n        transform: translateX(24px);\n        opacity: 0;\n    }\n\n    60% {\n        transform: translateX(-80px);\n        opacity: 0;\n    }\n\n    100% {\n        transform: translateX(0);\n        opacity: 1;\n    }\n"], ["\n    0% {\n        transform: translateX(0px);\n        opacity: 1;\n    }\n\n    5% {\n        transform: translateX(0px);\n        opacity: 1;\n    }\n\n    30% {\n        transform: translateX(24px);\n        opacity: 0;\n    }\n\n    60% {\n        transform: translateX(-80px);\n        opacity: 0;\n    }\n\n    100% {\n        transform: translateX(0);\n        opacity: 1;\n    }\n"])));
exports.scalePrevBtn = styled_components_1.css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\tanimation-name: ", ";\n\tanimation-timing-function: cubic-bezier(0.55, 0.003, 0.164, 0.991);\n\tanimation-duration: 1s;\n\tanimation-delay: 0.1s;\n\tanimation-fill-mode: forwards;\n"], ["\n\tanimation-name: ", ";\n\tanimation-timing-function: cubic-bezier(0.55, 0.003, 0.164, 0.991);\n\tanimation-duration: 1s;\n\tanimation-delay: 0.1s;\n\tanimation-fill-mode: forwards;\n"])), exports.scalePrevBtnKeyframes);
exports.movePrevBtnArrow = styled_components_1.css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\tanimation-name: ", ";\n\tanimation-timing-function: cubic-bezier(0.55, 0.003, 0.164, 0.991);\n\tanimation-duration: 1s;\n\tanimation-fill-mode: forwards;\n"], ["\n\tanimation-name: ", ";\n\tanimation-timing-function: cubic-bezier(0.55, 0.003, 0.164, 0.991);\n\tanimation-duration: 1s;\n\tanimation-fill-mode: forwards;\n"])), exports.movePrevBtnArrowKeyframes);
exports.scaleNextBtn = styled_components_1.css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\tanimation-name: ", ";\n\tanimation-timing-function: cubic-bezier(0.55, 0.003, 0.164, 0.991);\n\tanimation-duration: 1s;\n\tanimation-delay: 0.1s;\n\tanimation-fill-mode: forwards;\n"], ["\n\tanimation-name: ", ";\n\tanimation-timing-function: cubic-bezier(0.55, 0.003, 0.164, 0.991);\n\tanimation-duration: 1s;\n\tanimation-delay: 0.1s;\n\tanimation-fill-mode: forwards;\n"])), exports.scaleNextBtnKeyframes);
exports.moveNextBtnArrow = styled_components_1.css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n\tanimation-name: ", ";\n\tanimation-timing-function: cubic-bezier(0.55, 0.003, 0.164, 0.991);\n\tanimation-duration: 1s;\n\tanimation-fill-mode: forwards;\n"], ["\n\tanimation-name: ", ";\n\tanimation-timing-function: cubic-bezier(0.55, 0.003, 0.164, 0.991);\n\tanimation-duration: 1s;\n\tanimation-fill-mode: forwards;\n"])), exports.moveNextBtnArrowKeyframes);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
