"use strict";
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var styled_components_1 = __importStar(require("styled-components"));
var styles_1 = require("../../styles");
var styled_1 = require("../Accordion/styled");
exports.InfoBoxStyled = styled_components_1.default.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tposition: relative;\n\tpadding: ", ";\n\tbackground-color: ", ";\n\twidth: 100%;\n\tz-index: 1;\n\n\t", ";\n"], ["\n\tposition: relative;\n\tpadding: ", ";\n\tbackground-color: ",
    ";\n\twidth: 100%;\n\tz-index: 1;\n\n\t",
    ";\n"])), styles_1.scale.px(3), function (_a) {
    var theme = _a.theme;
    return theme.dark ? "" + styles_1.colors["white"] : "" + styles_1.colors["black"];
}, styles_1.breakpoint("l")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n\t\tpadding: ", ";\n\t\tmax-width: ", ";\n    "], ["\n\t\tpadding: ", ";\n\t\tmax-width: ", ";\n    "])), styles_1.scale.px(4), styles_1.scale.px(60)));
exports.InfoBoxList = styled_components_1.default.ul(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n\tmargin: 0;\n\tpadding: 0;\n\tlist-style-type: none;\n"], ["\n\tmargin: 0;\n\tpadding: 0;\n\tlist-style-type: none;\n"])));
exports.InfoBoxListItem = styled_components_1.default.li(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n\tpadding: ", " 0;\n\tborder-bottom: 1px solid ", ";\n\tcolor: ", ";\n\n\t&:first-of-type {\n\t\tpadding-top: 0;\n\t}\n\n\tp {\n\t\tmargin-bottom: 0;\n\t}\n\n\t/* Accordion specific styling */\n\t", " {\n\t\tpadding: 0;\n\t\theight: auto;\n\t\tborder-bottom: none;\n\t}\n\n\t", " {\n\t\tpadding: ", " 0;\n\t\tcolor: ", ";\n\t\tfont-size: ", ";\n\t\tline-height: ", ";\n\t}\n\n\t", " {\n\t\tborder-left: none;\n\n\t\t&:before,\n\t\t&:after {\n\t\t\tbackground-color: ", ";\n\t\t}\n\t}\n"], ["\n\tpadding: ", " 0;\n\tborder-bottom: 1px solid ", ";\n\tcolor: ",
    ";\n\n\t&:first-of-type {\n\t\tpadding-top: 0;\n\t}\n\n\tp {\n\t\tmargin-bottom: 0;\n\t}\n\n\t/* Accordion specific styling */\n\t", " {\n\t\tpadding: 0;\n\t\theight: auto;\n\t\tborder-bottom: none;\n\t}\n\n\t", " {\n\t\tpadding: ", " 0;\n\t\tcolor: ",
    ";\n\t\tfont-size: ", ";\n\t\tline-height: ", ";\n\t}\n\n\t", " {\n\t\tborder-left: none;\n\n\t\t&:before,\n\t\t&:after {\n\t\t\tbackground-color: ",
    ";\n\t\t}\n\t}\n"])), styles_1.scale.px(1.5), styles_1.colors["greybrown-dark"], function (_a) {
    var theme = _a.theme;
    return theme.dark ? "" + styles_1.colors["black"] : "" + styles_1.colors["black"];
}, styled_1.HeaderStyled, styled_1.PanelInnerStyled, styles_1.scale.px(1), function (_a) {
    var theme = _a.theme;
    return theme.dark ? "" + styles_1.colors["black"] : "" + styles_1.colors["white"];
}, styles_1.scale.px(2), styles_1.scale.px(3), styled_1.ToggleStyled, function (_a) {
    var theme = _a.theme;
    return theme.dark ? styles_1.colors["black-light"] : styles_1.colors["white"];
});
exports.InfoBoxListItemIcon = styled_components_1.default.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n\twidth: ", ";\n\theight: ", ";\n\tborder-radius: 50%;\n\tbackground-color: ", ";\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tmargin-left: ", ";\n\tmargin-top: ", ";\n\n\t", "\n"], ["\n\twidth: ", ";\n\theight: ", ";\n\tborder-radius: 50%;\n\tbackground-color: ",
    ";\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\tmargin-left: ", ";\n\tmargin-top: ", ";\n\n\t",
    "\n"])), styles_1.scale.px(4), styles_1.scale.px(4), function (_a) {
    var theme = _a.theme;
    return theme.dark ? "rgba(0, 0, 0, 0.12)" : "rgba(255, 255, 255, 0.12)";
}, styles_1.scale.px(1), styles_1.scale.px(-0.5), styles_1.breakpoint("m")(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n\t\twidth: ", ";\n    \theight: ", ";\n\t"], ["\n\t\twidth: ", ";\n    \theight: ", ";\n\t"])), styles_1.scale.px(5), styles_1.scale.px(5)));
exports.infoBoxCtaStyles = styled_components_1.css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n\tposition: relative;\n\tdisplay: inline-block;\n\tpadding: ", ";\n\ttext-decoration: none;\n\ttext-align: center;\n\twidth: auto;\n\tfont-size: ", ";\n\tletter-spacing: 1px;\n\tfont-weight: ", ";\n\ttext-transform: uppercase;\n\tbackground-color: ", ";\n\tcolor: ", ";\n\tvertical-align: middle;\n\tborder: 1px solid transparent;\n\tborder-radius: ", ";\n\tmargin-top: ", ";\n\twidth: 100%;\n\n\t", "\n\n\t", "\n"], ["\n\tposition: relative;\n\tdisplay: inline-block;\n\tpadding: ", ";\n\ttext-decoration: none;\n\ttext-align: center;\n\twidth: auto;\n\tfont-size: ", ";\n\tletter-spacing: 1px;\n\tfont-weight: ", ";\n\ttext-transform: uppercase;\n\tbackground-color: ", ";\n\tcolor: ", ";\n\tvertical-align: middle;\n\tborder: 1px solid transparent;\n\tborder-radius: ", ";\n\tmargin-top: ", ";\n\twidth: 100%;\n\n\t",
    "\n\n\t",
    "\n"])), styles_1.scale.px(2), styles_1.scale.px(2), styles_1.fontWeight.semibold, styles_1.colors["gold"], styles_1.colors["black-light"], styles_1.scale.px(0.5), styles_1.scale.px(2.5), styles_1.breakpoint("m")(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n\t\tpadding: ", ";\n\t"], ["\n\t\tpadding: ", ";\n\t"])), styles_1.scale.px(3)), styles_1.breakpoint("xxl")(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n\t\tmargin-top: ", ";\n\t"], ["\n\t\tmargin-top: ", ";\n\t"])), styles_1.scale.px(3)));
var InfoBox = function (props) { return (React.createElement(exports.InfoBoxStyled, __assign({ ref: props.innerRef }, props))); };
exports.InfoBox = InfoBox;
InfoBox.List = exports.InfoBoxList;
InfoBox.Item = exports.InfoBoxListItem;
InfoBox.ItemIcon = exports.InfoBoxListItemIcon;
var InfoBoxCtaStyled = styled_components_1.default.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), exports.infoBoxCtaStyles);
InfoBox.Cta = function (_a) {
    var label = _a.label;
    return React.createElement(InfoBoxCtaStyled, null, label);
};
var InfoBoxCTALinkStyled = styled_components_1.default(react_router_dom_1.Link)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), exports.infoBoxCtaStyles);
InfoBox.CtaLink = function (_a) {
    var label = _a.label, rest = __rest(_a, ["label"]);
    return (React.createElement(InfoBoxCTALinkStyled, __assign({}, rest), label));
};
var InfoBoxCtaExternalLinkStyled = styled_components_1.default.a(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n\t", "\n"], ["\n\t", "\n"])), exports.infoBoxCtaStyles);
InfoBox.CtaExternalLink = function (_a) {
    var label = _a.label, as = _a.as, ref = _a.ref, rest = __rest(_a, ["label", "as", "ref"]);
    return (React.createElement(InfoBoxCtaExternalLinkStyled, __assign({}, rest), label));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
