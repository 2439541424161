"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getFocusableElements = function (containerElement) {
    var focusableElementSelectors = "input:enabled:not([type='hidden']), select:enabled, a[href], textarea:enabled, button:enabled, [tabindex]";
    var nodeList = containerElement.querySelectorAll(focusableElementSelectors);
    var focusableElements = [];
    for (var i = 0; i < nodeList.length; i++) {
        focusableElements.push(nodeList[i]);
    }
    return focusableElements;
};
exports.default = getFocusableElements;
