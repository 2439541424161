import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type LandingPageHeroBlockFieldsFragment = (
  { __typename?: 'landingPageHeroBlock' }
  & Pick<Types.LandingPageHeroBlock, 'id' | 'type' | 'videoUrl' | 'title' | 'preamble' | 'themeColorVariant'>
  & { image?: Types.Maybe<(
    { __typename?: 'image' }
    & Pick<Types.Image, 'src' | 'alt'>
  )>, theme?: Types.Maybe<(
    { __typename?: 'theme' }
    & Pick<Types.Theme, 'dark' | 'complementaryColor' | 'primaryColor' | 'textColor'>
  )> }
);

export const LandingPageHeroBlockFieldsFragmentDoc = gql`
    fragment landingPageHeroBlockFields on landingPageHeroBlock {
  id
  type
  image {
    src
    alt
  }
  videoUrl
  title
  preamble
  themeColorVariant
  theme {
    dark
    complementaryColor
    primaryColor
    textColor
  }
}
    `;