import * as React from "react"
import { Flex, Text } from "@operan/ui-components"
import { PreviewBlock as IPreviewBlock } from "../../@types/graphql.generated"

const PreviewBlock: React.FC<IPreviewBlock> = props => {
	return (
		<Flex
			w={1}
			backgroundColor="blue-light"
			py={{ _: 1, m: 2.5 }}
			px={2}
			justifyContent="center"
		>
			<Text
				textColor="blue-dark"
				fontSize={{ _: 2, m: 3 }}
				lineHeight={{ _: 3, m: 4 }}
				fontWeight="bold"
				mb={0}
			>
				{props.heading}
			</Text>
		</Flex>
	)
}

export { PreviewBlock }
