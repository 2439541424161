export type LocaleSlug = "en"
export type LocaleCode = "en-gb" | "sv-se"

/**
 * Returns a locale code based on a given URL's slug/sub-page, from a fixed list of possible locales.
 * I.e. the url http://website.com/en/sub-page will return "en-gb" for the en sub-directory.
 * If no matching locale is found the default locale "sv-se" is returned.
 */
export const localeCodeFromUrl = (url: string): LocaleCode => {
	let localeCode: LocaleCode = "sv-se"
	const localeCodeMap: Record<LocaleSlug, LocaleCode> = {
		en: "en-gb"
	}

	Object.keys(localeCodeMap).some(localeSlug => {
		const match = new RegExp(`/${localeSlug}($|/)`).test(url)
		localeCode = match ? localeCodeMap[localeSlug] : localeCode

		return match
	})

	return localeCode
}
