import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type SlideshowBlockFieldsFragment = (
  { __typename?: 'slideshowBlock' }
  & Pick<Types.SlideshowBlock, 'id' | 'type' | 'themeColorVariant' | 'title' | 'preamble'>
  & { theme?: Types.Maybe<(
    { __typename?: 'theme' }
    & Pick<Types.Theme, 'textColor' | 'primaryColor' | 'complementaryColor' | 'dark'>
  )>, slideshowItems?: Types.Maybe<Array<(
    { __typename?: 'slideshowItem' }
    & Pick<Types.SlideshowItem, 'caption' | 'captionLabel' | 'credit' | 'creditLabel'>
    & { image?: Types.Maybe<(
      { __typename?: 'image' }
      & Pick<Types.Image, 'alt' | 'id' | 'placeholder' | 'src'>
    )> }
  )>> }
);

export const SlideshowBlockFieldsFragmentDoc = gql`
    fragment slideshowBlockFields on slideshowBlock {
  id
  type
  themeColorVariant
  theme {
    textColor
    primaryColor
    complementaryColor
    dark
  }
  title
  preamble
  slideshowItems: items {
    caption
    captionLabel
    credit
    creditLabel
    image {
      alt
      id
      placeholder
      src
    }
  }
}
    `;