import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type IFrameBlockFieldsFragment = (
  { __typename?: 'iFrameBlock' }
  & Pick<Types.IFrameBlock, 'type' | 'id' | 'themeColorVariant' | 'title' | 'preamble' | 'height' | 'src'>
  & { theme?: Types.Maybe<(
    { __typename?: 'theme' }
    & Pick<Types.Theme, 'primaryColor' | 'complementaryColor' | 'textColor' | 'dark'>
  )> }
);

export const IFrameBlockFieldsFragmentDoc = gql`
    fragment iFrameBlockFields on iFrameBlock {
  type
  id
  theme {
    primaryColor
    complementaryColor
    textColor
    dark
  }
  themeColorVariant
  title
  preamble
  height
  src
}
    `;