"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var styled_1 = require("./styled");
exports.MenuContext = React.createContext({
    open: false
});
var Menu = function (props) { return (React.createElement(exports.MenuContext.Provider, { value: { open: props.open } },
    React.createElement(styled_1.MenuStyled, __assign({}, props)))); };
exports.Menu = Menu;
Menu.List = styled_1.ListStyled;
var Item = function (props) {
    var open = React.useContext(exports.MenuContext).open;
    return React.createElement(styled_1.ListItemStyled, __assign({ open: open }, props));
};
Menu.Item = Item;
Menu.Link = styled_1.LinkStyled;
