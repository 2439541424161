export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  Date: any;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: any;
  /** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTimeOffset: any;
  /** The `Seconds` scalar type represents a period of time represented as the total number of seconds. */
  Seconds: any;
  /** The `Milliseconds` scalar type represents a period of time represented as the total number of milliseconds. */
  Milliseconds: any;
  Decimal: any;
};







export type Query = {
  __typename?: 'query';
  arenaPlanSeats?: Maybe<Array<Maybe<Seat>>>;
  footer?: Maybe<FooterBlock>;
  getCommonSearchResults?: Maybe<CommonSearchResult>;
  getOrder?: Maybe<CheckoutUrl>;
  getProductsByCategory?: Maybe<Array<Maybe<ProductCategory>>>;
  languageSwitch?: Maybe<LanguageSwitchResult>;
  menu?: Maybe<Array<Maybe<Menu>>>;
  page?: Maybe<Page>;
  search?: Maybe<Search>;
  ticketZones?: Maybe<SeatPlanner>;
  translations?: Maybe<Array<Maybe<Translation>>>;
};


export type QueryArenaPlanSeatsArgs = {
  eventId: Scalars['Int'];
};


export type QueryFooterArgs = {
  path?: Maybe<Scalars['String']>;
};


export type QueryGetOrderArgs = {
  orderId: Scalars['String'];
};


export type QueryGetProductsByCategoryArgs = {
  path: Scalars['String'];
  eventId: Scalars['Int'];
  eventGroupId: Scalars['Int'];
};


export type QueryLanguageSwitchArgs = {
  path: Scalars['String'];
  culture: Scalars['String'];
};


export type QueryMenuArgs = {
  type?: Maybe<MenuType>;
  path?: Maybe<Scalars['String']>;
};


export type QueryPageArgs = {
  path: Scalars['String'];
  previewContentId?: Maybe<Scalars['Int']>;
  previewToken?: Maybe<Scalars['String']>;
};


export type QuerySearchArgs = {
  searchInput: Scalars['String'];
  pageInput: PaginationInput;
};


export type QueryTicketZonesArgs = {
  eventId: Scalars['Int'];
  ticketTypeIds: Array<Maybe<Scalars['Int']>>;
};

export type Page = {
  __typename?: 'page';
  blocks?: Maybe<Array<Maybe<Blocks>>>;
  httpStatusCode: StatusCode;
  id: Scalars['Int'];
  meta?: Maybe<Meta>;
  pageHierarchy?: Maybe<Array<Maybe<PageHierarchy>>>;
  redirectUrl?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  type: Scalars['String'];
};

export enum StatusCode {
  Ok = 'OK',
  Permanentredirect = 'PERMANENTREDIRECT',
  Notfound = 'NOTFOUND'
}

export type PageHierarchy = {
  __typename?: 'pageHierarchy';
  id: Scalars['Int'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export type Meta = {
  __typename?: 'meta';
  description?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Blocks = ShowHeroBlock | ArticleRteBlock | PageIntroBlock | RelatedPagesBlock | AccordionBlock | LandingPageHeroBlock | MediaContentBlock | ProgramListingBlock | PageGridBlock | ThreeColumnPromoBlock | BreadcrumbsBlock | SeasonCalendarBlock | ProfilePageHeroBlock | SearchBlock | ProfileListingBlock | HeroBlock | MultipleShowsBlock | ShowIntroBlock | RteBlock | VideoBlock | SlideshowBlock | IFrameBlock | ParticipantsBlock | AlertBlock | PerformancePastBlock | EcomBlock | PreviewBlock | TabsBlock | ShowAlertBlock | VideoGridBlock | VideoPageIntroBlock | NewsletterBlock | UnknownBlock;

/** Field used for SearchService only */
export type ShowHeroBlock = {
  __typename?: 'showHeroBlock';
  cta?: Maybe<CtaUnionGraphType>;
  /** Field used for SearchService only */
  ctaTitle?: Maybe<Scalars['String']>;
  editorsPick?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  info?: Maybe<Array<Maybe<KeyValueGraphType>>>;
  name?: Maybe<Scalars['String']>;
  riderAndCreator?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
  videoUrl?: Maybe<Scalars['String']>;
};

export enum ThemeColorVariant {
  Primary = 'PRIMARY',
  Complementary = 'COMPLEMENTARY'
}

export enum BlockType {
  Unknown = 'UNKNOWN',
  RteBlock = 'RTE_BLOCK',
  PageIntroBlock = 'PAGE_INTRO_BLOCK',
  ShowHeroBlock = 'SHOW_HERO_BLOCK',
  ShowIntroBlock = 'SHOW_INTRO_BLOCK',
  RelatedPagesBlock = 'RELATED_PAGES_BLOCK',
  VideoBlock = 'VIDEO_BLOCK',
  ArticleRteBlock = 'ARTICLE_RTE_BLOCK',
  AccordionBlock = 'ACCORDION_BLOCK',
  LandingPageHeroBlock = 'LANDING_PAGE_HERO_BLOCK',
  MediaContentBlock = 'MEDIA_CONTENT_BLOCK',
  PageGridBlock = 'PAGE_GRID_BLOCK',
  ProgramListingBlock = 'PROGRAM_LISTING_BLOCK',
  ThreeColumnPromoBlock = 'THREE_COLUMN_PROMO_BLOCK',
  BreadcrumbsBlock = 'BREADCRUMBS_BLOCK',
  SlideshowBlock = 'SLIDESHOW_BLOCK',
  SearchBlock = 'SEARCH_BLOCK',
  SeasonCalendarBlock = 'SEASON_CALENDAR_BLOCK',
  ProfilePageHeroBlock = 'PROFILE_PAGE_HERO_BLOCK',
  ProfileListingBlock = 'PROFILE_LISTING_BLOCK',
  HeroBlock = 'HERO_BLOCK',
  FooterBlock = 'FOOTER_BLOCK',
  MultipleShowsBlock = 'MULTIPLE_SHOWS_BLOCK',
  IframeBlock = 'IFRAME_BLOCK',
  ParticipantsBlock = 'PARTICIPANTS_BLOCK',
  AlertBlock = 'ALERT_BLOCK',
  EcomBlock = 'ECOM_BLOCK',
  PerformancePastBlock = 'PERFORMANCE_PAST_BLOCK',
  PreviewBlock = 'PREVIEW_BLOCK',
  ShowAlertBlock = 'SHOW_ALERT_BLOCK',
  ProgrammeBlock = 'PROGRAMME_BLOCK',
  TabsBlock = 'TABS_BLOCK',
  VideoPageBlock = 'VIDEO_PAGE_BLOCK',
  VideoGridBlock = 'VIDEO_GRID_BLOCK',
  NewsletterBlock = 'NEWSLETTER_BLOCK'
}

export type Image = {
  __typename?: 'image';
  alt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  participants?: Maybe<Scalars['String']>;
  photographer?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  src?: Maybe<Scalars['String']>;
};

export type KeyValueGraphType = {
  __typename?: 'KeyValueGraphType';
  anchorLinkId?: Maybe<BlockType>;
  asAccordion?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<IconType>;
  label?: Maybe<Scalars['String']>;
  link?: Maybe<Link>;
  text?: Maybe<Scalars['String']>;
};

export type Link = {
  __typename?: 'link';
  alt: Scalars['String'];
  target: LinkTarget;
  title: Scalars['String'];
  url: Scalars['String'];
};

export enum LinkTarget {
  Self = 'SELF',
  Blank = 'BLANK',
  NoLink = 'NO_LINK'
}

export enum IconType {
  BookOpen = 'BOOK_OPEN',
  Image = 'IMAGE',
  PersonSpotlight = 'PERSON_SPOTLIGHT',
  Podcast = 'PODCAST',
  TheaterMasks = 'THEATER_MASKS',
  Calendar = 'CALENDAR',
  Camera = 'CAMERA',
  Headphones = 'HEADPHONES',
  Video = 'VIDEO'
}

export type CtaUnionGraphType = Link | StringBox;

export type StringBox = {
  __typename?: 'stringBox';
  value?: Maybe<Scalars['String']>;
};

export type ArticleRteBlock = {
  __typename?: 'articleRteBlock';
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  type: BlockType;
};

export type Theme = {
  __typename?: 'theme';
  complementaryColor?: Maybe<Scalars['String']>;
  dark: Scalars['Boolean'];
  primaryColor?: Maybe<Scalars['String']>;
  textColor?: Maybe<Scalars['String']>;
};

export type PageIntroBlock = {
  __typename?: 'pageIntroBlock';
  campaignArea?: Maybe<CampaignArea>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  menu?: Maybe<Menu>;
  preamble?: Maybe<Scalars['String']>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type Menu = {
  __typename?: 'menu';
  children?: Maybe<Array<Maybe<Menu>>>;
  id?: Maybe<Scalars['String']>;
  /** @deprecated Not used for now */
  isExternalLink?: Maybe<Scalars['Boolean']>;
  linkType?: Maybe<LinkType>;
  pageType: Scalars['String'];
  title: Scalars['String'];
  url: Scalars['String'];
};

export enum LinkType {
  StandardPage = 'STANDARD_PAGE',
  TicketPage = 'TICKET_PAGE',
  FoldoutItem = 'FOLDOUT_ITEM',
  SearchPage = 'SEARCH_PAGE',
  MyProfile = 'MY_PROFILE'
}

export type CampaignArea = {
  __typename?: 'campaignArea';
  campaignAreaCardColor?: Maybe<Scalars['String']>;
  campaignAreaDarkMode: Scalars['Boolean'];
  campaignAreaImage?: Maybe<Image>;
  campaignAreaLink?: Maybe<Link>;
  campaignAreaShowHide: Scalars['Boolean'];
  campaignAreaText?: Maybe<Scalars['String']>;
  campaignAreaTitle?: Maybe<Scalars['String']>;
};

export type RelatedPagesBlock = {
  __typename?: 'relatedPagesBlock';
  cta?: Maybe<CtaGraphType>;
  id?: Maybe<Scalars['String']>;
  pages?: Maybe<Array<Maybe<PageSummary>>>;
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type CtaGraphType = {
  __typename?: 'CtaGraphType';
  disabled?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type PageSummary = {
  __typename?: 'pageSummary';
  editorsPick?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  meta?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  preamble?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
};

export type AccordionBlock = {
  __typename?: 'accordionBlock';
  id?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<AccordionItem>>>;
  preamble?: Maybe<Scalars['String']>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type AccordionItem = {
  __typename?: 'accordionItem';
  content?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type LandingPageHeroBlock = {
  __typename?: 'landingPageHeroBlock';
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  preamble?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
  videoUrl?: Maybe<Scalars['String']>;
};

export type MediaContentBlock = {
  __typename?: 'mediaContentBlock';
  id?: Maybe<Scalars['String']>;
  mirrorLayout?: Maybe<Scalars['Boolean']>;
  pages?: Maybe<Array<Maybe<PageSummary>>>;
  preamble?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type ProgramListingBlock = {
  __typename?: 'programListingBlock';
  id?: Maybe<Scalars['String']>;
  pages?: Maybe<Array<Maybe<PageSummary>>>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  type: BlockType;
};

export type PageGridBlock = {
  __typename?: 'pageGridBlock';
  id?: Maybe<Scalars['String']>;
  mirrorLayout?: Maybe<Scalars['Boolean']>;
  pages?: Maybe<Array<Maybe<PageSummary>>>;
  preamble?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type ThreeColumnPromoBlock = {
  __typename?: 'threeColumnPromoBlock';
  id?: Maybe<Scalars['String']>;
  pages?: Maybe<Array<Maybe<PageSummary>>>;
  preamble?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type BreadcrumbsBlock = {
  __typename?: 'breadcrumbsBlock';
  id?: Maybe<Scalars['String']>;
  links?: Maybe<Array<Link>>;
  type: BlockType;
};

export type SeasonCalendarBlock = {
  __typename?: 'seasonCalendarBlock';
  content?: Maybe<CalendarContent>;
  id?: Maybe<Scalars['String']>;
  preamble?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type CalendarContent = {
  __typename?: 'calendarContent';
  months: Array<CalendarMonth>;
  selectMonthFacets?: Maybe<Array<CalendarMonthFacet>>;
  showFacets?: Maybe<Array<ShowFacet>>;
  tagFacets?: Maybe<Array<Facet>>;
};

export type CalendarMonth = {
  __typename?: 'calendarMonth';
  days?: Maybe<Array<CalendarDayUnionType>>;
  monthYear: Scalars['String'];
  name: Scalars['String'];
};

export type CalendarDayUnionType = CalendarDay | DateRange;

export type CalendarDay = {
  __typename?: 'calendarDay';
  dayMonth: Scalars['String'];
  dayNumber: Scalars['Int'];
  events?: Maybe<Array<Event>>;
  name: Scalars['String'];
};

export type Event = {
  __typename?: 'event';
  additionalInfo?: Maybe<Scalars['String']>;
  cta?: Maybe<CtaGraphType>;
  date: EventDate;
  eventGroupId: Scalars['Int'];
  eventGroupTitle?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  scene?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  ticketInformation?: Maybe<Scalars['String']>;
  ticketTypes?: Maybe<Array<TicketType>>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type EventDate = {
  __typename?: 'eventDate';
  day?: Maybe<Scalars['String']>;
  dayMonth?: Maybe<Scalars['String']>;
  formatted?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
};

export type TicketType = {
  __typename?: 'ticketType';
  maxPrice: Scalars['Decimal'];
  minPrice: Scalars['Decimal'];
  name: Scalars['String'];
  position: Scalars['Int'];
  priceZones?: Maybe<Array<Maybe<PriceZone>>>;
  ticketCount: Scalars['String'];
  ticketTypeId: Scalars['Int'];
};

export type PriceZone = {
  __typename?: 'priceZone';
  color: Scalars['String'];
  price?: Maybe<Scalars['Decimal']>;
  reservedColor: Scalars['String'];
  zoneId?: Maybe<Scalars['Int']>;
  zoneName?: Maybe<Scalars['String']>;
};

export type DateRange = {
  __typename?: 'dateRange';
  from?: Maybe<DateTime>;
  to?: Maybe<DateTime>;
};

export type DateTime = {
  __typename?: 'dateTime';
  datetime?: Maybe<Scalars['Date']>;
  formatted?: Maybe<FormattedDate>;
};

export type FormattedDate = {
  __typename?: 'formattedDate';
  date?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  weekday?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
};

export type CalendarMonthFacet = {
  __typename?: 'calendarMonthFacet';
  days: Array<Facet>;
  disabled: Scalars['Boolean'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type Facet = {
  __typename?: 'facet';
  disabled: Scalars['Boolean'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ShowFacet = {
  __typename?: 'showFacet';
  disabled: Scalars['Boolean'];
  label: Scalars['String'];
  tag?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type ProfilePageHeroBlock = {
  __typename?: 'profilePageHeroBlock';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  preamble?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
  url?: Maybe<Link>;
};

export type SearchBlock = {
  __typename?: 'searchBlock';
  archiveLink?: Maybe<CtaGraphType>;
  id?: Maybe<Scalars['String']>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type ProfileListingBlock = {
  __typename?: 'profileListingBlock';
  id?: Maybe<Scalars['String']>;
  items?: Maybe<Array<ProfilePageHeroBlock>>;
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type HeroBlock = {
  __typename?: 'heroBlock';
  cta?: Maybe<Link>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  link?: Maybe<Link>;
  preamble?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
  videoUrl?: Maybe<Scalars['String']>;
};

export type MultipleShowsBlock = {
  __typename?: 'multipleShowsBlock';
  id?: Maybe<Scalars['String']>;
  tabs?: Maybe<Array<MultipleShowsTab>>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  type: BlockType;
};

export type MultipleShowsTab = {
  __typename?: 'multipleShowsTab';
  blocks?: Maybe<Array<Maybe<MultipleShowsTabBlocks>>>;
  image?: Maybe<Image>;
  subTitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type MultipleShowsTabBlocks = AccordionBlock | MediaContentBlock | PageGridBlock | ProfileListingBlock | SlideshowBlock | ThreeColumnPromoBlock | RteBlock | VideoBlock | IFrameBlock | ParticipantsBlock | UnknownBlock;

export type SlideshowBlock = {
  __typename?: 'slideshowBlock';
  id?: Maybe<Scalars['String']>;
  items?: Maybe<Array<SlideshowItem>>;
  preamble?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type SlideshowItem = {
  __typename?: 'slideshowItem';
  caption?: Maybe<Scalars['String']>;
  captionLabel?: Maybe<Scalars['String']>;
  credit?: Maybe<Scalars['String']>;
  creditLabel?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
};

export type RteBlock = {
  __typename?: 'rteBlock';
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  type: BlockType;
};

export type VideoBlock = {
  __typename?: 'videoBlock';
  id?: Maybe<Scalars['String']>;
  preamble?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
  url?: Maybe<Scalars['String']>;
};

export type IFrameBlock = {
  __typename?: 'iFrameBlock';
  height: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  preamble?: Maybe<Scalars['String']>;
  src: Scalars['String'];
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type ParticipantsBlock = {
  __typename?: 'participantsBlock';
  castListing?: Maybe<Scalars['String']>;
  castParticipants?: Maybe<Array<Maybe<ProfilePageHeroBlock>>>;
  crewListing?: Maybe<Scalars['String']>;
  /** @deprecated Obsolete property */
  crewParticipants?: Maybe<Array<Maybe<ProfilePageHeroBlock>>>;
  id?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  type: BlockType;
};

export type UnknownBlock = {
  __typename?: 'unknownBlock';
  id?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type ShowIntroBlock = {
  __typename?: 'showIntroBlock';
  fromDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  info?: Maybe<Array<Maybe<KeyValueGraphType>>>;
  preamble?: Maybe<Scalars['String']>;
  rte?: Maybe<RteBlock>;
  showMoreLabel?: Maybe<Scalars['String']>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  toDate?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type AlertBlock = {
  __typename?: 'alertBlock';
  backgroundColor?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Link>;
  preamble?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type PerformancePastBlock = {
  __typename?: 'performancePastBlock';
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Link>;
  preamble?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type EcomBlock = {
  __typename?: 'ecomBlock';
  backLink?: Maybe<Link>;
  bookTable: BookTable;
  events?: Maybe<Array<ProgrammeMonth>>;
  groupBookingInfoBox?: Maybe<InfoBox>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  infoBoxes?: Maybe<Array<Maybe<InfoBox>>>;
  name?: Maybe<Scalars['String']>;
  seatPickerTabs?: Maybe<Array<SeatPickerTab>>;
  tag?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  type: BlockType;
};

export type SeatPickerTab = {
  __typename?: 'seatPickerTab';
  additionalInfo: Scalars['String'];
  label: Scalars['String'];
  link?: Maybe<Link>;
  preamble: Scalars['String'];
  title: Scalars['String'];
};

export type ProgrammeMonth = {
  __typename?: 'programmeMonth';
  events?: Maybe<Array<Event>>;
  name?: Maybe<Scalars['String']>;
};

export type BookTable = {
  __typename?: 'bookTable';
  cta?: Maybe<CtaGraphType>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  link?: Maybe<Link>;
  title: Scalars['String'];
};

export type InfoBox = {
  __typename?: 'infoBox';
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type PreviewBlock = {
  __typename?: 'previewBlock';
  heading?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type TabsBlock = {
  __typename?: 'tabsBlock';
  id?: Maybe<Scalars['String']>;
  tabs?: Maybe<Array<Tab>>;
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export type Tab = {
  __typename?: 'tab';
  content: TabsContentUnionType;
  icon: IconType;
  id: Scalars['String'];
  title: Scalars['String'];
};

export type TabsContentUnionType = RteTab | VideoTab | SlideshowTab;

export type RteTab = {
  __typename?: 'rteTab';
  id?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type VideoTab = {
  __typename?: 'videoTab';
  id?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type SlideshowTab = {
  __typename?: 'slideshowTab';
  id?: Maybe<Scalars['String']>;
  items?: Maybe<Array<SlideshowItem>>;
  type: Scalars['String'];
};

export type ShowAlertBlock = {
  __typename?: 'showAlertBlock';
  body?: Maybe<Scalars['String']>;
  icon: AlertIcon;
  id?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  type: BlockType;
};

export enum AlertIcon {
  RedAlert = 'RED_ALERT',
  Alert = 'ALERT',
  Info = 'INFO',
  Check = 'CHECK',
  Ticket = 'TICKET'
}

export type VideoGridBlock = {
  __typename?: 'videoGridBlock';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
  videoPages?: Maybe<Array<VideoPageIntroBlock>>;
};

export type VideoPageIntroBlock = {
  __typename?: 'videoPageIntroBlock';
  fromDate?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  link?: Maybe<Link>;
  preamble?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  toDate?: Maybe<Scalars['String']>;
  type: BlockType;
  videoDetails?: Maybe<VimeoDetails>;
  videoURL?: Maybe<Scalars['String']>;
};

export type VimeoDetails = {
  __typename?: 'vimeoDetails';
  title?: Maybe<Scalars['String']>;
  videoThumbnail?: Maybe<Scalars['String']>;
};

export type NewsletterBlock = {
  __typename?: 'newsletterBlock';
  backgroundImage?: Maybe<Image>;
  buttonText?: Maybe<Scalars['String']>;
  failMessage?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  invalidInput?: Maybe<Scalars['String']>;
  placeholderText?: Maybe<Scalars['String']>;
  privacyPolicyInformation?: Maybe<Scalars['String']>;
  successMessage?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  themeColorVariant?: Maybe<ThemeColorVariant>;
  title?: Maybe<Scalars['String']>;
  type: BlockType;
};

export enum MenuType {
  Main = 'MAIN',
  Foldout = 'FOLDOUT'
}

export type Translation = {
  __typename?: 'translation';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type Search = {
  __typename?: 'search';
  pagination: Pagination;
  results?: Maybe<Array<Maybe<SearchResultItemGraphType>>>;
  searchInput?: Maybe<Scalars['String']>;
};

export type SearchResultItemGraphType = CommonSearchResultItem;

export type CommonSearchResultItem = {
  __typename?: 'commonSearchResultItem';
  dateRange?: Maybe<DateRange>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  passed: Scalars['Boolean'];
  passedShowMessage?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  preamble?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  ticketUrl?: Maybe<CtaUnionGraphType>;
  title?: Maybe<Link>;
  type?: Maybe<Scalars['String']>;
};

export type Pagination = {
  __typename?: 'pagination';
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  totalPages: Scalars['Int'];
  totalSize: Scalars['Int'];
};

export type PaginationInput = {
  page: Scalars['Int'];
  maxSize?: Maybe<Scalars['Int']>;
};

export type FooterBlock = {
  __typename?: 'footerBlock';
  aboutSite?: Maybe<Link>;
  contactDepartments?: Maybe<Array<ContactDepartment>>;
  contactInfoPage?: Maybe<Link>;
  danceCompanySocialMedia?: Maybe<Array<Maybe<Link>>>;
  danceCompanySocialMediaTitle?: Maybe<Scalars['String']>;
  /** @deprecated Not used for now */
  email?: Maybe<Scalars['String']>;
  faq?: Maybe<Array<Maybe<FaqItem>>>;
  faqPage?: Maybe<Link>;
  id?: Maybe<Scalars['String']>;
  operaLogo?: Maybe<Image>;
  operaSocialMedia?: Maybe<Array<Maybe<Link>>>;
  operaSocialMediaTitle?: Maybe<Scalars['String']>;
  personalData?: Maybe<Link>;
  /** @deprecated Not used for now */
  phone?: Maybe<Scalars['String']>;
  regionLogo?: Maybe<Image>;
  termsPage?: Maybe<Link>;
  type: BlockType;
};

export type FaqItem = {
  __typename?: 'faqItem';
  items?: Maybe<Array<Link>>;
  title: Link;
};

export type ContactDepartment = {
  __typename?: 'contactDepartment';
  departmentName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type CommonSearchResult = {
  __typename?: 'commonSearchResult';
  results?: Maybe<Array<Maybe<Link>>>;
  title?: Maybe<Scalars['String']>;
};

export type CheckoutUrl = {
  __typename?: 'checkoutUrl';
  checkoutUrl: Scalars['String'];
};

export type SeatPlanner = {
  __typename?: 'SeatPlanner';
  /** @deprecated Not used on front end */
  arenaPlanSvg: Scalars['String'];
  ticketZones?: Maybe<Array<Maybe<TicketZone>>>;
};

export type TicketZone = {
  __typename?: 'ticketZone';
  color: Scalars['String'];
  cta?: Maybe<CtaGraphType>;
  seatsAvailable: Scalars['Int'];
  totalPrice: Scalars['Int'];
  zoneId: Scalars['Int'];
  zoneName: Scalars['String'];
};

export type LanguageSwitchResult = {
  __typename?: 'languageSwitchResult';
  redirectUrlEn: Scalars['String'];
  redirectUrlSv: Scalars['String'];
};

export type Seat = {
  __typename?: 'seat';
  color: Scalars['String'];
  isReserved: Scalars['Boolean'];
  positionX: Scalars['Int'];
  positionY: Scalars['Int'];
  radius: Scalars['Int'];
  reservedColor: Scalars['String'];
  rowName: Scalars['String'];
  rowNumber: Scalars['Int'];
  seatId: Scalars['Int'];
  seatName: Scalars['String'];
  seatNumber: Scalars['Int'];
  sectionId: Scalars['Int'];
  sectionName: Scalars['String'];
  zoneId: Scalars['Int'];
  zoneName: Scalars['String'];
};

export type ProductCategory = {
  __typename?: 'productCategory';
  category: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<ProductsUnionGraphType>>>;
};

export type ProductsUnionGraphType = Product | ProductGroup | BookTable;

export type Product = {
  __typename?: 'product';
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  title: Scalars['String'];
  unitPrice?: Maybe<Scalars['Decimal']>;
};

export type ProductGroup = {
  __typename?: 'productGroup';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Image>;
  products?: Maybe<Array<Product>>;
  title: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  deleteReservation?: Maybe<Scalars['Boolean']>;
  reserveTickets?: Maybe<ReserveTicketsResponse>;
  updateProducts?: Maybe<ReserveTicketsResponse>;
  updateReservation?: Maybe<ReserveTicketsResponse>;
};


export type MutationDeleteReservationArgs = {
  orderId: Scalars['String'];
};


export type MutationReserveTicketsArgs = {
  eventId: Scalars['Int'];
  orderId?: Maybe<Scalars['String']>;
  tickets: Array<Maybe<TicketReservationInput>>;
};


export type MutationUpdateProductsArgs = {
  eventId: Scalars['Int'];
  orderId: Scalars['String'];
  productIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
};


export type MutationUpdateReservationArgs = {
  eventId: Scalars['Int'];
  orderId?: Maybe<Scalars['String']>;
  tickets: Array<Maybe<TicketReservationInput>>;
};

export type ReserveTicketsResponse = {
  __typename?: 'reserveTicketsResponse';
  collatedSeatInfo?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<Product>>>;
  ticketTypes?: Maybe<Array<TicketSummary>>;
  totalPrice: Scalars['Int'];
  zoneNames?: Maybe<Array<Scalars['String']>>;
};

export type TicketSummary = {
  __typename?: 'ticketSummary';
  limitedView: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  seatId: Scalars['Int'];
  seatInfo?: Maybe<Scalars['String']>;
  seatName: Scalars['String'];
  ticketTypeCollatedSeatInfo: Scalars['String'];
  ticketTypeId: Scalars['Int'];
  unitPrice: Scalars['Int'];
  zoneColor: Scalars['String'];
  zoneId: Scalars['Int'];
  zoneName: Scalars['String'];
};

export type TicketReservationInput = {
  ticketTypeId: Scalars['Int'];
  zoneId?: Maybe<Scalars['Int']>;
  zoneName?: Maybe<Scalars['String']>;
  seatId?: Maybe<Scalars['Int']>;
};
