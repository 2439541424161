import React from "react"
import { useLocation } from "react-router"
import { TabsBlockFieldsFragment } from "./operations.generated"
import {
	Text,
	Tabs,
	Flex,
	Rte,
	VideoPlayer,
	Accordion,
	Icon,
	READABLE_LINE_MAX_WIDTH
} from "@operan/ui-components"
import { iconTypeToIconProp } from "../../utils/iconTypeToIcon"
import { Block } from "../Block/Block"
import { BlockCommonLayout, trackVideoInteraction } from "../../utils"
import { useTheme } from "styled-components"
import { useLocales, translationNamespace } from "../../hooks/useLocales"

interface TabsBlockProps extends TabsBlockFieldsFragment {}

type TabTypeName = TabsBlockFieldsFragment["tabs"][0]["content"]["__typename"]

type TabProps<TabTypeName> = Extract<
	TabsBlockFieldsFragment["tabs"][0]["content"],
	{ __typename?: TabTypeName }
>

type RteTabProps = TabProps<"rteTab">
type VideoTabProps = TabProps<"videoTab">
type SideshowTabProps = TabProps<"slideshowTab">

function createMarkup(markup: string) {
	return { __html: markup }
}

const RteTab: React.FC<RteTabProps> = props => (
	<Flex
		maxw={READABLE_LINE_MAX_WIDTH}
		w={1}
		style={{ margin: "0 auto" }}
		pl={{ _: 2, m: 5, l: 11 }}
		pr={{ _: 2, m: 5, l: 11 }}
	>
		<Rte>
			{props.text && <div dangerouslySetInnerHTML={createMarkup(props.text)} />}
		</Rte>
	</Flex>
)
const VideoTab: React.FC<VideoTabProps> = props => {
	const [isPlaying, setIsPlaying] = React.useState(false)
	const [progressEventSent, setProgressEventSent] = React.useState(false)
	const location = useLocation()

	return props.url ? (
		<VideoPlayer
			url={props.url}
			loadWhenInView={false}
			onStart={() => {
				trackVideoInteraction("Play", location.pathname)
				setIsPlaying(true)
			}}
			onProgress={({ played }) => {
				if (
					isPlaying &&
					!progressEventSent &&
					parseFloat(played.toFixed(1)) === 0.5
				) {
					trackVideoInteraction("50% progress", location.pathname)
					setProgressEventSent(true)
				}
			}}
			onEnded={() => {
				trackVideoInteraction("Complete", location.pathname)
				setIsPlaying(false)
			}}
		/>
	) : null
}

const SlideshowTab: React.FC<SideshowTabProps> = props => <div />

const TabRecord: Partial<Record<TabTypeName, React.FC<any>>> = {
	rteTab: RteTab,
	videoTab: VideoTab,
	slideshowTab: SlideshowTab
}

const getTabComponent = (tabTypeName: TabTypeName) =>
	TabRecord[tabTypeName] || React.Fragment


const TabsBlock: React.FC<TabsBlockProps> = props => {

	const theme = useTheme()
	const locales = useLocales(translationNamespace.common("Accordion"), [
		"toggleLabel"
	])
	let timeOutOnExpand: ReturnType<typeof setTimeout>

	const handleOnExpand = (id: string) => {

		const currentPanel = document.querySelector<HTMLElement|null>('#panel-' + id)
		if (!currentPanel) return

		clearTimeout(timeOutOnExpand)
		const offset = 100
		const wait = 50
		
		timeOutOnExpand = setTimeout(() => {
			const topPos = currentPanel.getBoundingClientRect().top;
			
			// check if top of element is in the viewport
			if (
				topPos >= 0 && 
				topPos <= ( window.innerHeight || document.documentElement.clientHeight )
			) return
			
			window.scrollTo({
				top: topPos + window.scrollY - offset,
				behavior: 'auto'
			});
		}, wait)
	}

	return (
		<Block themeColorVariant={props.themeColorVariant} theme={props.theme}>
			<BlockCommonLayout>
				{props.tabs &&
					<Tabs activeTab={props.tabs[0].id}>
						{/* Display tabs for xl > */}
						<Flex display={{ _: "none", xl: "flex" }} flex={1} w={1}>
							<Flex maxw={42} mr={8} w={1} flexDirection="column">
								<Tabs.TabsContainer
									flexDirection="column"
									w={1}
									borderRight
									pb={15}
								>
									<Text variant="headline-2" textAlign="center" mb={4} mx={2}>
										{props.title}
									</Text>
									{props.tabs.map((tab, i) => (
										<Tabs.IconTab
											icon={iconTypeToIconProp(tab.icon)}
											id={tab.id}
											key={`tab-${tab.id}`}
											variant="ghost"
										>
											{tab.title}
										</Tabs.IconTab>
									))}
								</Tabs.TabsContainer>
							</Flex>
							<Flex m={2} flex={1}>
								{props.tabs.map((tab, i) => (
									<Tabs.Panel id={tab.id} key={`panel-${tab.id}`}>
										{React.createElement(
											getTabComponent(tab.content.__typename),
											tab.content
										)}
									</Tabs.Panel>
								))}
							</Flex>
						</Flex>
						{/* Display accordion for xl < */}
						<Flex
							w={1}
							flex={1}
							flexDirection="column"
							alignItems="center"
							display={{ xl: "none" }}
						>
							<Text variant="headline-2">{props.title}</Text>
							<Accordion>
								{props.tabs.map(tab => (
									<Flex w={1} flexDirection="column" key={tab.id}>
										<Accordion.Header
											id={tab.id}
											toggleLabel={locales.toggleLabel.replace(/%s/g, tab.title)}
											onExpand={() => {
												handleOnExpand(tab.id)
											}}
										>
											<Icon
												mr={2}
												icon={iconTypeToIconProp(tab.icon)}
												color={theme.dark ? "white" : "black"}
											/>
											{tab.title}
										</Accordion.Header>
										<Accordion.Panel id={tab.id}>
											{React.createElement(
												getTabComponent(tab.content.__typename),
												tab.content
											)}
										</Accordion.Panel>
									</Flex>
								))}
							</Accordion>
						</Flex>
					</Tabs>
				}
			</BlockCommonLayout>
		</Block>
	)
}

export { TabsBlock }
