import { breakpoint, fontWeight, scale } from "@operan/ui-components"
import styled from "styled-components"
interface SearchButtonStyledProps {
	textColor: string
}

export const SearchButtonStyled = styled.button<SearchButtonStyledProps>`
	background-color: transparent;
	border: none;
	font-weight: ${fontWeight.semibold};
	line-height: ${scale.px(2)};
	font-size: ${scale.px(1.75)};
	text-transform: uppercase;
	color: ${props => props.textColor};
	${breakpoint("xl")`
		font-size: ${scale.px(2)};
	`}
`
