import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type HeroBlockFieldsFragment = (
  { __typename?: 'heroBlock' }
  & Pick<Types.HeroBlock, 'id' | 'type' | 'videoUrl' | 'title' | 'preamble'>
  & { image?: Types.Maybe<(
    { __typename?: 'image' }
    & Pick<Types.Image, 'src' | 'alt'>
  )>, ctaButton?: Types.Maybe<(
    { __typename?: 'link' }
    & Pick<Types.Link, 'title' | 'target' | 'url'>
  )>, link?: Types.Maybe<(
    { __typename?: 'link' }
    & Pick<Types.Link, 'alt' | 'target' | 'url' | 'title'>
  )>, theme?: Types.Maybe<(
    { __typename?: 'theme' }
    & Pick<Types.Theme, 'dark' | 'complementaryColor' | 'primaryColor' | 'textColor'>
  )> }
);

export const HeroBlockFieldsFragmentDoc = gql`
    fragment heroBlockFields on heroBlock {
  id
  type
  image {
    src
    alt
  }
  videoUrl
  title
  preamble
  ctaButton: cta {
    title
    target
    url
  }
  link {
    alt
    target
    url
    title
  }
  theme {
    dark
    complementaryColor
    primaryColor
    textColor
  }
}
    `;