import * as React from "react"
import {
	EventCard,
	Flex,
} from "@operan/ui-components"
import {
	VideoGridBlock as IVideoGridBlock
} from "../../@types/graphql.generated"
import { BlockFullWidthLayout, BlockTitlePreamble } from "../../utils/layout"
import { Link, useLocation } from "react-router-dom"
import { localeCodeFromUrl } from "@operan/www-common/utils/locale"
import { Block } from "../Block"
import { formatDateSpan } from "../../utils/dateFormatter"

const VideoGridBlock: React.FC<IVideoGridBlock> = props => {
	const location = useLocation();
	const localeCode = localeCodeFromUrl(location.pathname);

	return (<Block themeColorVariant={props.themeColorVariant} theme={props.theme}>
		<BlockFullWidthLayout>
			<Flex w={1}>
				<BlockTitlePreamble
					title={props.title}
					preamble={props.description || null}
				/>
			</Flex>
			<Flex
				display={{ _: "block", s: "flex" }}
				flexWrap="wrap"
				width={{ _: 1, s: "calc(100% + 16px)", xl: "calc(100% + 32px)" }}
				ml={{ s: -1, xl: -2 }}
			>
				{props.videoPages ? 
					props.videoPages.map((page, i) => {
						const startDate = page.fromDate && new Date(page.fromDate);
						const endDate = page.toDate && new Date(page.toDate);
						const description = (startDate && endDate) && 
							`${formatDateSpan(startDate, endDate, localeCode)}`;
						
						return (<Flex
							key={i}
							flex={{
								_: "0 0 100%",
								s: "0 0 50%",
								l: "0 0 33.33%",
								xxxl: "0 0 25%"
							}}
							flexDirection="column"
							px={{ _: 0, s: 1, xl: 1.5 }}
							pb={{ _: 0, s: 2, xl: 3 }}
						>
							<Link
								to={page.link.url}
								title={page.preamble || page.link.title || ""}
								style={{ width: "100%", height: "100%" }}
							>
								<EventCard
									name={page.title}
									meta={description}
									media={
										<img alt={page.title} src={page.videoDetails.videoThumbnail} />
									}
									title={page.preamble}
								/>
							</Link>
						</Flex>);
					}) : (<></>)
				}
			</Flex>
		</BlockFullWidthLayout>
	</Block>)
}

export { VideoGridBlock }
