import {
	fontFamilyHeadings,
	SearchResultItem,
	Text
} from "@operan/ui-components"
import * as React from "react"
import { Link } from "react-router-dom"
import { CommonSearchResultItem } from "../../../@types/graphql.generated"
import { translationNamespace, useLocales } from "../../../hooks"
import { qBankImageSrc } from "../../../utils"

interface SearchResultitemProfileProps {
	result: CommonSearchResultItem
}

export const SearchResultItemProfile = React.forwardRef<
	HTMLAnchorElement,
	SearchResultitemProfileProps
>((props, ref) => {
	const locales = useLocales(translationNamespace.common("Misc"), [
		"email",
		"telephone"
	])
	return (
		<SearchResultItem
			image={
				props.result.image ? (
					<img
						src={qBankImageSrc(props.result.image.src, "768")}
						alt={props.result.image.alt || ""}
					/>
				) : null
			}
		>
			<SearchResultItem.Header>
				{props.result.tag && (
					<SearchResultItem.Tag shouldTruncate>
						{props.result.tag}
					</SearchResultItem.Tag>
				)}
			</SearchResultItem.Header>
			{props.result.title && (
				<Link ref={ref} to={props.result.title.url}>
					<Text
						as="h2"
						fontFamily={fontFamilyHeadings}
						fontSize={{ _: 2.25, m: 3, xl: 4 }}
						lineHeight={{ _: 3, m: 4, xl: 5 }}
						textColor="black"
						mb={{ _: 0, m: 0.5 }}
						style={{ textDecoration: "underline" }}
					>
						{props.result.title.title}
					</Text>
				</Link>
			)}

			{props.result.role && (
				<Text
					fontSize={{ _: 1.5, m: 1.75 }}
					lineHeight={2.25}
					textTransform="uppercase"
					mb={{ _: 1, m: 4 }}
				>
					{props.result.role}
				</Text>
			)}

			{(props.result.phone || props.result.email) && (
				<SearchResultItem.Content>
					{props.result.phone && (
						<SearchResultItem.ContactLink href={`tel:${props.result.phone}`}>
							{locales.telephone}: {props.result.phone}
						</SearchResultItem.ContactLink>
					)}
					{props.result.email && (
						<SearchResultItem.ContactLink href={`mailto:${props.result.email}`}>
							{locales.email}: {props.result.email}
						</SearchResultItem.ContactLink>
					)}
				</SearchResultItem.Content>
			)}
		</SearchResultItem>
	)
})
