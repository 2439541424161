import * as React from "react"
import { Snackbar } from "@operan/ui-components"
import { useLocales, translationNamespace } from "../hooks/useLocales"

export interface SnackbarContextValue {
	addAlert: (alert: string, timeout?: number) => void
}

export const SnackbarContext = React.createContext<SnackbarContextValue>({
	addAlert: (alert, timeout) => {}
})

export const SnackbarContextProvider: React.FC = ({ children }) => {
	const [alert, setAlert] = React.useState<string>("")
	const [timeoutValue, setTimeoutValue] = React.useState(8000)
	const [userInteraction, setUserInteraction] = React.useState<boolean>(false)
	const locales = useLocales(translationNamespace.common("Misc"), ["close"])

	React.useEffect(() => {
		if (alert !== "") {
			const timer = setTimeout(() => setAlert(""), timeoutValue)

			if (userInteraction) {
				clearTimeout(timer)
			}

			return () => clearTimeout(timer)
		}
	})

	const addAlert = (newAlert: string, timeout?: number) => {
		if (newAlert !== alert) {
			setAlert(newAlert)
			timeout && setTimeoutValue(timeout)
		}
	}
	const removeAlert = () => setAlert("")

	const value = { addAlert }

	return (
		<SnackbarContext.Provider value={value}>
			{children}
			{alert !== "" && (
				<Snackbar
					key={alert}
					alert={alert}
					actionLabel={locales.close}
					onActionClick={() => removeAlert()}
					onMouseEnter={() => setUserInteraction(true)}
					onMouseLeave={() => setUserInteraction(false)}
				/>
			)}
		</SnackbarContext.Provider>
	)
}
