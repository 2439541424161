import { IconTypes } from "@operan/ui-components"
import { IconType } from "../@types/graphql.generated"

const icons: Partial<Record<IconType, IconTypes>> = {
	[IconType.BookOpen]: "book-open",
	[IconType.Calendar]: "calendar",
	[IconType.Image]: "image",
	[IconType.PersonSpotlight]: "person-spotlight",
	[IconType.Podcast]: "podcast",
	[IconType.TheaterMasks]: "theater-masks",
	[IconType.Camera]: "camera",
	[IconType.Headphones]: "headphones",
	[IconType.Video]: "video"
}

export const iconTypeToIconProp = (iconType: IconType): IconTypes => {
	return icons[iconType]
}
