import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetMenuQueryVariables = Types.Exact<{
  path?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetMenuQuery = (
  { __typename?: 'query' }
  & { foldout?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'isExternalLink' | 'title' | 'url'>
    & { children?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'menu' }
      & Pick<Types.Menu, 'id' | 'title' | 'url'>
    )>>> }
  )>>>, main?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'menu' }
    & Pick<Types.Menu, 'id' | 'title' | 'url' | 'linkType'>
  )>>> }
);


export const GetMenuDocument = gql`
    query GetMenu($path: String) {
  foldout: menu(type: FOLDOUT, path: $path) {
    id
    isExternalLink
    title
    url
    children {
      id
      title
      url
    }
  }
  main: menu(type: MAIN, path: $path) {
    id
    title
    url
    linkType
  }
}
    `;

/**
 * __useGetMenuQuery__
 *
 * To run a query within a React component, call `useGetMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMenuQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useGetMenuQuery(baseOptions?: Apollo.QueryHookOptions<GetMenuQuery, GetMenuQueryVariables>) {
        return Apollo.useQuery<GetMenuQuery, GetMenuQueryVariables>(GetMenuDocument, baseOptions);
      }
export function useGetMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMenuQuery, GetMenuQueryVariables>) {
          return Apollo.useLazyQuery<GetMenuQuery, GetMenuQueryVariables>(GetMenuDocument, baseOptions);
        }
export type GetMenuQueryHookResult = ReturnType<typeof useGetMenuQuery>;
export type GetMenuLazyQueryHookResult = ReturnType<typeof useGetMenuLazyQuery>;
export type GetMenuQueryResult = Apollo.QueryResult<GetMenuQuery, GetMenuQueryVariables>;