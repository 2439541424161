import * as Types from '../../@types/graphql.generated';

import { gql } from '@apollo/client';
export type PageGridBlockFieldsFragment = (
  { __typename?: 'pageGridBlock' }
  & Pick<Types.PageGridBlock, 'id' | 'type' | 'mirrorLayout' | 'title' | 'preamble' | 'themeColorVariant'>
  & { pages?: Types.Maybe<Array<Types.Maybe<(
    { __typename?: 'pageSummary' }
    & Pick<Types.PageSummary, 'url' | 'videoUrl' | 'title' | 'preamble'>
    & { image?: Types.Maybe<(
      { __typename?: 'image' }
      & Pick<Types.Image, 'src' | 'alt'>
    )>, theme?: Types.Maybe<(
      { __typename?: 'theme' }
      & Pick<Types.Theme, 'complementaryColor' | 'primaryColor' | 'textColor'>
    )> }
  )>>>, theme?: Types.Maybe<(
    { __typename?: 'theme' }
    & Pick<Types.Theme, 'dark' | 'complementaryColor' | 'primaryColor' | 'textColor'>
  )> }
);

export const PageGridBlockFieldsFragmentDoc = gql`
    fragment pageGridBlockFields on pageGridBlock {
  id
  type
  mirrorLayout
  title
  preamble
  pages {
    url
    image {
      src
      alt
    }
    videoUrl
    title
    preamble
    theme {
      complementaryColor
      primaryColor
      textColor
    }
  }
  theme {
    dark
    complementaryColor
    primaryColor
    textColor
  }
  themeColorVariant
}
    `;