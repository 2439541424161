import * as Types from '../../@types/graphql.generated';

import { RteBlockFieldsFragment } from '../RteBlock/operations.generated';
import { ArticleRteBlockFieldsFragment } from '../ArticleRteBlock/operations.generated';
import { PageIntroBlockFieldsFragment } from '../PageIntroBlock/operations.generated';
import { RelatedPagesBlockFieldsFragment } from '../RelatedPagesBlock/operations.generated';
import { ProgramListingBlockFieldsFragment } from '../ProgramListingBlock/operations.generated';
import { ShowHeroBlockFieldsFragment } from '../ShowHeroBlock/operations.generated';
import { ShowIntroBlockFieldsFragment } from '../ShowIntroBlock/operations.generated';
import { VideoBlockFieldsFragment } from '../VideoBlock/operations.generated';
import { HeroBlockFieldsFragment } from '../HeroBlock/operations.generated';
import { LandingPageHeroBlockFieldsFragment } from '../LandingPageHeroBlock/operations.generated';
import { PageGridBlockFieldsFragment } from '../PageGridBlock/operations.generated';
import { ThreeColumnPromoBlockFieldsFragment } from '../ThreeColumnPromoBlock/operations.generated';
import { MediaContentBlockFieldsFragment } from '../MediaContentBlock/operations.generated';
import { AccordionBlockFieldsFragment } from '../AccordionBlock/operations.generated';
import { BreadcrumbsBlockFieldsFragment } from '../BreadcrumbsBlock/operations.generated';
import { SlideshowBlockFieldsFragment } from '../SlideshowBlock/operations.generated';
import { SearchBlockFieldsFragment } from '../SearchBlock/operations.generated';
import { ProfilePageHeroBlockFieldsFragment } from '../ProfilePageHeroBlock/operations.generated';
import { ProfileListingBlockFieldsFragment } from '../ProfileListingBlock/operations.generated';
import { ParticipantsBlockFieldsFragment } from '../ParticipantsBlock/operations.generated';
import { MultipleShowsBlockFieldsFragment } from '../MultipleShowsBlock/operations.generated';
import { IFrameBlockFieldsFragment } from '../IFrameBlock/operations.generated';
import { AlertBlockFieldsFragment } from '../AlertBlock/operations.generated';
import { PerformancePastBlockFieldsFragment } from '../PerformancePastBlock/operations.generated';
import { PreviewBlockFieldsFragment } from '../PreviewBlock/operations.generated';
import { SeasonCalendarBlockFieldsFragment } from '../SeasonCalendarBlock/operations.generated';
import { TabsBlockFieldsFragment } from '../TabsBlock/operations.generated';
import { ShowAlertBlockFieldsFragment } from '../ShowAlertBlock/operations.generated';
import { VideoPageIntroBlockFieldsFragment } from '../VideoPageIntroBlock/operations.generated';
import { VideoGridBlockFieldsFragment } from '../VideoGridBlock/operations.generated';
import { gql } from '@apollo/client';
import { RteBlockFieldsFragmentDoc } from '../RteBlock/operations.generated';
import { ArticleRteBlockFieldsFragmentDoc } from '../ArticleRteBlock/operations.generated';
import { PageIntroBlockFieldsFragmentDoc } from '../PageIntroBlock/operations.generated';
import { RelatedPagesBlockFieldsFragmentDoc } from '../RelatedPagesBlock/operations.generated';
import { ProgramListingBlockFieldsFragmentDoc } from '../ProgramListingBlock/operations.generated';
import { ShowHeroBlockFieldsFragmentDoc } from '../ShowHeroBlock/operations.generated';
import { ShowIntroBlockFieldsFragmentDoc } from '../ShowIntroBlock/operations.generated';
import { VideoBlockFieldsFragmentDoc } from '../VideoBlock/operations.generated';
import { HeroBlockFieldsFragmentDoc } from '../HeroBlock/operations.generated';
import { LandingPageHeroBlockFieldsFragmentDoc } from '../LandingPageHeroBlock/operations.generated';
import { PageGridBlockFieldsFragmentDoc } from '../PageGridBlock/operations.generated';
import { ThreeColumnPromoBlockFieldsFragmentDoc } from '../ThreeColumnPromoBlock/operations.generated';
import { MediaContentBlockFieldsFragmentDoc } from '../MediaContentBlock/operations.generated';
import { AccordionBlockFieldsFragmentDoc } from '../AccordionBlock/operations.generated';
import { BreadcrumbsBlockFieldsFragmentDoc } from '../BreadcrumbsBlock/operations.generated';
import { SlideshowBlockFieldsFragmentDoc } from '../SlideshowBlock/operations.generated';
import { SearchBlockFieldsFragmentDoc } from '../SearchBlock/operations.generated';
import { ProfilePageHeroBlockFieldsFragmentDoc } from '../ProfilePageHeroBlock/operations.generated';
import { ProfileListingBlockFieldsFragmentDoc } from '../ProfileListingBlock/operations.generated';
import { ParticipantsBlockFieldsFragmentDoc } from '../ParticipantsBlock/operations.generated';
import { MultipleShowsBlockFieldsFragmentDoc } from '../MultipleShowsBlock/operations.generated';
import { IFrameBlockFieldsFragmentDoc } from '../IFrameBlock/operations.generated';
import { AlertBlockFieldsFragmentDoc } from '../AlertBlock/operations.generated';
import { PerformancePastBlockFieldsFragmentDoc } from '../PerformancePastBlock/operations.generated';
import { PreviewBlockFieldsFragmentDoc } from '../PreviewBlock/operations.generated';
import { SeasonCalendarBlockFieldsFragmentDoc } from '../SeasonCalendarBlock/operations.generated';
import { TabsBlockFieldsFragmentDoc } from '../TabsBlock/operations.generated';
import { ShowAlertBlockFieldsFragmentDoc } from '../ShowAlertBlock/operations.generated';
import { VideoPageIntroBlockFieldsFragmentDoc } from '../VideoPageIntroBlock/operations.generated';
import { VideoGridBlockFieldsFragmentDoc } from '../VideoGridBlock/operations.generated';
import * as Apollo from '@apollo/client';
export type GetPageQueryVariables = Types.Exact<{
  path: Types.Scalars['String'];
  previewContentId?: Types.Maybe<Types.Scalars['Int']>;
  previewToken?: Types.Maybe<Types.Scalars['String']>;
}>;


export type GetPageQuery = (
  { __typename?: 'query' }
  & { page?: Types.Maybe<(
    { __typename?: 'page' }
    & Pick<Types.Page, 'httpStatusCode' | 'redirectUrl' | 'id'>
    & { theme?: Types.Maybe<(
      { __typename?: 'theme' }
      & Pick<Types.Theme, 'primaryColor' | 'complementaryColor' | 'dark'>
    )>, meta?: Types.Maybe<(
      { __typename?: 'meta' }
      & Pick<Types.Meta, 'title' | 'description'>
    )>, blocks?: Types.Maybe<Array<Types.Maybe<(
      { __typename: 'showHeroBlock' }
      & ShowHeroBlockFieldsFragment
    ) | (
      { __typename: 'articleRteBlock' }
      & ArticleRteBlockFieldsFragment
    ) | (
      { __typename: 'pageIntroBlock' }
      & PageIntroBlockFieldsFragment
    ) | (
      { __typename: 'relatedPagesBlock' }
      & RelatedPagesBlockFieldsFragment
    ) | (
      { __typename: 'accordionBlock' }
      & AccordionBlockFieldsFragment
    ) | (
      { __typename: 'landingPageHeroBlock' }
      & LandingPageHeroBlockFieldsFragment
    ) | (
      { __typename: 'mediaContentBlock' }
      & MediaContentBlockFieldsFragment
    ) | (
      { __typename: 'programListingBlock' }
      & ProgramListingBlockFieldsFragment
    ) | (
      { __typename: 'pageGridBlock' }
      & PageGridBlockFieldsFragment
    ) | (
      { __typename: 'threeColumnPromoBlock' }
      & ThreeColumnPromoBlockFieldsFragment
    ) | (
      { __typename: 'breadcrumbsBlock' }
      & BreadcrumbsBlockFieldsFragment
    ) | (
      { __typename: 'seasonCalendarBlock' }
      & SeasonCalendarBlockFieldsFragment
    ) | (
      { __typename: 'profilePageHeroBlock' }
      & ProfilePageHeroBlockFieldsFragment
    ) | (
      { __typename: 'searchBlock' }
      & SearchBlockFieldsFragment
    ) | (
      { __typename: 'profileListingBlock' }
      & ProfileListingBlockFieldsFragment
    ) | (
      { __typename: 'heroBlock' }
      & HeroBlockFieldsFragment
    ) | (
      { __typename: 'multipleShowsBlock' }
      & MultipleShowsBlockFieldsFragment
    ) | (
      { __typename: 'showIntroBlock' }
      & ShowIntroBlockFieldsFragment
    ) | (
      { __typename: 'rteBlock' }
      & RteBlockFieldsFragment
    ) | (
      { __typename: 'videoBlock' }
      & VideoBlockFieldsFragment
    ) | (
      { __typename: 'slideshowBlock' }
      & SlideshowBlockFieldsFragment
    ) | (
      { __typename: 'iFrameBlock' }
      & IFrameBlockFieldsFragment
    ) | (
      { __typename: 'participantsBlock' }
      & ParticipantsBlockFieldsFragment
    ) | (
      { __typename: 'alertBlock' }
      & AlertBlockFieldsFragment
    ) | (
      { __typename: 'performancePastBlock' }
      & PerformancePastBlockFieldsFragment
    ) | { __typename: 'ecomBlock' } | (
      { __typename: 'previewBlock' }
      & PreviewBlockFieldsFragment
    ) | (
      { __typename: 'tabsBlock' }
      & TabsBlockFieldsFragment
    ) | (
      { __typename: 'showAlertBlock' }
      & ShowAlertBlockFieldsFragment
    ) | (
      { __typename: 'videoGridBlock' }
      & VideoGridBlockFieldsFragment
    ) | (
      { __typename: 'videoPageIntroBlock' }
      & VideoPageIntroBlockFieldsFragment
    ) | { __typename: 'newsletterBlock' } | (
      { __typename: 'unknownBlock' }
      & Pick<Types.UnknownBlock, 'id' | 'type'>
    )>>> }
  )> }
);

export type GetHttpStatusQueryVariables = Types.Exact<{
  path: Types.Scalars['String'];
}>;


export type GetHttpStatusQuery = (
  { __typename?: 'query' }
  & { page?: Types.Maybe<(
    { __typename?: 'page' }
    & Pick<Types.Page, 'httpStatusCode' | 'redirectUrl'>
  )> }
);


export const GetPageDocument = gql`
    query GetPage($path: String!, $previewContentId: Int, $previewToken: String) {
  page(
    path: $path
    previewContentId: $previewContentId
    previewToken: $previewToken
  ) {
    httpStatusCode
    redirectUrl
    id
    theme {
      primaryColor
      complementaryColor
      dark
    }
    meta {
      title
      description
    }
    blocks {
      __typename
      ... on rteBlock {
        ...rteBlockFields
      }
      ... on articleRteBlock {
        ...articleRteBlockFields
      }
      ... on pageIntroBlock {
        ...pageIntroBlockFields
      }
      ... on relatedPagesBlock {
        ...relatedPagesBlockFields
      }
      ... on programListingBlock {
        ...programListingBlockFields
      }
      ... on showHeroBlock {
        ...showHeroBlockFields
      }
      ... on showIntroBlock {
        ...showIntroBlockFields
      }
      ... on videoBlock {
        ...videoBlockFields
      }
      ... on heroBlock {
        ...heroBlockFields
      }
      ... on landingPageHeroBlock {
        ...landingPageHeroBlockFields
      }
      ... on pageGridBlock {
        ...pageGridBlockFields
      }
      ... on threeColumnPromoBlock {
        ...threeColumnPromoBlockFields
      }
      ... on mediaContentBlock {
        ...mediaContentBlockFields
      }
      ... on accordionBlock {
        ...accordionBlockFields
      }
      ... on breadcrumbsBlock {
        ...breadcrumbsBlockFields
      }
      ... on slideshowBlock {
        ...slideshowBlockFields
      }
      ... on searchBlock {
        ...searchBlockFields
      }
      ... on profilePageHeroBlock {
        ...profilePageHeroBlockFields
      }
      ... on profileListingBlock {
        ...profileListingBlockFields
      }
      ... on participantsBlock {
        ...participantsBlockFields
      }
      ... on multipleShowsBlock {
        ...multipleShowsBlockFields
      }
      ... on iFrameBlock {
        ...iFrameBlockFields
      }
      ... on alertBlock {
        ...alertBlockFields
      }
      ... on performancePastBlock {
        ...performancePastBlockFields
      }
      ... on previewBlock {
        ...previewBlockFields
      }
      ... on seasonCalendarBlock {
        ...seasonCalendarBlockFields
      }
      ... on tabsBlock {
        ...tabsBlockFields
      }
      ... on showAlertBlock {
        ...showAlertBlockFields
      }
      ... on videoPageIntroBlock {
        ...videoPageIntroBlockFields
      }
      ... on videoGridBlock {
        ...videoGridBlockFields
      }
      ... on unknownBlock {
        id
        type
      }
    }
  }
}
    ${RteBlockFieldsFragmentDoc}
${ArticleRteBlockFieldsFragmentDoc}
${PageIntroBlockFieldsFragmentDoc}
${RelatedPagesBlockFieldsFragmentDoc}
${ProgramListingBlockFieldsFragmentDoc}
${ShowHeroBlockFieldsFragmentDoc}
${ShowIntroBlockFieldsFragmentDoc}
${VideoBlockFieldsFragmentDoc}
${HeroBlockFieldsFragmentDoc}
${LandingPageHeroBlockFieldsFragmentDoc}
${PageGridBlockFieldsFragmentDoc}
${ThreeColumnPromoBlockFieldsFragmentDoc}
${MediaContentBlockFieldsFragmentDoc}
${AccordionBlockFieldsFragmentDoc}
${BreadcrumbsBlockFieldsFragmentDoc}
${SlideshowBlockFieldsFragmentDoc}
${SearchBlockFieldsFragmentDoc}
${ProfilePageHeroBlockFieldsFragmentDoc}
${ProfileListingBlockFieldsFragmentDoc}
${ParticipantsBlockFieldsFragmentDoc}
${MultipleShowsBlockFieldsFragmentDoc}
${IFrameBlockFieldsFragmentDoc}
${AlertBlockFieldsFragmentDoc}
${PerformancePastBlockFieldsFragmentDoc}
${PreviewBlockFieldsFragmentDoc}
${SeasonCalendarBlockFieldsFragmentDoc}
${TabsBlockFieldsFragmentDoc}
${ShowAlertBlockFieldsFragmentDoc}
${VideoPageIntroBlockFieldsFragmentDoc}
${VideoGridBlockFieldsFragmentDoc}`;

/**
 * __useGetPageQuery__
 *
 * To run a query within a React component, call `useGetPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageQuery({
 *   variables: {
 *      path: // value for 'path'
 *      previewContentId: // value for 'previewContentId'
 *      previewToken: // value for 'previewToken'
 *   },
 * });
 */
export function useGetPageQuery(baseOptions?: Apollo.QueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
        return Apollo.useQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, baseOptions);
      }
export function useGetPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
          return Apollo.useLazyQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, baseOptions);
        }
export type GetPageQueryHookResult = ReturnType<typeof useGetPageQuery>;
export type GetPageLazyQueryHookResult = ReturnType<typeof useGetPageLazyQuery>;
export type GetPageQueryResult = Apollo.QueryResult<GetPageQuery, GetPageQueryVariables>;
export const GetHttpStatusDocument = gql`
    query GetHttpStatus($path: String!) {
  page(path: $path) {
    httpStatusCode
    redirectUrl
  }
}
    `;

/**
 * __useGetHttpStatusQuery__
 *
 * To run a query within a React component, call `useGetHttpStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHttpStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHttpStatusQuery({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useGetHttpStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetHttpStatusQuery, GetHttpStatusQueryVariables>) {
        return Apollo.useQuery<GetHttpStatusQuery, GetHttpStatusQueryVariables>(GetHttpStatusDocument, baseOptions);
      }
export function useGetHttpStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHttpStatusQuery, GetHttpStatusQueryVariables>) {
          return Apollo.useLazyQuery<GetHttpStatusQuery, GetHttpStatusQueryVariables>(GetHttpStatusDocument, baseOptions);
        }
export type GetHttpStatusQueryHookResult = ReturnType<typeof useGetHttpStatusQuery>;
export type GetHttpStatusLazyQueryHookResult = ReturnType<typeof useGetHttpStatusLazyQuery>;
export type GetHttpStatusQueryResult = Apollo.QueryResult<GetHttpStatusQuery, GetHttpStatusQueryVariables>;