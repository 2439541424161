import * as React from "react"
import {
	ProfilePageHeroBlock,
	ParticipantsBlock
} from "../../@types/graphql.generated"
import { ProfileCard, Text } from "@operan/ui-components"
import { qBankImageSrc } from "../../utils/assetPaths"
import { profileFallbackImageSrc } from "../../utils/fallbackImage"

export interface ParticipantsTab {
	id: string
	label: string
	participants: ProfilePageHeroBlock[]
	rte: string
}

export const mapParticipantListsToTabs = (
	props: ParticipantsBlock,
	locales: Record<"crewLabel" | "castLabel", string>
): {
	id: string
	label: string
	participants?: ProfilePageHeroBlock[]
	rte?: string
}[] => {
	let tabs = []

	if (props.castParticipants?.length > 0 || props.castListing) {
		tabs.push({
			id: "cast",
			label: locales.castLabel,
			participants:
				props.castParticipants.length > 0 ? props.castParticipants : [],
			rte: props.castListing ?? null
		})
	}

	if (props.crewParticipants?.length > 0 || props.crewListing) {
		tabs.push({
			id: "crew",
			label: locales.crewLabel,
			participants: [],
			rte: props.crewListing ?? null
		})
	}

	return tabs
}

export const Participant: React.FC<ProfilePageHeroBlock> = props => (
	<ProfileCard
		image={
			<img
				src={
					props.image
						? qBankImageSrc(props.image.src, "600")
						: profileFallbackImageSrc
				}
				alt=""
			/>
		}
		alignItems="center"
		textAlign="center"
		variant="asParticipant"
	>
		{props.title && (
			<Text
				as="span"
				display={"block"}
				fontSize={{ _: 1.75, s: 1.5 }}
				lineHeight={2}
				mb={0.75}
				textTransform={"uppercase"}
			>
				{props.title}
			</Text>
		)}
		{props.url ? (
			<Text.Link
				to={props.url.url}
				fontSize={{ _: 2, l: 1.75 }}
				lineHeight={{ _: 2.5, l: 2.25 }}
				fontWeight="semibold"
				style={{ textDecoration: "underline" }}
			>
				{props.name}
			</Text.Link>
		) : (
			<Text
				fontSize={{ _: 2, l: 1.75 }}
				lineHeight={{ _: 2.5, l: 2.25 }}
				fontWeight="semibold"
			>
				{props.name}
			</Text>
		)}
	</ProfileCard>
)
