import * as Types from '../../@types/graphql.generated';

import { AccordionBlockFieldsFragment } from '../AccordionBlock/operations.generated';
import { MediaContentBlockFieldsFragment } from '../MediaContentBlock/operations.generated';
import { PageGridBlockFieldsFragment } from '../PageGridBlock/operations.generated';
import { ProfileListingBlockFieldsFragment } from '../ProfileListingBlock/operations.generated';
import { SlideshowBlockFieldsFragment } from '../SlideshowBlock/operations.generated';
import { ThreeColumnPromoBlockFieldsFragment } from '../ThreeColumnPromoBlock/operations.generated';
import { RteBlockFieldsFragment } from '../RteBlock/operations.generated';
import { VideoBlockFieldsFragment } from '../VideoBlock/operations.generated';
import { ParticipantsBlockFieldsFragment } from '../ParticipantsBlock/operations.generated';
import { IFrameBlockFieldsFragment } from '../IFrameBlock/operations.generated';
import { gql } from '@apollo/client';
import { AccordionBlockFieldsFragmentDoc } from '../AccordionBlock/operations.generated';
import { MediaContentBlockFieldsFragmentDoc } from '../MediaContentBlock/operations.generated';
import { PageGridBlockFieldsFragmentDoc } from '../PageGridBlock/operations.generated';
import { ProfileListingBlockFieldsFragmentDoc } from '../ProfileListingBlock/operations.generated';
import { SlideshowBlockFieldsFragmentDoc } from '../SlideshowBlock/operations.generated';
import { ThreeColumnPromoBlockFieldsFragmentDoc } from '../ThreeColumnPromoBlock/operations.generated';
import { RteBlockFieldsFragmentDoc } from '../RteBlock/operations.generated';
import { VideoBlockFieldsFragmentDoc } from '../VideoBlock/operations.generated';
import { ParticipantsBlockFieldsFragmentDoc } from '../ParticipantsBlock/operations.generated';
import { IFrameBlockFieldsFragmentDoc } from '../IFrameBlock/operations.generated';
export type MultipleShowsBlockFieldsFragment = (
  { __typename?: 'multipleShowsBlock' }
  & Pick<Types.MultipleShowsBlock, 'id' | 'type' | 'themeColorVariant'>
  & { tabs?: Types.Maybe<Array<(
    { __typename?: 'multipleShowsTab' }
    & Pick<Types.MultipleShowsTab, 'title' | 'subTitle'>
    & { image?: Types.Maybe<(
      { __typename?: 'image' }
      & Pick<Types.Image, 'id' | 'alt' | 'src'>
    )>, blocks?: Types.Maybe<Array<Types.Maybe<(
      { __typename?: 'accordionBlock' }
      & AccordionBlockFieldsFragment
    ) | (
      { __typename?: 'mediaContentBlock' }
      & MediaContentBlockFieldsFragment
    ) | (
      { __typename?: 'pageGridBlock' }
      & PageGridBlockFieldsFragment
    ) | (
      { __typename?: 'profileListingBlock' }
      & ProfileListingBlockFieldsFragment
    ) | (
      { __typename?: 'slideshowBlock' }
      & SlideshowBlockFieldsFragment
    ) | (
      { __typename?: 'threeColumnPromoBlock' }
      & ThreeColumnPromoBlockFieldsFragment
    ) | (
      { __typename?: 'rteBlock' }
      & RteBlockFieldsFragment
    ) | (
      { __typename?: 'videoBlock' }
      & VideoBlockFieldsFragment
    ) | (
      { __typename?: 'iFrameBlock' }
      & IFrameBlockFieldsFragment
    ) | (
      { __typename?: 'participantsBlock' }
      & ParticipantsBlockFieldsFragment
    ) | (
      { __typename?: 'unknownBlock' }
      & Pick<Types.UnknownBlock, 'id' | 'type'>
    )>>> }
  )>> }
);

export const MultipleShowsBlockFieldsFragmentDoc = gql`
    fragment multipleShowsBlockFields on multipleShowsBlock {
  id
  type
  themeColorVariant
  tabs {
    image {
      id
      alt
      src
    }
    title
    subTitle
    blocks {
      ... on accordionBlock {
        ...accordionBlockFields
      }
      ... on mediaContentBlock {
        ...mediaContentBlockFields
      }
      ... on pageGridBlock {
        ...pageGridBlockFields
      }
      ... on profileListingBlock {
        ...profileListingBlockFields
      }
      ... on slideshowBlock {
        ...slideshowBlockFields
      }
      ... on threeColumnPromoBlock {
        ...threeColumnPromoBlockFields
      }
      ... on rteBlock {
        ...rteBlockFields
      }
      ... on videoBlock {
        ...videoBlockFields
      }
      ... on participantsBlock {
        ...participantsBlockFields
      }
      ... on iFrameBlock {
        ...iFrameBlockFields
      }
      ... on unknownBlock {
        id
        type
      }
    }
  }
}
    ${AccordionBlockFieldsFragmentDoc}
${MediaContentBlockFieldsFragmentDoc}
${PageGridBlockFieldsFragmentDoc}
${ProfileListingBlockFieldsFragmentDoc}
${SlideshowBlockFieldsFragmentDoc}
${ThreeColumnPromoBlockFieldsFragmentDoc}
${RteBlockFieldsFragmentDoc}
${VideoBlockFieldsFragmentDoc}
${ParticipantsBlockFieldsFragmentDoc}
${IFrameBlockFieldsFragmentDoc}`;