import * as React from "react"
import { ThemeProvider } from "styled-components"
import { Link } from "react-router-dom"
import {
	VideoPlayer,
	Flex,
	mergeColorTheme,
	MediaContent
} from "@operan/ui-components"
import { Block } from "../Block/Block"
import {
	MediaContentBlock as IMediaContentBlock,
	PageSummary
} from "../../@types/graphql.generated"
import { qBankImageSrc, qBankVideoSrc, FallbackImage } from "../../utils"
import { BlockCommonLayout, BlockTitlePreamble } from "../../utils/layout"

const MediaContentBlock: React.FC<IMediaContentBlock> = props => (
	<Block themeColorVariant={props.themeColorVariant} theme={props.theme}>
		<BlockCommonLayout>
			<Flex w={1} flexDirection="column">
				{props.title && (
					<BlockTitlePreamble
						title={props.title}
						preamble={props.preamble || null}
					/>
				)}
				{props.pages.map((page: PageSummary, i) => {
					// Used to remove margin on last child
					const lastChild = i === props.pages.length - 1

					// Even/odd logic for mirror layout
					const useMirrorLayout =
						i % 2 === 0 ? props.mirrorLayout : !props.mirrorLayout

					return (
						<Flex
							ml={useMirrorLayout ? { _: 0, xl: 27 } : 0}
							mr={useMirrorLayout ? 0 : { _: 0, xl: 27 }}
							key={i}
						>
							<Link to={page.url} style={{ width: "100%" }}>
								<Flex mb={{ _: lastChild ? 0 : 4, m: lastChild ? 0 : 8 }}>
									<ThemeProvider theme={mergeColorTheme(page.theme)}>
										<MediaContent
											media={
												page.videoUrl ? (
													<VideoPlayer
														useCustomControls={true}
														autoplay={true}
														url={qBankVideoSrc(page.videoUrl)}
														muted
														playsinline
														loop
													/>
												) : page.image ? (
													<img
														loading="lazy"
														alt=""
														src={qBankImageSrc(page.image.src, "1600")}
														style={{ width: "100%" }}
													/>
												) : (
													<FallbackImage />
												)
											}
											mirrorLayout={useMirrorLayout}
											bgColor={page.theme && page.theme.primaryColor}
											textColor={page.theme && page.theme.textColor}
											key={i}
											{...page}
										/>
									</ThemeProvider>
								</Flex>
							</Link>
						</Flex>
					)
				})}
			</Flex>
		</BlockCommonLayout>
	</Block>
)

export { MediaContentBlock }
