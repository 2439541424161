import * as React from "react"
import {
	Flex,
	Text,
	INNER_MAX_WIDTH,
	INNER_CONTAINER_MAX_WIDTH,
	READABLE_LINE_MAX_WIDTH
} from "@operan/ui-components"

/*
 Max width 1972
 Padding x: { _: 2, m: 5, l: 10 }
 Padding y: { _: 4, m: 6, l: 10 }
*/
export const BlockCommonLayout: React.FC = props => (
	<Flex px={{ _: 2, m: 5, l: 10 }} py={{ _: 4, m: 6, l: 8 }}>
		<Flex
			alignItems="center"
			flexDirection="column"
			w={1}
			maxw={INNER_MAX_WIDTH}
			style={{ margin: "0 auto" }}
		>
			{props.children}
		</Flex>
	</Flex>
)

/*
 Max width 1272
 Padding x: { _: 2, m: 5, l: 10 }
 Padding y: { _: 4, m: 6, l: 10 }
*/
export const BlockCommonNarrowLayout: React.FC = props => (
	<Flex px={{ _: 2, m: 5, l: 10 }} py={{ _: 4, m: 6, l: 8 }}>
		<Flex
			alignItems="center"
			flexDirection="column"
			w={1}
			maxw={INNER_CONTAINER_MAX_WIDTH}
			style={{ margin: "0 auto" }}
		>
			{props.children}
		</Flex>
	</Flex>
)

/*
 Max width 1972
 Padding x: { _: 0, m: 5, l: 10 }
 Padding y: { _: 4, m: 6, l: 10 }
*/
// This has one variant with 0 padding x on medium
export const BlockFullWidthLayout: React.FC = props => (
	<Flex px={{ _: 0, m: 5, l: 10 }} py={{ _: 8, l: 8 }}>
		<Flex
			alignItems="center"
			flexDirection="column"
			w={1}
			maxw={INNER_MAX_WIDTH}
			style={{ margin: "0 auto" }}
		>
			{props.children}
		</Flex>
	</Flex>
)

/*
    For centered block title and preamble
*/
export interface BlockTitlePreambleProps {
	title: string
	preamble?: string
}

export const BlockTitlePreamble: React.FC<BlockTitlePreambleProps> = props => (
	<Flex
		maxw={READABLE_LINE_MAX_WIDTH}
		style={{ marginLeft: "auto", marginRight: "auto" }}
		mb={{ _: 3, m: 8 }}
	>
		<Flex flexDirection="column" alignItems="center" px={{ _: 2, s: 5 }}>
			<Text variant="headline-2" mb={{ _: 0 }} textAlign="center">
				{props.title}
			</Text>
			{props.preamble && (
				<Text variant="headline-4" mt={{_: 2, m: 4 }} as="p" textAlign="center">
					{props.preamble}
				</Text>
			)}
		</Flex>
	</Flex>
)
